import React from 'react';
import { Dialog, DialogContent, DialogFooter } from '@react-md/dialog';
import { Button } from '@react-md/button';
import QRCode from 'qrcode.react';

interface QrDialogProps {
    visible: boolean;
    onHide: () => void | undefined;
    link: string;
}

export const QrDialog = (props: QrDialogProps) => {

    return (
        <Dialog
            id="share-dialog"
            visible={props.visible}
            onRequestClose={props.onHide}
            aria-labelledby="share-title"
            style={{ borderRadius: '16px' }}
        >
            <DialogContent>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <QRCode size={300} value={props.link} renderAs='svg' includeMargin={true} />
                </div>
            </DialogContent>
            <DialogFooter>
                <Button
                    id="share-cancel"
                    onClick={props.onHide}
                    type="button"
                >
                    Close
                </Button>
            </DialogFooter>
        </Dialog>
    );
};
