import React, { useEffect } from 'react';
import { PrivateRoute } from '../../private.route';
import { Switch } from 'react-router-dom';
import { OverviewComponent } from '../overview/overview.component';
import { NavigationDrawer } from '../navigation/navigation-drawer';
import { NavigationItems } from '../navigation/navigation-item';
import { UsersComponent } from '../admin/users/users.component';
import { ContactsComponent } from '../contacts/contacts.component';
import { useDispatch, useSelector } from 'react-redux';
import { loadSelectors, loadDefaultSelectors } from '../../redux/selector.state';
import { loadTags } from '../../redux/tag.state';
import { loadAllFollowings, loadAllFollowers } from '../../redux/following.state';
import { ProfileComponent } from '../profile/profile.component';
import { OnboardingComponent } from '../onboarding/onboarding.component';
import { showOnboarding } from '../../redux/authentication.state';
import { RootState } from '../../redux/reducers';
import { updateMobile } from '../../redux/mobile.state';
import { loadPushNotifications } from '../../redux/push-notification.state';
import { Loyalty } from '../loyalty/loyalty';
import { getUser } from '../../redux/user.state';

import './dashboard.component.scss';

export const Dashboard = () => {
    const token = useSelector((state: RootState) => state.authentication.token);
    const onboarding = useSelector((state: RootState) => state.authentication.onboarding);
    const { mobiles, selectedMobileId } = useSelector((state: RootState) => state.mobile);
    const selectedMobile = mobiles.find((m) => m._id === selectedMobileId);
    const dispatch = useDispatch();

    useEffect(() => {
        document.title = "FizLink";
        if (token) {
            getUser(token.user_id)(dispatch);
        }
        loadDefaultSelectors()(dispatch);
        loadSelectors()(dispatch);
        loadTags()(dispatch);
        loadAllFollowings()(dispatch);
        loadAllFollowers()(dispatch);
        loadPushNotifications()(dispatch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    const handleOnboardingDone = () => {
        if (selectedMobile && !selectedMobile.pages[0]?.onboarding) {
            const updatedPages = [...selectedMobile.pages];
            const updatedPage0 = { ...updatedPages[0], onboarding: 1 };
            updatedPages.splice(0, 1, updatedPage0);
            updateMobile({ ...selectedMobile, pages: updatedPages })(dispatch);
        }
        dispatch(showOnboarding(false));
    };

    return (
        <NavigationDrawer items={NavigationItems}>
            <Switch key={window.location.pathname}>
                <PrivateRoute path="/" exact={true} component={OverviewComponent} />
                <PrivateRoute path="/setup/contacts" exact={true} component={ContactsComponent} />
                <PrivateRoute path="/setup/users" component={UsersComponent} />
                <PrivateRoute path="/setup/loyalty" component={Loyalty} />
                <PrivateRoute path="/profile/*" component={ProfileComponent} />
            </Switch>
            <OnboardingComponent visible={onboarding} onDone={handleOnboardingDone} />
        </NavigationDrawer>
    );
};
