import { SecuredService } from "./secured.service";
import { ApiResult } from "./api-result";
import { Following } from '../models/following';
import { Follower } from '../models/follower';

export class FollowingService extends SecuredService {

    private baseUrl = 'api/followings';

    public getFollowings(): Promise<ApiResult<Following[] | undefined>> {
        return this.get(this.baseUrl);
    }

    public getFollowers(): Promise<ApiResult<Follower[] | undefined>> {
        return this.get(`${this.baseUrl}/followers`);
    }
    
    public postFollow(id: string): Promise<ApiResult<Following | undefined>> {
        return this.post(this.baseUrl, { followingid: id });
    }

    public approveFollower(id: string): Promise<ApiResult<Following | undefined>> {
        return this.put(`${this.baseUrl}/approve`, { followingid: id });
    }

    public declineFollower(id: string): Promise<ApiResult<Following | undefined>> {
        return this.put(`${this.baseUrl}/decline`, { followingid: id });
    }

    public unfollowFollowing(id: string): Promise<ApiResult<Following | undefined>> {
        return this.put(`${this.baseUrl}/unfollow`, { followingid: id });
    }
}