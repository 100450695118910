import { useCallback, useEffect } from "react";

export const useWrapperAlerter = (ref: any, onClick: () => void) => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = useCallback((event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            onClick();
        }
    }, [ref, onClick]);

    useEffect(() => {
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [handleClickOutside]);
}