import React from 'react';
import { FizLink } from '../svg/fizlink-logo';
import { Button } from 'react-md';
import { useHistory } from 'react-router-dom';
import { FizlinkIcon } from '../svg/fizlink-icon';

export const MobileToolbar = () => {
    const history = useHistory();

    return (
        <header className="mobile-toolbar">
            <a href="/home">
                <FizLink height={32} />
            </a>
            <div>
                <Button icon={true} onClick={() => history.push('/')}>
                    <FizlinkIcon height={20} color={'rgba(0, 0, 0, 0.54)'} />
                </Button>
                <Button icon={true} onClick={() => history.push('/setup/contacts')}>group</Button>
            </div>
        </header>
    );
};
