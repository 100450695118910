import { MobileCollection } from "../models/mobile-collection";
import { MobileCollectionService } from "../services/mobile-collection.service";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CollectionCount } from '../models/collection-count';
import { extractData } from './authentication.state';

export interface MobileCollectionState {
    collections: MobileCollection[];
    collectionsCount: CollectionCount[];
}

const initialState: MobileCollectionState = {
    collections: [],
    collectionsCount: [],
};

const loadMobileCollectionsReducer = (state: MobileCollectionState, action: PayloadAction<MobileCollection[]>) => {
    state.collections = action.payload;
}

const loadMobileCollectionsCountReducer = (state: MobileCollectionState, action: PayloadAction<CollectionCount[]>) => {
    state.collectionsCount = action.payload;
}

const updateMobileCollectionReducer = (state: MobileCollectionState, action: PayloadAction<MobileCollection>) => {
    const collection = action.payload;
    const cIdx = state.collections.findIndex((c) => c._id === collection._id);
    if (cIdx < 0) {
        state.collections.push(collection);
    }
    else {
        state.collections[cIdx] = collection;
    }
}

const { reducer, actions } = createSlice({
    name: 'mobilecollections',
    initialState,
    reducers: {
        loadMobileCollections: loadMobileCollectionsReducer,
        loadMobileCollectionsCount: loadMobileCollectionsCountReducer,
        updateMobileCollection: updateMobileCollectionReducer,
    },
});

export { reducer as MobileCollectionReducer };

export const loadMobileCollectionsCount = (tagId: string) => {
    return (async (dispatch: any) => {
        const service = new MobileCollectionService();
        const res = await service.getMobileCollectionsCount(tagId);
        const collections = extractData(res, dispatch) || [];
        dispatch(actions.loadMobileCollectionsCount(collections));
    });
};
