import { UserEntity } from "../models/user-entity";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EntityService } from "../services/entity.service";

export interface EntityState {
    entity?: UserEntity;
}

const initialState: EntityState = {
    entity: undefined,
};

const setEntityReducer = (state: EntityState, action: PayloadAction<UserEntity | undefined>) => {
    state.entity = action.payload;
}

const { reducer, actions } = createSlice({
    name: 'entity',
    initialState,
    reducers: {
        setEntity: setEntityReducer,
    },
});

export { reducer as EntityReducer };

export const getEntity = (entityId: string) => {
    return (async (dispatch: any) => {
        const service = new EntityService();
        const res = await service.getEntity(entityId);
        const company = res.data;
        if (company) {
            dispatch(actions.setEntity(company));
        }
    });
};
