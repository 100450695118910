import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { Redirect } from 'react-router-dom';
import { FontIcon } from 'react-md';
import { Button } from '../button';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../locales/keys';
import { DownloadiOSAppStoreSvg } from '../svg/download-ios-app-store';
import { useMobileDevice, MobileDeviceType } from '../../hooks/device.hooks';

import './authentication.component.scss';

interface SuccessErrorProps {
    title: string;
    subtitle1?: string;
    subtitle2?: string;
    subtitle3?: string;
    error?: boolean;
    onClick?: () => void;
}

export const SuccessErrorMessage = (props: SuccessErrorProps) => {
    const { t } = useTranslation();
    const mobileDevice = useMobileDevice();
    const token = useSelector((state: RootState) => state.authentication.token);

    if (token) {
        return <Redirect to="/" />;
    }

    return (
        <div className="thankyou">
            <div className="checkmark" style={{ backgroundColor: props.error ? "#EE3D4B" : "#00d478" }}>
                {props.error ? <FontIcon style={{ color: '#ffffff' }}>close</FontIcon> : <FontIcon style={{ color: '#ffffff' }}>done</FontIcon>}
            </div>
            <span className="thankyou-title">{props.title}</span>
            {props.subtitle1 ? <span className="thankyou-subtitle">{props.subtitle1}</span> : null}
            {props.subtitle2 ? <span className="thankyou-subtitle">{props.subtitle2}</span> : null}
            {props.subtitle3 ? <span className="thankyou-subtitle">{props.subtitle3}</span> : null}
            <Button color={props.error ? "#EE3D4B" : "#00d478"} onClick={props.onClick}>{t(LocalizationKeys.GotIt)}</Button>
            {mobileDevice !== MobileDeviceType.iOSPhone ? null :
                <a href="https://apps.apple.com/ca/app/fizlink/id1568710582" target="_blank" rel="noopener noreferrer" style={{ marginTop: '64px', textDecoration: 'none' }}>
                    <DownloadiOSAppStoreSvg height={56} />
                </a>
            }
        </div>
    );
};
