import React, { useEffect, useState } from 'react';
import { HorizontalLoader } from '../horizontal-loader';
import { UserSearch } from '../../models/user-search';
import { UserContactComponent } from './user-contact.component';
import { SearchService } from '../../services/search.service';

interface UserSearchProps {
    query?: string;
    page?: number;
}

export const UserSearchComponent = (props: UserSearchProps) => {
    const [searching, setSearching] = useState(!!props.query);
    const [users, setUsers] = useState<UserSearch[]>([]);

    const performSearch = (page?: number, previousUsers?: UserSearch[]) => {
        if (props.query) {
            setSearching(true);
            const service = new SearchService();
            service.search(props.query, page)
                .then((res) => {
                    if (res.success && res.data) {
                        setUsers([...previousUsers ?? [], ...res.data]);
                    }
                    setSearching(false);
                })
                .catch((res) => {
                    setSearching(false);
                    // no results?
                });
        }
        else {
            setUsers([]);
            setSearching(false);
        }
    };

    useEffect(() => {
        performSearch(1);

        return () => {
            setUsers([]);
            setSearching(false);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.query]);

    useEffect(() => {
        performSearch(props.page, users);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.page]);

    const renderUsers = (): JSX.Element => {
        return (
            <React.Fragment>
                <div className="divider" />
                {users.map((u) => <UserContactComponent user={u} />)}
                {searching ? <HorizontalLoader /> : null}
            </React.Fragment>
        );
    };

    return !props.query ? null : renderUsers();
};
