import React, { useCallback, useEffect, useState } from 'react';
import { Snackbar, TextField } from 'react-md';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../locales/keys';
import { useTextField } from '../authentication/signup.component';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { PageNotFound } from '../page-not-found.component';
import { Button } from '@react-md/button';
import { updatePassword } from '../../redux/user.state';

export const PasswordComponent = () => {
    const { t } = useTranslation();
    const token = useSelector((state: RootState) => state.authentication.token);
    const user = useSelector((state: RootState) => state.user.selectedUser);
    const { value: password, bind: bindPassword } = useTextField('');
    const { value: confirmPassword, bind: bindConfirmPassword } = useTextField('');
    const [toasts, setToasts] = useState<Array<{ text: React.ReactNode }>>([]);

    const enabled = password.length && confirmPassword.length && password === confirmPassword;
    const dispatch = useDispatch();

    useEffect(() => {
        if (user) {
            bindPassword.onChange('');
            bindConfirmPassword.onChange('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const onUpdatedSuccessfully = useCallback(() => {
        setToasts([{ text: 'Password updated.' }]);
    }, []);

    const onSubmit = useCallback(() => {
        if (user && enabled) {
            updatePassword(user._id, password, confirmPassword, onUpdatedSuccessfully)(dispatch);
        }
    }, [dispatch, user, password, confirmPassword, enabled, onUpdatedSuccessfully]);

    if (!token && !user) {
        return <PageNotFound />
    }

    return (
        <React.Fragment>
            <div>
                <TextField fullWidth={true} id="newpassword" type="password" label={t(LocalizationKeys.NewPassword)} {...bindPassword} error={!!password && !!confirmPassword && password !== confirmPassword} errorText={t(LocalizationKeys.PasswordsDoNotMatch)} />
                <TextField fullWidth={true} id="newconfirmPassword" type="password" label={t(LocalizationKeys.ConfirmNewPassword)} {...bindConfirmPassword} />
                <Button className="edit-submit" disabled={!enabled} onClick={onSubmit}>{t(LocalizationKeys.ChangePassword)}</Button>
            </div>
            <Snackbar
                id="change-password-snackbar"
                toasts={toasts}
                autohide={true}
                onDismiss={() => setToasts([])}
            />
        </React.Fragment>
    );
};
