import React, { useCallback } from 'react';

import './support.scss';

export const Support = () => {
    const handleOnClick = useCallback(() => {
        window.open("http://www.votie.cc/fizlinksupport", "FizLink Support", 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=700,height=800');
    }, []);
    return (
        <div className="support-tab" onClick={handleOnClick}>
            <div className="support-icon">
                {/* <FizlinkIcon height={16} color="black" /> */}
                <svg xmlns="http://www.w3.org/2000/svg" width="16" viewBox="0 0 92 97.062"><path d="M46,92A46.011,46.011,0,0,1,28.093,3.617a46.023,46.023,0,0,1,60.29,60.29,44.693,44.693,0,0,1-7.792,12.354c-.142.161.164.63.955,1.915,1.158,1.88,3.128,5.287,4.775,8.22,1.625,3.377,4.57,6.363,3.65,7.74a1.969,1.969,0,0,1-1.31.9c-1.261.18-15.069-4.785-16.944-5.469-1.454-.475-3.929-1.332-5.18-1.719a3.482,3.482,0,0,0-2.63.534A45.716,45.716,0,0,1,46,92ZM46,9.1A36.9,36.9,0,0,0,19.908,72.092,36.9,36.9,0,1,0,72.092,19.908,36.658,36.658,0,0,0,46,9.1Z" fill="rgba(0, 0, 0, 0.54)" /></svg>
            </div>
            <div className="support-text">Support</div>
        </div>
    );
};
