import React from 'react';
import { Radio } from 'react-md';
import { usePushNotifications } from './push-notifications.hooks';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../../locales/keys';

import './push-notifications.component.scss';

export const PushNotificationsComponent = () => {
    const { t } = useTranslation();
    const { pushnotifications, toggleOff, toggleOn } = usePushNotifications();

    return (
        <>
            <div className="push-settings">
                <h3>{t(LocalizationKeys.AcceptedFollowRequests)}</h3>
                <Radio id="acceptedrequests-off" aria-label="follow-requests-off" labelClassName="push-headline" label={`${t(LocalizationKeys.Off)}`} name={`${t(LocalizationKeys.Off)}`} value={`${t(LocalizationKeys.Off)}`} checked={!pushnotifications?.acceptedrequests} onChange={toggleOff} />
                <Radio id="acceptedrequests" aria-label="follow-requests-everyone" labelClassName="push-headline" label={`${t(LocalizationKeys.FromEveryone)}`} name={`${t(LocalizationKeys.FromEveryone)}`} value={`${t(LocalizationKeys.FromEveryone)}`} checked={pushnotifications?.acceptedrequests} onChange={toggleOn} />
                <span className="push-subheadline">{t(LocalizationKeys.AcceptedFollowRequestsInfo)}</span>
            </div>
            <div className="push-settings">
                <h3>{t(LocalizationKeys.NewFollowers)}</h3>
                <Radio id="newfollowers-off" aria-label="new-followers-off" labelClassName="push-headline" label={`${t(LocalizationKeys.Off)}`} name={`${t(LocalizationKeys.Off)}`} value={`${t(LocalizationKeys.Off)}`} checked={!pushnotifications?.newfollowers} onChange={toggleOff} />
                <Radio id="newfollowers" aria-label="new-followers-everyone" labelClassName="push-headline" label={`${t(LocalizationKeys.FromEveryone)}`} name={`${t(LocalizationKeys.FromEveryone)}`} value={`${t(LocalizationKeys.FromEveryone)}`} checked={pushnotifications?.newfollowers} onChange={toggleOn} />
                <span className="push-subheadline">{t(LocalizationKeys.NewFollowersInfo)}</span>
            </div>
            <div className="push-settings">
                <h3>{t(LocalizationKeys.Reminders)}</h3>
                <Radio id="reminders-off" aria-label="reminders-off" labelClassName="push-headline" label={`${t(LocalizationKeys.Off)}`} name={`${t(LocalizationKeys.Off)}`} value={`${t(LocalizationKeys.Off)}`} checked={!pushnotifications?.reminders} onChange={toggleOff} />
                <Radio id="reminders" aria-label="reminders-everyone" labelClassName="push-headline" label={`${t(LocalizationKeys.FromEveryone)}`} name={`${t(LocalizationKeys.FromEveryone)}`} value={`${t(LocalizationKeys.FromEveryone)}`} checked={pushnotifications?.reminders} onChange={toggleOn} />
                <span className="push-subheadline">{t(LocalizationKeys.RemindersInfo)}</span>
            </div>
            <div className="push-settings">
                <h3>{t(LocalizationKeys.FollowerUpdates)}</h3>
                <Radio id="followerupdates-off" aria-label="follower-updates-off" labelClassName="push-headline" label={`${t(LocalizationKeys.Off)}`} name={`${t(LocalizationKeys.Off)}`} value={`${t(LocalizationKeys.Off)}`} checked={!pushnotifications?.followerupdates} onChange={toggleOff} />
                <Radio id="followerupdates" aria-label="follower-updates-follow" labelClassName="push-headline" label={`${t(LocalizationKeys.FromPeopleIFollow)}`} name={`${t(LocalizationKeys.FromPeopleIFollow)}`} value={`${t(LocalizationKeys.FromPeopleIFollow)}`} checked={pushnotifications?.followerupdates} onChange={toggleOn} />
                <span className="push-subheadline">{t(LocalizationKeys.FollowerUpdatesInfo)}</span>
            </div>
        </>
    );
};
