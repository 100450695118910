import React from 'react';
import { Dialog, DialogFooter, DialogContent } from '@react-md/dialog';
import { DEFAULT_PROFILE_PICTURE } from '../preview/base64.lib';
import { Button } from '@react-md/button';
import { MediaService } from '../../services/media.service';

interface FollowDialogProps {
    visible: boolean;
    media?: string;
    onLogin: () => void;
    onSignUp: () => void;
    onHide: () => void | undefined;
}

export const FollowDialogComponent = (props: FollowDialogProps) => {

    return (
        <Dialog
            id="follow-dialog"
            visible={props.visible}
            onRequestClose={props.onHide}
            aria-labelledby="follow-title"
            style={{ borderRadius: '16px' }}
        >
            <DialogContent style={{ textAlign: 'center' }}>
                <img height={96} width={96} src={props.media ? MediaService.getMediaUrl(props.media) : DEFAULT_PROFILE_PICTURE} alt="Profile" style={{ objectFit: 'cover', borderRadius: '50%' }}/>
                <div><span>Login to follow</span></div>
                <button className="approve-follower" style={{ backgroundColor: '#0095f6', color: '#ffffff' }} onClick={props.onLogin}>Login</button>
                <button className="follow-back" onClick={props.onSignUp}>Sign Up</button>
            </DialogContent>
            <DialogFooter>
                <Button
                    id="follow-cancel"
                    onClick={props.onHide}
                    type="button"
                >
                    Cancel
                </Button>
            </DialogFooter>
        </Dialog>
    );
};
