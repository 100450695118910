import React from 'react';

interface FizlinkIconProps {
    height: number;
    color?: string;
}

export const FizlinkIcon = (props: FizlinkIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height={props.height} viewBox="0 0 48.384 57.545">
            <g >
                <circle cx="14.91" cy="14.91" r="14.91" fill={props.color ?? "#c7eb5c"} />
                <circle cx="8.76" cy="8.76" r="8.76" transform="translate(30.865 19.107)" fill={props.color ?? "#5cc7eb"} />
                <path d="M5.619,0A5.619,5.619,0,1,1,0,5.619,5.619,5.619,0,0,1,5.619,0Z" transform="translate(0 31.388)" fill={props.color ?? "#ebad5c"} />
                <circle cx="11.246" cy="11.246" r="11.246" transform="translate(12.805 35.053)" fill={props.color ?? "#eb5c5c"} />
            </g>
        </svg>
    );
};
