import { SecuredService } from "./secured.service";
import { ApiResult } from "./api-result";
import { Mobile } from '../models/mobile';

export class MobileService extends SecuredService {

    private baseUrl = 'api/mobiles';

    public getMobiles(): Promise<ApiResult<Mobile[]>> {
        return this.get(this.baseUrl);
    }

    public getMobile(id: string): Promise<ApiResult<Mobile>> {
        return this.get(`${this.baseUrl}/${id}`);
    }

    public updateMobile(mobile: Mobile): Promise<ApiResult<Mobile>> {
        return this.put(`${this.baseUrl}/${mobile._id}`, mobile);
    }
}