import React, { useEffect } from 'react';
import { Selector } from '../../../models/selector';
import { MediaService } from '../../../services/media.service';
import { FontIcon } from 'react-md';
import { createPortal } from 'react-dom';

import './add-selector.dialog.scss';

interface AddSelectorDialogProps {
    selectors: Selector[];
    visible: boolean;
    onHide: () => void | undefined;
    onAdd?: (selectorId: string) => void;
}

export const AddSelectorDialog = (props: AddSelectorDialogProps) => {

    useEffect(() => {
        if (props.visible) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.overflow = 'unset';
        }
    }, [props.visible]);

    const handleAdd = (id: string) => {
        if (props.onAdd) {
            props.onAdd(id);
        }
        props.onHide();
    };

    const renderSelector = (selector: Selector): JSX.Element => {
        return (
            <div className="add-selector mobile-selector">
                <div className="mobile-selector-container">
                    <img className="mobile-selector" src={MediaService.getMediaUrl(selector.media)} alt={selector._id} onClick={(ev) => { ev.stopPropagation(); handleAdd(selector._id); }} />
                </div>
                <label>{selector.name}</label>
            </div>
        );
    };

    const render = () => {
        return (
            <div className="add-selector-overlay" onClick={props.onHide}>
                <div className="links">
                    {props.selectors.map(renderSelector)}
                </div>
                <div className="add-selector-footer">
                    <div onClick={(ev) => { ev.stopPropagation(); props.onHide(); }}>
                        <FontIcon style={{ color: '#fafafa', fontSize: '30px' }}>close</FontIcon>
                    </div>
                </div>
            </div>
        );
    };
    const rootContainer = document.getElementById('root');

    return !props.visible || !rootContainer ? null : createPortal(render(), rootContainer);
};
