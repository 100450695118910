import React, { useState } from 'react';
import { Dialog, DialogContent } from '@react-md/dialog';
import { TextField } from 'react-md';
import { Button } from '@react-md/button';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../locales/keys';

interface InviteDialogProps {
    visible: boolean;
    onHide: () => void;
    onInvite?: (emails: string[]) => void;
}

export const InviteDialog = (props: InviteDialogProps) => {
    const { t } = useTranslation();
    const [emails, setEmails] = useState<string | undefined>(undefined);

    const handleInvite = () => {
        if (emails && props.onInvite) {
            props.onInvite(emails.split(/[ ,]+/));
        }
    };

    return (
        <Dialog id="invite-dialog" visible={props.visible} onRequestClose={props.onHide} aria-labelledby="invite-dialog-title" style={{ borderRadius: '16px' }}>
            <DialogContent>
                <h3>{t(LocalizationKeys.InviteOthers)}</h3>
                <TextField
                    id="invite-action-dialog-field"
                    placeholder={t(LocalizationKeys.Emails)}
                    helpText={t(LocalizationKeys.SeparateEmailsByComma)}
                    onChange={(value) => setEmails(value.toString())}
                />
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '8px' }}>
                    <Button
                        id="invite-dialog-cancel"
                        onClick={props.onHide}
                        type="button"
                        style={{ color: "#ff6e40" }}
                    >
                        {t(LocalizationKeys.Cancel)}
                    </Button>
                    <Button
                        id="invite-dialog-invite"
                        onClick={handleInvite}
                        type="button"
                        style={{ color: '#03a9f4' }}
                    >
                        {t(LocalizationKeys.Invite)}
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
};
