import React from 'react';
import { FontIcon } from 'react-md';

export const FizlinkIconsComponent = () => {

    const renderIconTextPair = (icon: string, text: string, iconColor?: string) => {
        return (
            <div style={{ display: 'flex', padding: '16px', alignItems: 'center', textAlign: 'left' }}>
                <FontIcon style={{ color: iconColor ?? 'white', marginRight: '16px' }}>{icon}</FontIcon>
                <span style={{ color: 'white' }}>{text}</span>
            </div>
        );
    };

    return (
        <div style={{ display: 'inline-block' }}>
            {renderIconTextPair("edit", "Edit your profile and links.")}
            {renderIconTextPair("qr_code", "Download your QR code.")}
            {renderIconTextPair("visibility", "Number of profile views.", "#0EBFE9")}
            {renderIconTextPair("touch_app", "Number of link clicks.", "#f9690e")}
            {renderIconTextPair("person", "Launch your profile to preview your links.")}
            {renderIconTextPair("group", "Number of contacts you follow.", "#00b16a")}
            {renderIconTextPair("share", "Share your or another person's profile.")}
            {renderIconTextPair("send", "Invite your friends!")}
        </div>
    );
};
