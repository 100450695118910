import React, { useState } from 'react';
import { Wrapper } from '../wrapper/wrapper';
import { useMobileDevice } from '../../hooks/device.hooks';

import './pagelet.scss';

interface PageletProps {
    children?: React.ReactNode;
    onHide: () => void;
}

export const Pagelet = ({ children, onHide }: PageletProps) => {
    const isMobileDevice = useMobileDevice();
    const [className] = useState(isMobileDevice ? 'pagelet mobile' : 'pagelet');
    return (
        <Wrapper onClick={onHide}>
            <div className={className}>
                {children}
            </div>
        </Wrapper>
    );
};
