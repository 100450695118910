import { SecuredService } from "./secured.service";
import { ApiResult } from './api-result';

export class MobileConnectionService extends SecuredService {

    private baseUrl = 'api/mobileconnections';

    public getMobileConnectionsCount(tagId: string): Promise<ApiResult<number>> {
        return this.get(`${this.baseUrl}/count/${tagId}`);
    }
}