import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from "../../redux/reducers";
import { addMember, addViewer, loadMembers } from '../../redux/member.state';
import { UserSearch } from '../../models/user-search';

export const useLoyalty = () => {
    const user = useSelector((state: RootState) => state.user.selectedUser);
    const { members, viewers, settings } = useSelector((state: RootState) => state.member);
    const fields = settings?.fields ?? [];
    const [activeIndex, setActiveIndex] = useState(0);
    const [query, setQuery] = useState("");
    const [selectedUser, setSelectedUser] = useState<UserSearch | undefined>();

    const dispatch = useDispatch();

    useEffect(() => {
        if (user?.enterprise) {
            loadMembers()(dispatch);
        }
    }, [dispatch, user]);

    const hideMemberDialog = useCallback(() => {
        setSelectedUser(undefined);
    }, []);

    const handleSearch = useCallback((value: React.Key): void => {
        const val = value.toString();
        setQuery(val);
    }, []);

    const hideResult = useCallback(() => {
        setQuery("");
    }, []);

    const handleSave = useCallback((name?: string, startdate?: Date, enddate?: Date, fields?: any) => {
        if (selectedUser) {
            if (activeIndex === 0) {
                addMember(selectedUser._id, name, startdate, enddate, fields)(dispatch);
            }
            else {
                addViewer(selectedUser._id)(dispatch);
            }
        }
        setSelectedUser(undefined);
    }, [dispatch, activeIndex, selectedUser]);

    return {
        members, viewers, settingFields: fields, enterprise: !!user?.enterprise, activeIndex, setActiveIndex, setSelectedUser, handleSave, hideMemberDialog, query, handleSearch, hideResult, selectedUser
    };
};