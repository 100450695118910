import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signup } from '../../redux/authentication.state';
import { Redirect } from 'react-router-dom';
import { RootState } from '../../redux/reducers';
import { TextField, Checkbox } from 'react-md';
import { HorizontalLoader } from '../horizontal-loader';
import { Button } from '../button';
import { setSignupError } from '../../redux/error.state';
import { SignupPayload } from '../../services/user.service';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../locales/keys';

import './authentication.component.scss';

interface SignupProps {
    onSignup?: (email: string, userId?: string) => void;
}

export const Signup = (props: SignupProps) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const params = new URLSearchParams(window.location.search);
    let queryUsername = params.get('username') ?? '';
    const { value: firstname, bind: bindFirstname } = useTextField('');
    const { value: lastname, bind: bindLastname } = useTextField('');
    const { value: email, bind: bindEmail } = useTextField('');
    const { value: username, bind: bindUsername } = useTextField(queryUsername);
    const { value: password, bind: bindPassword } = useTextField('');
    const { value: confirmPassword, bind: bindconfirmPassword } = useTextField('');
    const [agreed, setAgreed] = useState(false);
    const token = useSelector((state: RootState) => state.authentication.token);
    const error = useSelector((state: RootState) => state.error.signupError);
    const dispatch = useDispatch();

    const handleOnSignUp = (success: boolean) => {
        setLoading(false);
        if (success) {
            dispatch(setSignupError(undefined));
        }
    };

    const onSignup = (payload: SignupPayload) => {
        setLoading(true);
        const params = new URLSearchParams(window.location.search);
        const queryNext = params.get("next");
        const querySource = params.get("source");
        var followingid: string | undefined = undefined;
        if (queryNext && querySource === 'follow') {
            followingid = queryNext;
        }
        signup({ ...payload, followingid }, props.onSignup, handleOnSignUp)(dispatch);
    };

    const onSignupClick = (event: React.FormEvent<HTMLFormElement>) => {
        if (firstname && lastname && email && username && password && (password === confirmPassword) && agreed) {
            const payload = { firstname, lastname, email, username, password, confirmpassword: confirmPassword };
            onSignup(payload);
        }
        event.preventDefault();
    }

    const handleOnCheckboxChange = (checked: boolean) => {
        setAgreed(checked);
    };

    if (token) {
        return <Redirect to="/" />;
    }

    const regexMatchUsername = new RegExp("^[A-Za-z0-9._-]*$");
    const isUsernameValid = username.match(regexMatchUsername);
    const regexMatchEmail = new RegExp("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$");
    const isEmailValid = !email || email.match(regexMatchEmail);
    const enabled = firstname && lastname && email && username && password && (password === confirmPassword) && agreed && isUsernameValid && isEmailValid;
    let tabIndex = 0;
    return (
        <form className="form" onSubmit={onSignupClick}>
            <div className="signup-slogan">
                <span>{t(LocalizationKeys.SignupIts)}<span className="free">{t(LocalizationKeys.Free)}!</span></span>
            </div>
            <div tabIndex={tabIndex++} />
            <TextField fullWidth={true} id="firstname" type="text" label={t(LocalizationKeys.FirstName)} {...bindFirstname} tabIndex={tabIndex++} />
            <TextField fullWidth={true} id="lastname" type="text" label={t(LocalizationKeys.LastName)} {...bindLastname} tabIndex={tabIndex++} />
            <TextField fullWidth={true} id="email" type="email" label={t(LocalizationKeys.Email)} {...bindEmail} tabIndex={tabIndex++} error={!isEmailValid} errorText={!isEmailValid ? t(LocalizationKeys.EmailErrorText) : undefined} />
            <TextField fullWidth={true} id="username" type="text" label={t(LocalizationKeys.Username)} {...bindUsername} error={!!error || !isUsernameValid} errorText={!isUsernameValid ? t(LocalizationKeys.UsernameErrorText) : error || undefined} tabIndex={tabIndex++} />
            <TextField fullWidth={true} id="password" type="password" label={t(LocalizationKeys.Password)} {...bindPassword} error={!!password && !!confirmPassword && password !== confirmPassword} errorText={t(LocalizationKeys.PasswordErrorText)} tabIndex={tabIndex++} />
            <TextField fullWidth={true} id="confirmPassword" type="password" label={t(LocalizationKeys.ConfirmPassword)} {...bindconfirmPassword} tabIndex={tabIndex++} />
            <div className="footer-container">
                <Checkbox id="tc-checkbox" name="" checked={agreed} onChange={handleOnCheckboxChange} /><span>{t(LocalizationKeys.IAgreeToThe)}<a href="/termsandconditions" target="_blank" rel="noopener noreferrer">{t(LocalizationKeys.TermsAndConditions)}</a></span>
            </div>
            <Button type="submit" className="submit" disabled={!enabled || loading}>{loading ? <HorizontalLoader /> : t(LocalizationKeys.CreateYourAccount)}</Button>
        </form>
    );
};

export const useTextField = (initialValue: string) => {
    const [value, setValue] = useState(initialValue);
    return {
        value,
        setValue,
        bind: {
            value,
            onChange: (value: string | number) => {
                setValue(value as string);
            }
        }
    };
};
