import { SecuredService } from "./secured.service";
import { ApiResult } from './api-result';

export class MediaService extends SecuredService {

    private static baseUrl = 'api/medias';

    public uploadMedia(contentdata: any, contenttype: string): Promise<ApiResult<string>> {
        return this.post<string>(MediaService.baseUrl, { contentdata, contenttype });
    }

    public static getMediaUrl(id?: string): string {
        return `/${MediaService.baseUrl}/${id}`;
    }
}