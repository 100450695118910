import { SecuredService } from "./secured.service";
import { ApiResult } from "./api-result";
import { Member } from '../models/member';

export class MemberService extends SecuredService {

    private baseUrl = 'api/members';

    public getAll(): Promise<ApiResult<Member[]>> {
        return this.get(this.baseUrl);
    }

    public save(userid: string, name?: string, startdate?: Date, enddate?: Date, fields?: any): Promise<ApiResult<Member>> {
        return this.post(`${this.baseUrl}/${userid}`, { name, startdate, enddate, fields });
    }

    public update(userid: string, name?: string, startdate?: string, enddate?: string, fields?: any, valid?: boolean): Promise<ApiResult<Member>> {
        return this.put(`${this.baseUrl}/${userid}`, { name, startdate, enddate, fields, valid });
    }
}