import { SecuredService } from "./secured.service";
import { ApiResult } from "./api-result";
import { Selector } from '../models/selector';

export class SelectorService extends SecuredService {

    private baseUrl = 'api/selectors';

    public getSelectors(): Promise<ApiResult<Selector[]>> {
        return this.get(this.baseUrl);
    }

    public addSelector(selector: any): Promise<ApiResult<Selector>> {
        return this.post(this.baseUrl, selector);
    }

    public updateSelector(selector: Selector): Promise<ApiResult<Selector>> {
        return this.put(`${this.baseUrl}/${selector._id}`, selector);
    }

    public deleteSelector(id: string): Promise<ApiResult<void>> {
        return this.delete(`${this.baseUrl}/${id}`);
    }
}