import React, { useState } from 'react';
import { DEFAULT_PROFILE_PICTURE } from '../preview/base64.lib';
import { MediaService } from '../../services/media.service';
import { UserSearch } from '../../models/user-search';
import { FollowingService } from '../../services/following.service';
import { useDispatch } from 'react-redux';
import { addFollowing } from '../../redux/following.state';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../locales/keys';
import { Verified } from '../svg/verified';

interface UserContactProps {
    user: UserSearch;
}

export const UserContactComponent = (props: UserContactProps) => {
    const { t } = useTranslation();
    const [following, setFollowing] = useState<'req' | 'act' | 'blk' | 'dcl' | undefined>(undefined);
    const dispatch = useDispatch();

    const handleFollow = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.stopPropagation();
        event.preventDefault();
        if (following) {
            // toggleUnfollowDialog(true);
        }
        else {
            const service = new FollowingService();
            service.postFollow(props.user._id)
                .then((res) => {
                    if (res?.data) {
                        setFollowing(res.data.status?.code);
                        dispatch(addFollowing(res.data));
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    };

    const renderFollow = (): JSX.Element => {
        let followText = t(LocalizationKeys.Follow);
        let color = 'white';
        let backgroundColor = '#0095f6';
        let border = '';
        if (following === 'act') {
            followText = t(LocalizationKeys.Following);
            backgroundColor = '#00d478';
        }
        else if (following === 'req' || following === 'dcl') {
            followText = t(LocalizationKeys.Requested);
            color = '#101010';
            backgroundColor = 'white';
            border = '1px solid #e0e0e0';
        }
        return (
            <div className="follow" style={{ backgroundColor, color, border }} onClick={handleFollow}>{followText}</div>
        );
    };

    return (
        <a id={`contact-${props.user._id}`} key={`contact-${props.user._id}`} className="paper" href={'/' + props.user.username}>
            <div className="contact-container">
                <img className="contact-profile" src={props.user.media ? MediaService.getMediaUrl(props.user.media) : DEFAULT_PROFILE_PICTURE} alt="Profile" />
                <div className="contact-info">
                    <span>{props.user.fullname}</span>
                    <span style={{ display: 'flex', alignItems: 'center' }}>@{props.user.username} {props.user.verified && <Verified width={16} />}</span>
                </div>
            </div>
            {renderFollow()}
        </a>
    );
};
