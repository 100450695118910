import React, { useState } from 'react';
import { Following } from '../../models/following';
import { DEFAULT_PROFILE_PICTURE } from '../preview/base64.lib';
import { MenuButton, ListItem } from 'react-md';
import { MediaService } from '../../services/media.service';
import { ShareDialog } from '../share.dialog.component';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../locales/keys';

interface ContactProps {
    follow: Following;
    sponsored?: boolean;
    onUnfollow?: (id: string) => void;
}

export const ContactComponent = (props: ContactProps) => {
    const { t } = useTranslation();
    const [shareDialog, showShareDialog] = useState(false);

    const shareMessage = t(LocalizationKeys.CheckOutThisFizlink);
    const shareUrl = `${window.location.host}/${props.follow.followingid.username}`;

    const share = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.stopPropagation();
        const nav = (navigator as any);
        if (nav.share) {
            nav.share({
                text: shareMessage,
                title: 'FizLink',
                url: shareUrl,
            });
        }
        else {
            showShareDialog(true);
        }
    };

    const handleUnfollow = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.stopPropagation();
        if (props.onUnfollow) {
            props.onUnfollow(props.follow.followingid._id);
        }
    };

    const renderUnfollowMenu = (): JSX.Element => {
        const menuItems = [
            <ListItem id="unfollow-contact" key="item-unfollow" primaryText={t(LocalizationKeys.Unfollow)} onClick={handleUnfollow} />,
            <ListItem id="share-contact" key="item-share" primaryText={t(LocalizationKeys.Share)} onClick={share} />,
        ]
        return (
            <MenuButton
                id="unfollow-menu"
                position="br"
                icon={true}
                onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}
                listStyle={{ borderRadius: '16px' }}
                menuItems={menuItems}
            >
                more_vert
            </MenuButton>
        );
    };

    return (
        <a id={`contact-${props.follow._id}`} key={`contact-${props.follow._id}`} className="paper" href={'/' + props.follow.followingid.username}>
            <div className="contact-container">
                <img className="contact-profile" src={props.follow.media ? MediaService.getMediaUrl(props.follow.media) : DEFAULT_PROFILE_PICTURE} alt="Profile" />
                <div className="contact-info">
                    <span>{props.follow.followingid?.fullname}</span>
                    <span>@{props.follow.followingid?.username}</span>
                </div>
            </div>
            {props.follow.status?.code === "req" || props.follow.status?.code === "dcl" ? <div className="requested">{t(LocalizationKeys.Requested)}</div> : renderUnfollowMenu()}
            {props.sponsored ? <div className="sponsored">{/*<FontIcon style={{ fontSize: '12px', color: '#03a9f4', marginRight: '4px' }}>verified</FontIcon>*/}SPONSORED</div> : null}
            {shareDialog ? <ShareDialog visible={shareDialog} link={shareUrl} message={shareMessage} onHide={() => showShareDialog(false)}/> : null}
        </a>
    );
};
