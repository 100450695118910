import React, { useRef, useEffect, useState } from 'react';

export const EditLinkComponent = () => {
    const svgRef = useRef<SVGSVGElement>(null);
    const [svgX, setSvgX] = useState(0);

    useEffect(() => {
        if (svgRef?.current?.clientWidth) {
            setSvgX(svgRef.current.clientWidth - 104);
        }
    }, []);

    const idClip = `diff`;
    const idGradient = `animated-diff`;
    const idAria = `aria`;

    const interval = 0.25;
    const speed = 1.2;
    const keyTimes = `0; ${interval}; 1`;
    const dur = `${speed}s`;

    const backgroundColor = '#10101045';
    const backgroundOpacity = 1;
    const baseUrl = '';
    const foregroundColor = '#10101065';
    const foregroundOpacity = 1;
    const gradientRatio = 2;
    return (
        <div style={{ border: '1px solid #eee', padding: '16px', borderRadius: '8px', backgroundColor: 'white' }}>
            <svg
                ref={svgRef}
                aria-labelledby={idAria}
                role="img"
                width="100%"
            >
                <rect
                    role="presentation"
                    x="0"
                    y="0"
                    width="100%"
                    height="100"
                    clipPath={`url(${baseUrl}#${idClip})`}
                    style={{ fill: `url(${baseUrl}#${idGradient})` }}
                />

                <svg width="100%" height="100%" x={svgX} y="0">
                    <path id="edit-link" transform="translate(0 8)" fill={backgroundColor} d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
                    <path id="lock-link" transform="translate(40 8)" fill={backgroundColor} d="M12 17c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-9h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6h1.9c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm0 12H6V10h12v10z" />
                    <path id="unlock-link" transform="translate(40 8)" fill="#00b16a" d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z" />                    <path id="delete-link" transform="translate(80 8)" fill={backgroundColor} d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
                    <path id="reorder-link" transform="translate(80 48)" fill={backgroundColor} d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
                </svg>

                <defs role="presentation">
                    <clipPath id={idClip}>
                        <rect x="48" y="20" width="calc(100% - 200px)" height="6" rx="3" />
                        <rect x="0" y="56" width="calc(100% - 64px)" height="6" rx="3" />
                        <rect x="0" y="72" width="24" height="6" rx="3" />
                        <circle cx="20" cy="20" r="20" />
                    </clipPath>

                    <linearGradient id={idGradient}>
                        <stop
                            offset="0%"
                            stopColor={backgroundColor}
                            stopOpacity={backgroundOpacity}
                        >
                            <animate
                                attributeName="offset"
                                values={`${-gradientRatio}; ${-gradientRatio}; 1`}
                                keyTimes={keyTimes}
                                dur={dur}
                                repeatCount="indefinite"
                            />
                        </stop>

                        <stop
                            offset="50%"
                            stopColor={foregroundColor}
                            stopOpacity={foregroundOpacity}
                        >
                            <animate
                                attributeName="offset"
                                values={`${-gradientRatio / 2}; ${-gradientRatio / 2}; ${1 +
                                    gradientRatio / 2}`}
                                keyTimes={keyTimes}
                                dur={dur}
                                repeatCount="indefinite"
                            />
                        </stop>

                        <stop
                            offset="100%"
                            stopColor={backgroundColor}
                            stopOpacity={backgroundOpacity}
                        >
                            <animate
                                attributeName="offset"
                                values={`0; 0; ${1 + gradientRatio}`}
                                keyTimes={keyTimes}
                                dur={dur}
                                repeatCount="indefinite"
                            />
                        </stop>
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};
