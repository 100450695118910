import { NavigationItem } from "../navigation/navigation-item";
import { LocalizationKeys } from '../../locales/keys';

const EditProfile: NavigationItem = { name: LocalizationKeys.EditProfile, icon: '', path: '/profile/edit' };
const ChangePassword: NavigationItem = { name: LocalizationKeys.ChangePassword, icon: '', path: '/profile/password' };
const Notifications: NavigationItem = { name: LocalizationKeys.Notifications, icon: '', path: '/profile/notifications' };
const PushNotifications: NavigationItem = { name: LocalizationKeys.PushNotifications, icon: '', path: '/profile/pushnotifications' };

export const ProfileItems = [
    EditProfile,
    ChangePassword,
    Notifications,
    PushNotifications,
];