import { SecuredService } from "./secured.service";
import { ApiResult } from "./api-result";
import { Tag } from '../models/tag';

export class TagService extends SecuredService {

    private baseUrl = 'api/tags';

    public getTags(): Promise<ApiResult<Tag[]>> {
        return this.get(this.baseUrl);
    }

    public updateTag(tag: Tag): Promise<ApiResult<Tag>> {
        return this.put(this.baseUrl + `/${tag._id}`, tag);
    }

    public async updateTagLogo(id: string, media: any, mediatype: string): Promise<ApiResult<Tag>> {
        return this.put(`${this.baseUrl}/logo/${id}`, { media, mediatype});
    }
}