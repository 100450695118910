import { useState, useEffect } from 'react';
import { useMobileDevice, useTablet } from './device.hooks';

export const useQr = (): boolean => {
    var mql = window.matchMedia("(orientation: landscape)");
    const amql = window.matchMedia("(min-aspect-ratio: 13/9)");
    const isMobileDevice = !!useMobileDevice();
    const isTablet = useTablet();

    const [qr, setQr] = useState(mql.matches && amql.matches && isMobileDevice && !isTablet);

    const handleResize = () => {
        const isLandscape = window.matchMedia("(orientation: landscape)").matches;
        const isAndroidLandscape = window.matchMedia("(min-aspect-ratio: 13/9)").matches;
        setQr(isLandscape && isAndroidLandscape && isMobileDevice && !isTablet);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // cleanup
        return () => {
            window.removeEventListener('resize', handleResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return qr;
};