import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { extractData } from './authentication.state';
import { UserSetting } from '../models/user-setting';
import { UserSettingService } from '../services/user-setting.service';

export interface UserSettingState {
    settings?: UserSetting;
}

const initialState: UserSettingState = {
    settings: undefined
};

const setSettingsReducer = (state: UserSettingState, action: PayloadAction<UserSetting>) => {
    state.settings = action.payload;
};

const { reducer, actions } = createSlice({
    name: 'usersetting',
    initialState,
    reducers: {
        setSettings: setSettingsReducer,
    },
});

export { reducer as UserSettingReducer };

export const getSettings = () => {
    return (async (dispatch: any) => {
        const service = new UserSettingService();
        const res = await service.getSettings();
        const settings = extractData(res, dispatch);
        if (settings) {
            dispatch(actions.setSettings(settings));
        }
    });
}

export const updateSettings = (s: UserSetting) => {
    return (async (dispatch: any) => {
        const service = new UserSettingService();
        const res = await service.updateSettings(s);
        const settings = extractData(res, dispatch);
        if (settings) {
            dispatch(actions.setSettings(settings));
        }
    });
}