import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MobileConnectionService } from "../services/mobile-connection.service";
import { extractData } from './authentication.state';

export interface MobileConnectionState {
    connections: number;
}

const initialState: MobileConnectionState = {
    connections: 0,
};

const loadMobileConnectionsReducer = (state: MobileConnectionState, action: PayloadAction<number>) => {
    state.connections = action.payload;
}

const { reducer, actions } = createSlice({
    name: 'mobileconnections',
    initialState,
    reducers: {
        loadMobileConnections: loadMobileConnectionsReducer,
    },
});

export { reducer as MobileConnectionReducer };

export const loadMobileConnections = (tagId: string) => {
    return (async (dispatch: any) => {
        const service = new MobileConnectionService();
        const res = await service.getMobileConnectionsCount(tagId);
        const connections = extractData(res, dispatch) || 0;
        dispatch(actions.loadMobileConnections(connections));
    });
}