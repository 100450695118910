import { SecuredService } from "./secured.service";
import { ApiResult } from "./api-result";
import { PushNotification } from '../models/push-notification';

export class PushNotificationService extends SecuredService {

    private baseUrl = 'api/pushnotifications';

    public getPushNotifications(): Promise<ApiResult<PushNotification[]>> {
        return this.get(this.baseUrl);
    }

    public markAsRead(notificationid: string): Promise<ApiResult<void>> {
        return this.put(`${this.baseUrl}/read/${notificationid}`);
    }
}