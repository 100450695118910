import React from 'react';
import { DEFAULT_PROFILE_PICTURE } from '../preview/base64.lib';
import { MediaService } from '../../services/media.service';
import QRCode from 'qrcode.react';
import { FizLink } from '../svg/fizlink-logo';

import './qr-profile.component.scss';

interface QrProfileProps {
    fullname?: string;
    username?: string;
    votieurl?: string;
    picture?: string;
    title?: string;
}

export const QrProfile = (props: QrProfileProps) => {
    const profilePicture = props.picture ? MediaService.getMediaUrl(props.picture) : DEFAULT_PROFILE_PICTURE;
    return (
        <div className="qr-profile">
            <div className="contact-info">
                <img alt="Profile" className="profile-picture" src={profilePicture} style={{ borderRadius: '50%' }} />
                <span>{props.fullname}<br />@{props.username}<br /><span style={{ color: 'gray', fontSize: '20px' }}>{props.title}</span></span>
            </div>
            <div className="contact-qr">
                <QRCode className="qr-code" value={`${window.location.host}/${props.votieurl}`} renderAs='svg' />
                <FizLink height={32} />
            </div>
        </div>
    );
};
