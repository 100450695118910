import JwtDecode from 'jwt-decode';
import { Token } from '../models/token';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AuthenticationService } from '../services/authentication.service';
import { UserService, SignupPayload } from '../services/user.service';
import { setLoginError, setSignupError } from './error.state';
import { ApiResult } from '../services/api-result';

export interface AuthenticationState {
    token?: Token;
    language: string;
    onboarding: boolean;
    profileOnboarding: boolean;
}

const initialToken = localStorage.getItem('currentUser');
const initialState: AuthenticationState = {
    token: initialToken ? JwtDecode<Token>(initialToken) : undefined,
    language: 'en',
    onboarding: false,
    profileOnboarding: false,
};

const setTokenReducer = (state: AuthenticationState, action: PayloadAction<Token | undefined>) => {
    state.token = action.payload;
}

const logoutReducer = (state: AuthenticationState, _: PayloadAction) => {
    state.token = undefined;
    AuthenticationService.clearToken();
    localStorage.removeItem('currentUser');
}

const setLanguageReducer = (state: AuthenticationState, action: PayloadAction<string>) => {
    state.language = action.payload;
}

const showOnboardingReducer = (state: AuthenticationState, action: PayloadAction<boolean>) => {
    state.onboarding = action.payload;
}

const showProfileOnboardingReducer = (state: AuthenticationState, action: PayloadAction<boolean>) => {
    state.profileOnboarding = action.payload;
}

const { reducer, actions } = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        setToken: setTokenReducer,
        logout: logoutReducer,
        setLanguage: setLanguageReducer,
        showOnboarding: showOnboardingReducer,
        showProfileOnboarding: showProfileOnboardingReducer,
    },
});

export { reducer as AuthenticationReducer };
export const { logout, setLanguage, showOnboarding, showProfileOnboarding } = actions;

export const login = (username: string, password: string, callback?: () => void, onSuccess?: () => void) => {
    return (async (dispatch: any) => {
        dispatch(setLoginError(undefined));
        const service = new AuthenticationService();
        const res = await service.login(username, password);
        const data = res.data;
        if (data?.token) {
            AuthenticationService.saveToken(data.token);
            localStorage.setItem('currentUser', data.token);
            if (onSuccess) {
                onSuccess();
            }
            const token = JwtDecode<Token>(data.token);
            dispatch(actions.setToken(token));
            // dispatch(getEntity(token.company_id));
        }
        else {
            dispatch(setLoginError(res.error ?? "Couldn't find your FizLink account"));
        }
        if (callback) {
            callback();
        }
    });
}

export const signup = (payload: SignupPayload, onSuccess?: (email: string) => void, callback?: (success: boolean) => void) => {
    return (async (dispatch: any) => {
        const userService = new UserService();
        const res = await userService.signup(payload);
        if (res.success) {
            if (onSuccess) {
                onSuccess(payload.email);
            }
        }
        else {
            dispatch(setSignupError(res.error));
        }
        if (callback) {
            callback(res.success);
        }
    });
}

export const extractData = <T>(result: ApiResult<T>, dispatch: any): T | undefined => {
    if (result.success && result.data) {
        return result.data;
    }
    else {
        if (result.code === 203) {
            // invalid token
            dispatch(actions.logout());
        }
        return undefined;
    }
}