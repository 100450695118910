import { useCallback, useState } from "react";
import { updateMember } from '../../../redux/member.state';
import { useDispatch } from 'react-redux';
import { Field } from '../../../models/member-setting';

export interface MemberItemProps {
    id: string;
    settingFields: Field[];
    fullname: string;
    username: string;
    name?: string;
    startdate?: string;
    enddate?: string;
    fields?: any;
    valid?: boolean;
    auditcount?: number;
}

export const useMemberItem = ({ id, settingFields, fullname, username, name, startdate, enddate, fields, valid, auditcount }: MemberItemProps) => {
    const [dialog, showDialog] = useState(false);
    const dispatch = useDispatch();

    const toggleValidity = useCallback(() => {
        updateMember(id, name, startdate, enddate, fields, !valid)(dispatch);
    }, [dispatch, id, name, startdate, enddate, fields, valid]);

    const toggleDialog = useCallback(() => {
        showDialog(!dialog);
    }, [dialog]);

    const handleSave = useCallback((name?: string, startdate?: Date, enddate?: Date, fields?: any) => {
        updateMember(id, name, startdate?.toUTCString(), enddate?.toUTCString(), fields)(dispatch);
        showDialog(false);
    }, [dispatch, id]);

    return {
        id, settingFields, fullname: name && name.length ? name : fullname, username, startdate, enddate, fields, valid, auditcount, toggleValidity, dialog, toggleDialog, handleSave
    }
};