import React from 'react';
import { Button } from '@react-md/button';
import { Dialog, DialogContent, DialogFooter } from '@react-md/dialog';
import { TextField } from 'react-md';
import { useState } from 'react';
import { useCallback } from 'react';
import { useTextField } from '../../authentication/signup.component';
import { Field } from '../../../models/member-setting';
import { useEffect } from 'react';

interface MemberDialogProps {
    visible: boolean;
    settingFields: Field[];
    startdate?: string;
    enddate?: string;
    name?: string;
    fields?: any;
    onSave: (name?: string, startdate?: Date, enddate?: Date, fields?: any) => void;
    onHide: () => void;
}

export const MemberDialog = ({ visible, settingFields, startdate, enddate, name, fields, onSave, onHide }: MemberDialogProps) => {
    const [startDate] = useState<Date | undefined>(startdate ? new Date(startdate) : undefined);
    const [endDate] = useState<Date | undefined>(enddate ? new Date(enddate) : undefined);
    const { bind: bindName } = useTextField(name ?? "");
    const [updatedFields, setUpdatedFields] = useState<any>();

    useEffect(() => {
        setUpdatedFields(fields);
    }, [fields]);

    const handleOnChange = useCallback((key: string, value: string | number) => {
        const val = value as string;
        setUpdatedFields({ ...updatedFields, [key]: val });
    }, [updatedFields]);

    const handleSave = useCallback(() => {
        onSave(bindName.value, startDate, endDate, updatedFields);
    }, [onSave, bindName.value, startDate, endDate, updatedFields]);

    return (
        <Dialog
            id="member-dialog"
            visible={visible}
            onRequestClose={onHide}
            aria-labelledby="member-title"
            style={{ borderRadius: '16px' }}
        >
            <DialogContent>
                <h3>Edit Member</h3>
                <TextField
                    id="member-name"
                    {...bindName}
                    placeholder="Member name..."
                />
                {
                    settingFields.map((f, i) => {
                        return (
                            <TextField
                                id={`${f.name}_${i}`}
                                key={`${f.name}_${i}`}
                                value={updatedFields?.[f._id] ?? ""}
                                onChange={(v) => handleOnChange(f._id, v)}
                                label={f.name}
                            />
                        )
                    })
                }
            </DialogContent>
            <DialogFooter>
                <Button
                    id="member-cancel"
                    onClick={onHide}
                    type="button"
                >
                    Cancel
                </Button>
                <Button
                    id="member-save"
                    onClick={handleSave}
                    type="button"
                    style={{ color: '#03a9f4' }}
                >
                    Save
                </Button>
            </DialogFooter>
        </Dialog>
    );
};
