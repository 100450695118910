import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../redux/authentication.state';
import { Redirect } from 'react-router-dom';
import { RootState } from '../../redux/reducers';
import { TextField } from 'react-md';
import { useTextField } from './signup.component';
import { HorizontalLoader } from '../horizontal-loader';
import { Button } from '../button';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../locales/keys';

import './authentication.component.scss';

interface LoginProps {
    onLogin?: () => void;
}

export const Login = (props: LoginProps) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { value: username, bind: bindUsername } = useTextField('');
    const { value: password, bind: bindPassword } = useTextField('');
    const token = useSelector((state: RootState) => state.authentication.token);
    const error = useSelector((state: RootState) => state.error.loginError);
    const dispatch = useDispatch();

    const onLoginClick = (event: React.FormEvent<HTMLFormElement>) => {
        if (username && password) {
            setLoading(true);
            login(username, password, () => setLoading(false), props.onLogin)(dispatch);
        }
        event.preventDefault();
    }

    if (token) {
        return <Redirect to="/" />;
    }

    const enabled = username && password;

    return (
        <form className="form" onSubmit={onLoginClick}>
            <TextField fullWidth={true} id="username" type="text" label={t(LocalizationKeys.UsernameEmail)} {...bindUsername} error={!!error} errorText={error} />
            <TextField fullWidth={true} id="password" type="password" label={t(LocalizationKeys.Password)} {...bindPassword} />
            <Button type="submit" className="submit" disabled={!enabled || loading}>{loading ? <HorizontalLoader /> : t(LocalizationKeys.Login)}</Button>
            <div className="footer-container">
                <a className="forgot-password-link" href="/forgotpassword">{t(LocalizationKeys.ForgotPassword)}</a>
            </div>
        </form>
    );
};
