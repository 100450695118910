import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { RootState } from '../../redux/reducers';
import { TextField } from 'react-md';
import { HorizontalLoader } from '../horizontal-loader';
import { Button } from '../button';
import { useTextField } from './signup.component';
import { UserService } from '../../services/user.service';
import { SuccessErrorMessage } from './successerrormessage.component';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../locales/keys';

import './authentication.component.scss';

export const ResetPassword = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [message, showMessage] = useState(false);
    const { value: password, bind: bindPassword } = useTextField('');
    const { value: confirmPassword, bind: bindConfirmPassword } = useTextField('');
    const token = useSelector((state: RootState) => state.authentication.token);
    const history = useHistory();

    const handleOnResetPassword = () => {
        setLoading(false);
        showMessage(true);
    };

    const onResetPassword = (event: React.FormEvent<HTMLFormElement>) => {
        if (password) {
            setLoading(true);
            const params = new URLSearchParams(window.location.search);
            const id = params.get("id");
            if (id && password === confirmPassword) {
                const service = new UserService();
                service.resetPassword(id, password)
                    .then(() => {
                        handleOnResetPassword();
                    })
                    .catch(() => {
                        handleOnResetPassword();
                    });
            }
            else {
                handleOnResetPassword();
            }
        }
        event.preventDefault();
    }

    if (token) {
        return <Redirect to="/" />;
    }

    let tabIndex = 0;
    const enabled = password && confirmPassword && password === confirmPassword;
    const renderForm = () => {
        return (
            <form className="form" onSubmit={onResetPassword}>
                <div className="forgot-password">
                    <span className="header">Reset your password</span>
                </div>
                <div tabIndex={tabIndex++} />
                <TextField fullWidth={true} id="password" type="password" label={t(LocalizationKeys.Password)} {...bindPassword} error={!!password && !!confirmPassword && password !== confirmPassword} errorText={t(LocalizationKeys.PasswordErrorText)} tabIndex={tabIndex++} />
                <TextField fullWidth={true} id="confirmPassword" type="password" label={t(LocalizationKeys.ConfirmPassword)} {...bindConfirmPassword} tabIndex={tabIndex++} />
                <Button type="submit" className="submit" disabled={!enabled || loading}>{loading ? <HorizontalLoader /> : t(LocalizationKeys.ResetYourPassword)}</Button>
            </form>
        );
    };

    return message ? <SuccessErrorMessage title={t(LocalizationKeys.PasswordReset)} subtitle1={t(LocalizationKeys.LoginToContinue)} onClick={() => history.push('/login')} /> : renderForm();
};
