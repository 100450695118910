import React, { useRef, useState } from 'react';
import { Dialog, DialogContent } from '@react-md/dialog';
import { Button } from '@react-md/button';
import { FilePond, File, registerPlugin } from 'react-filepond';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginTypeValidation from 'filepond-plugin-file-validate-type';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../locales/keys';

import 'filepond/dist/filepond.min.css';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginTypeValidation);

interface FileUploadProps {
    visible: boolean;
    onHide: () => void | undefined;
    onFileUpload: (file: Blob, onDone: () => void, onError: () => void) => void;
    acceptedFileTypes?: string[]; 
}

export const FileUploadDialog = (props: FileUploadProps) => {
    const { t } = useTranslation();
    const pondRef = useRef(null);
    const [file, setFile] = useState<File | undefined>(undefined);

    const serverProcess = (_fieldName: string, file: Blob, _metadata: { [key: string]: any }, load: (p: string | { [key: string]: any }) => void, error: (errorText: string) => void, progress: any) => {
        const accepted = !props.acceptedFileTypes
            ? file.type.includes('image')
            : props.acceptedFileTypes.flatMap((a) => a.split('/')).some((a) => file.type.includes(a));
        if (accepted) {
            props.onFileUpload(
                file,
                () => {
                    progress(false, 100, 100);
                    load("id");
                },
                () => {
                    error("Upload failed.");
                }
            );
        }
        else {
            error("That file type is not supported.");
        }
    };

    const handleUpdateFiles = (files: File[]) => {
        if (files.length) {
            setFile(files[0]);
        }
    };

    return (
        <Dialog id="file-upload-dialog" visible={props.visible} onRequestClose={props.onHide} aria-labelledby="delete-selector-title" style={{ borderRadius: '16px' }}>
            <DialogContent>
                <FilePond
                    ref={pondRef}
                    files={file ? [file] : undefined}
                    metadata={{ allowImageExifOrientation: true }}
                    allowMultiple={false}
                    instantUpload={true}
                    allowRevert={false}
                    acceptedFileTypes={props.acceptedFileTypes ?? ["image/*"]}
                    server={{ process: serverProcess }}
                    name="files"
                    onupdatefiles={handleUpdateFiles}
                />
                <Button
                    id="file-upload-cancel"
                    onClick={props.onHide}
                    type="button"
                    style={{ width: '100%' }}
                >
                    {t(LocalizationKeys.Close)}
                </Button>
            </DialogContent>
        </Dialog>
    );
};
