import React from 'react';
import { Table, TableBody, TableCell, TableHeader, TableRow } from '@react-md/table';
import { MemberItem } from '../member-item/member-item';
import { FontIcon } from 'react-md';
import { MemberTableProps, useMemberTable } from './member-table.hooks';
import { FieldDialog } from '../field-dialog/field-dialog';

export const MemberTable = (props: MemberTableProps) => {
    const { members, fields, dialog, toggleDialog, handleSave } = useMemberTable(props);
    return (
        <>
            <Table fullWidth={true}>
                <TableHeader>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>User</TableCell>
                        <TableCell>Scan Count</TableCell>
                        <TableCell>Start Date</TableCell>
                        <TableCell>End Date</TableCell>
                        {
                            fields.map((f, i) => {
                                return <TableCell key={`${f.name}_${i}`}>{f.name}</TableCell>
                            })
                        }
                        <TableCell>
                            <div className="valid-button" onClick={toggleDialog}>
                                <FontIcon>add</FontIcon>
                            </div>
                        </TableCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {members.map((m) => {
                        return <MemberItem key={m.user._id} id={m.user._id} settingFields={fields} fullname={m.user.contact.fullname} name={m.name} username={m.user.username} startdate={m.startdate} enddate={m.enddate} fields={m.fields} valid={m.valid} auditcount={m.auditcount} />
                    })}
                </TableBody>
            </Table>
            {dialog ? <FieldDialog visible={dialog} onSave={handleSave} onHide={toggleDialog} /> : null}
        </>
    );
};
