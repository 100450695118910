import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { GenericPhone } from './generic-phone';
import { Selector } from '../../models/selector';
import { FizLink } from '../svg/fizlink-logo';
import { MobileSelector } from '../../models/mobile-selector';
import { Mobile } from '../../models/mobile';
import { MobileSelectorComponent } from '../mobile-selectors/mobile-selector.component';
import { MobileProfileComponent } from '../mobile-selectors/mobile-identity.component';
import { MediaService } from '../../services/media.service';

import './link-preview.component.scss';

interface LinkPreviewProps {
    mobile: Mobile;
    selectors: Selector[];
    mobileSelectors: MobileSelector[];
    size?: number;
}

export const LinkPreviewComponent = (props: LinkPreviewProps) => {
    const token = useSelector((state: RootState) => state.authentication.token);
    const user = useSelector((state: RootState) => state.user.selectedUser);

    const renderSelector = (mobileSelector: MobileSelector): JSX.Element | null => {
        const selector = props.selectors.find((s) => s._id === mobileSelector.selector);
        if (!selector) {
            return null;
        }
        // eslint-disable-next-line no-template-curly-in-string
        let redirecturl = (mobileSelector.redirecturl?.replace('${value}', '') ?? "") + mobileSelector.value;
        if (selector.allowmedia === 'yes' && mobileSelector.value) {
            redirecturl = MediaService.getMediaUrl(mobileSelector.value);
        }
        const selectorId = mobileSelector.selector;
        return (
            <MobileSelectorComponent
                key={`selector-${selectorId}`}
                selectorId={selectorId}
                redirecturl={redirecturl}
                media={selector.media ?? ""}
                caption={selector.caption?.en ?? ""}
                privacy={mobileSelector.privacy}
                highlight={mobileSelector.highlight}
                listview={mobileSelector.listview}
            />
        );
    };

    const page = props.mobile.pages[0];
    return (
        <div className="link-preview">
            <GenericPhone>
                <div className="preview">
                    <div className="preview-body">
                        <MobileProfileComponent
                            info={{ 
                                fullname: user?.contact?.fullname ?? token?.fullname ?? "",
                                username: token?.username ?? "",
                                title: page.title,
                                bio: page.bio, 
                                image: page.picture,
                                verified: user?.verified
                            }}
                        />
                        <div className="links">
                            {props.mobileSelectors.map((r) => renderSelector(r))}
                        </div>
                    </div>
                    <div className="preview-footer"><FizLink height={24} /></div>
                </div>
            </GenericPhone>
        </div>
    );
};
