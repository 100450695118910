import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PushNotification } from '../models/push-notification';
import { PushNotificationService } from '../services/push-notification.service';
import { extractData } from './authentication.state';

export interface PushNotificationState {
    pushnotifications: PushNotification[];
}

const initialState: PushNotificationState = {
    pushnotifications: [],
};

const loadPushNotificationsReducer = (state: PushNotificationState, action: PayloadAction<PushNotification[]>) => {
    state.pushnotifications = action.payload;
}

const deletePushNotificationReducer = (state: PushNotificationState, action: PayloadAction<string>) => {
    state.pushnotifications = state.pushnotifications.filter((n) => n._id !== action.payload);
}

const { reducer, actions } = createSlice({
    name: 'pushnotification',
    initialState,
    reducers: {
        loadPushNotifications: loadPushNotificationsReducer,
        deletePushNotification: deletePushNotificationReducer,
    },
});

export { reducer as PushNotificationReducer };

export const loadPushNotifications = () => {
    return (async (dispatch: any) => {
        const service = new PushNotificationService();
        const res = await service.getPushNotifications();
        const pushnotifications = extractData(res, dispatch) || [];
        dispatch(actions.loadPushNotifications(pushnotifications));
    });
};

export const markAsRead = (notificationid: string) => {
    return (async (dispatch: any) => {
        const service = new PushNotificationService();
        await service.markAsRead(notificationid);
        dispatch(actions.deletePushNotification(notificationid));
    });
};