import React from 'react';
import { PageWrapper } from './page-wrapper.component';
import { useMobileDevice } from '../../hooks/device.hooks';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../locales/keys';

export const PageTwo = () => {
    const { t } = useTranslation();
    const isMobileDevice = useMobileDevice();

    const renderText = () => {
        return (
            <div style={{ textAlign: 'left' }}>
                <h1>{t(LocalizationKeys.PageTwoH1)}</h1>
                <h2>{t(LocalizationKeys.PageTwoH2)}</h2>
            </div>
        );
    };

    const renderImage = () => {
        return (
            <div className="social-selectors">
                <img width="100%" src="/image1.png" alt="hey" />
            </div>
        );
    };

    return (
        <PageWrapper>
            <div className={isMobileDevice ? 'split-page-mobile' : 'split-page'}>
                {renderText()}
                {renderImage()}
            </div>
        </PageWrapper>
    );
};
