import React from 'react';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import reducers from './redux/reducers';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { PrivateRoute } from './private.route';
import { Authentication } from './components/authentication/authentication.component';
import { Dashboard } from './components/dashboard/dashboard.component';
import { Home } from './components/landingpage/home.component';
import { PageNotFound } from './components/page-not-found.component';
import { MobileComponent } from './components/mobile/mobile.component';
import { TermsAndConditions } from './components/terms-and-conditions/terms-and-conditions';

import './fizlink.scss';

export const store = configureStore({
  reducer: reducers,
  middleware: [...getDefaultMiddleware()],
});

export const FizLink = () => {
  return (
    <Provider store={store}>
      <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
      <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
      <link rel="stylesheet" href="https://unpkg.com/react-md@1.16.0/dist/react-md.indigo-pink.min.css" />
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
      <Router>
        <Switch>
          <Route exact={true} path="/home" component={Home} />
          <Route exact={true} path="/termsandconditions" component={TermsAndConditions} />
          <Route exact={true} path={["/login", "/signup", "/forgotpassword", "/resetpassword"]} component={Authentication} />
          <PrivateRoute exact={true} path={["/", "/setup/contacts", "/setup/users", "/setup/loyalty", "/profile/*"]} component={Dashboard} />
          <Route path="/pagenotfound" component={PageNotFound} />
          <Route exact={true} path="/:username" component={MobileComponent} />
          <Route path="/*" component={PageNotFound} />
        </Switch>
      </Router>
    </Provider>
  );
};

