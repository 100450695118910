import React, { useCallback, useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { PrivateRoute } from '../../private.route';
import { EditProfileComponent } from './edit-profile.component';
import { PasswordComponent } from './password.component';
import { ProfileItems } from './profile-item';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { getUser } from '../../redux/user.state';
import { useTranslation } from 'react-i18next';
import { useMobileDevice } from '../../hooks/device.hooks';
import { MenuButton } from 'react-md';
import { NavigationItem } from '../navigation/navigation-item';
import { NotificationsComponent } from './notifications/notifications.component';
import { PushNotificationsComponent } from './push-notifications/push-notifications.component';
import { getSettings } from '../../redux/user-setting.state';

import './profile.component.scss';

export const ProfileComponent = () => {
    const { t } = useTranslation();
    const token = useSelector((state: RootState) => state.authentication.token);
    const isMobileDevice = useMobileDevice();

    const dispatch = useDispatch();

    useEffect(() => {
        if (token) {
            getUser(token.user_id)(dispatch);
            getSettings()(dispatch);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    const renderOption = useCallback((p: NavigationItem) => {
        const selected = window.location.pathname === p.path;
        let className = "option-item";
        className += selected ? " selected" : "";
        return (
            <a key={`nav-item-${p.name}`} className={className} href={p.path}>
                {t(p.name)}
            </a>
        );
    }, [t]);

    return (
        <div className="profile-container">
            {!isMobileDevice && <div className="profile-options">
                {ProfileItems.map(renderOption)}
            </div>}
            <div className="profile-content">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <h3>{t(ProfileItems.find((p) => p.path === window.location.pathname)?.name ?? "")}</h3>
                    {isMobileDevice && <MenuButton
                        id="option-menu"
                        position="bl"
                        icon={true}
                        onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}
                        listStyle={{ borderRadius: '16px' }}
                        style={{ marginBottom: '14px', marginLeft: '8px' }}
                        menuItems={ProfileItems.map(renderOption)}
                    >
                        arrow_drop_down
                    </MenuButton>}
                </div>
                <Switch>
                    <PrivateRoute path="/profile/edit" component={EditProfileComponent} />
                    <PrivateRoute path="/profile/password" component={PasswordComponent} />
                    <PrivateRoute path="/profile/notifications" component={NotificationsComponent} />
                    <PrivateRoute path="/profile/pushnotifications" component={PushNotificationsComponent} />
                </Switch>
            </div>
        </div>
    );
};
