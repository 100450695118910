import React, { useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Login } from './login.component';
import { Signup } from './signup.component';
import { FizLink } from '../svg/fizlink-logo';
import { SuccessErrorMessage } from './successerrormessage.component';
import { useMobileDevice } from '../../hooks/device.hooks';
import { FollowingService } from '../../services/following.service';
import { ForgotPassword } from './forgot-password.component';
import { ResetPassword } from './reset-password.component';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../locales/keys';

import './authentication.component.scss';

export const Authentication = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const isMobile = useMobileDevice();
    const [thankyou, setThankYou] = useState<string | undefined>(undefined);
    const searchParams = new URLSearchParams(window.location.search);
    const searchError = searchParams.get("error") === 'true';
    const hasTitle = searchParams.has("title");
    const [showMessage, setShowMessage] = useState(hasTitle);

    const pathname = window.location.pathname;
    const isLogin = pathname === '/login';

    const handleNext = async () => {
        const params = new URLSearchParams(window.location.search);
        const queryNext = params.get("next");
        const querySource = params.get("source");
        if (queryNext && querySource) {
            if (querySource === 'follow') {
                const service = new FollowingService();
                await service.postFollow(queryNext);
            }
            else if (querySource === 'approve') {
                const service = new FollowingService();
                await service.approveFollower(queryNext);
            }
        }
    };

    const handleSignup = (email: string) => {
        history.push("/login");
        setThankYou(email);
    };

    const handleLogin = () => {
        handleNext();
    };

    const handleOnSuccessClick = () => {
        setThankYou(undefined);
        setShowMessage(false);
        history.replace("/login");
    };

    const title = hasTitle ? searchParams.get("title") ?? "" : t(LocalizationKeys.ThankYouForSigningUp);
    const subtitle1 = hasTitle ? searchParams.get("message") ?? "" : t(LocalizationKeys.WeveSentAnEmail, { email: thankyou });
    const subtitle2 = hasTitle ? undefined : t(LocalizationKeys.VerifyYourEmail);
    const subtitle3 = hasTitle ? undefined : t(LocalizationKeys.CheckYourSpam);
    return (
        <div className="login">
            {isMobile ? null : <div className="image" />}
            <div className="authentication" style={{ width: isMobile ? '100%' : '40%' }}>
                <div className="login-header">
                    <a href="/home" tabIndex={-1}>
                        <FizLink height={36} />
                    </a>
                    {!thankyou ? <a className="signup-login" href={isLogin ? "/signup" : "/login"}>{t(isLogin ? LocalizationKeys.Signup : LocalizationKeys.Login)}</a> : null}
                </div>
                <Switch key={pathname}>
                    <Route exact={true} path="/login">
                        {thankyou || showMessage ? <SuccessErrorMessage title={title} subtitle1={subtitle1} subtitle2={subtitle2} subtitle3={subtitle3} error={searchError} onClick={handleOnSuccessClick} /> : <Login onLogin={handleLogin} />}
                    </Route>
                    <Route exact={true} path="/signup">
                        <Signup onSignup={handleSignup} />
                    </Route>
                    <Route exact={true} path="/forgotpassword" component={ForgotPassword} />
                    <Route exact={true} path="/resetpassword" component={ResetPassword} />
                </Switch>
            </div>
        </div>
    );
};
