import React from 'react';
import { Selector } from '../../models/selector';
import { Dialog, DialogFooter, DialogContent } from '@react-md/dialog';
import { Button } from '@react-md/button';

interface DeleteSelectorProps {
    selector: Selector;
    visible: boolean;
    onHide: () => void | undefined;
    onDelete: () => void;
}

export const DeleteSelectorDialog = (props: DeleteSelectorProps) => {
    return (
        <Dialog id="delete-selector-dialog" visible={props.visible} onRequestClose={props.onHide} aria-labelledby="delete-selector-title" style={{ borderRadius: '16px' }}>
            <DialogContent>
                <h4>Are you sure you want to delete {props.selector.caption?.en}?</h4>
            </DialogContent>
            <DialogFooter>
                <Button
                    id="delete-cancel"
                    onClick={props.onHide}
                    type="button"
                >
                    Cancel
                </Button>
                <Button id="delete-delete" onClick={props.onDelete} type="reset" theme="warning">
                    Delete
                </Button>
            </DialogFooter>
        </Dialog>
    );
};
