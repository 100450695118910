export interface NavigationItem {
    name: string;
    icon: string;
    path: string;
}

const Overview: NavigationItem = { name: 'Overview', icon: 'dashboard', path: '/' };
const Users: NavigationItem = { name: 'Users', icon: 'account_circle', path: '/setup/users' };
const Selectors: NavigationItem = { name: 'Selectors', icon: 'assessment', path: '/setup/selectors' };

export const NavigationItems = [
    Overview,
    Users,
    Selectors,
];