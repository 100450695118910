import React, { useCallback, useState } from 'react';
import { Widget } from './widgets/widget.component';
import { Button, Snackbar, FontIcon } from 'react-md';
import { Tooltip } from '../tooltip';
import { updateMobile } from '../../redux/mobile.state';
import { Mobile } from '../../models/mobile';
import { useDispatch, useSelector } from 'react-redux';
import { InviteDialog } from './invite-dialog.component';
import { UserService } from '../../services/user.service';
import { RootState } from '../../redux/reducers';
import { ShareDialog } from '../share.dialog.component';
import { FileUploadDialog } from './file-upload.dialog';
import { useHistory } from 'react-router-dom';
import { MobileProfileComponent } from '../mobile-selectors/mobile-identity.component';
import { formatNumber } from './widgets/selector.component';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../locales/keys';
import { ProfileOnboarding } from './profile-onboarding';
import { showProfileOnboarding } from '../../redux/authentication.state';
import { QrDialog } from '../qr-dialog';
import { useMobileDevice } from '../../hooks/device.hooks';
import QRCode from 'qrcode.react';

import './fiz-link-widget.component.scss';

interface FizLinkWidgetProps {
    link?: string;
    mobile?: Mobile;
    views: number;
    clicks: number;
    followers: number;
}

export const FizLinkWidget = (props: FizLinkWidgetProps) => {
    const { t } = useTranslation();
    const [toasts, setToasts] = useState<Array<{ text: React.ReactNode }>>([]);
    const [dialog, setDialog] = useState(false);
    const [shareDialog, setShareDialog] = useState(false);
    const [fileUpload, showFileUpload] = useState(false);
    const [qrDialog, showQrDialog] = useState(false);
    const { token, onboarding, profileOnboarding } = useSelector((state: RootState) => state.authentication);
    const user = useSelector((state: RootState) => state.user.selectedUser);
    const history = useHistory();
    const mobile = useMobileDevice();
    const dispatch = useDispatch();

    const share = (str: string) => {
        const nav = (navigator as any);
        if (nav.share) {
            nav.share({
                text: t(LocalizationKeys.CheckOutMyFizlink),
                title: 'FizLink',
                url: str,
            });
        }
        else {
            setShareDialog(true);
        }
    };

    const sendInvitation = (emails: string[]) => {
        const service = new UserService();
        service.invite(emails)
            .then((res) => {
                if (res.success) {
                    const toastMessage = t(emails.length > 1 ? LocalizationKeys.InvitationsSent : LocalizationKeys.InvitationSent);
                    setToasts([...toasts, { text: toastMessage }]);
                }
                setDialog(false);
            })
            .catch(() => {
                setDialog(false);
            });
    };

    const renderSnackbar = (): JSX.Element => {
        return (
            <Snackbar
                id="copied-snackbar"
                toasts={toasts}
                autohide={true}
                onDismiss={() => setToasts([])}
            />
        );
    };

    const handleFileUpload = (file: Blob, onDone: () => void, onError: () => void) => {
        const reader = new FileReader();

        reader.onload = (ev: ProgressEvent<FileReader>) => {
            if (ev?.target && props.mobile) {
                const pages = [...props.mobile.pages];
                pages.splice(0, 1, { ...pages[0], profilepicture: ev.target.result, picturecontent: file.type });
                const updatedMobile = { ...props.mobile, pages };
                updateMobile(updatedMobile, onDone, onError)(dispatch);
            }
            else {
                onError();
            }
        };

        reader.readAsDataURL(file);
    };

    const hideProfileOnboarding = useCallback(() => {
        dispatch(showProfileOnboarding(false));
    }, [dispatch]);

    const chooseProfilePicture = () => {
        showFileUpload(true);
    };

    const downloadQr = () => {
        if (mobile) {
            showQrDialog(true);
        }
        else {
            const canvas = document.getElementById("downloadable-qr-code");
            if (!canvas) {
                return;
            }
            const pngUrl = (canvas as any).toDataURL("image/png");
            let downloadLink = document.createElement("a");
            downloadLink.target = "_blank";
            downloadLink.href = pngUrl;
            downloadLink.download = `${token?.username ?? "qr_code"}_qr.png`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
    };

    const renderActions = (): JSX.Element => {
        return (
            <div style={{ display: 'flex' }}>
                <Tooltip tooltip={t(LocalizationKeys.Edit)} position="top"><Button icon={true} onClick={() => history.push('/profile/edit')}>edit</Button></Tooltip>
                <Tooltip tooltip={t(LocalizationKeys.Profile)} position="top"><Button icon={true} onClick={() => window.open(props.link)}>person</Button></Tooltip>
                <Tooltip tooltip={t(LocalizationKeys.Download)} position="top"><Button icon={true} onClick={downloadQr}>qr_code</Button></Tooltip>
                <Tooltip tooltip={t(LocalizationKeys.Share)} position="top"><Button icon={true} onClick={() => share(props.link ?? "")}>share</Button></Tooltip>
                <Tooltip tooltip={t(LocalizationKeys.Invite)} position="top"><Button icon={true} onClick={() => setDialog(true)}>send</Button></Tooltip>
            </div>
        );
    };

    const renderProfile = (): JSX.Element | null => {
        if (!props.mobile) {
            return null;
        }
        const page = props.mobile.pages[0];
        return (
            <div className="fizlink-mobile-profile">
                <MobileProfileComponent
                    info={{ fullname: user?.contact?.fullname ?? token?.fullname ?? "", username: token?.username ?? "", title: page.title, bio: page.bio, image: page.picture, verified: user?.verified }}
                    onImageClick={chooseProfilePicture}
                />
            </div>
        );
    };

    const shareMessage = "Check out my FizLink!";

    return (
        <Widget title={t(LocalizationKeys.Profile)} showActions={true} actions={renderActions()}>
            {renderProfile()}
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '16px' }}>
                <div style={{ margin: '0 16px' }}>
                    <Tooltip tooltip={t(LocalizationKeys.Views)} position="bottom">
                        <div className="selector-clicks" style={{ background: '#0EBFE9', padding: '4px 16px', borderRadius: '8px' }}>
                            <FontIcon style={{ color: '#ffffff' }}>visibility</FontIcon>
                            <span style={{ color: '#ffffff' }}>{formatNumber(props.views)}</span>
                        </div>
                    </Tooltip>
                </div>
                <div style={{ margin: '0 16px' }}>
                    <Tooltip tooltip={t(LocalizationKeys.Clicks)} position="bottom">
                        <div className="selector-clicks" style={{ background: '#f9690e', padding: '4px 16px', borderRadius: '8px' }}>
                            <FontIcon style={{ color: '#ffffff' }}>touch_app</FontIcon>
                            <span style={{ color: '#ffffff' }}>{formatNumber(props.clicks)}</span>
                        </div>
                    </Tooltip>
                </div>
                <div style={{ margin: '0 16px' }}>
                    <Tooltip tooltip={t(LocalizationKeys.Followers)} position="bottom">
                        <div className="selector-clicks" style={{ background: '#00b16a', padding: '4px 16px', borderRadius: '8px' }}>
                            <FontIcon style={{ color: '#ffffff' }}>group</FontIcon>
                            <span style={{ color: '#ffffff' }}>{formatNumber(props.followers)}</span>
                        </div>
                    </Tooltip>
                </div>
            </div>
            <div style={{ position: 'absolute', visibility: 'hidden' }}>
                <QRCode id="downloadable-qr-code" className="qr-code" size={500} value={window.location.host + (props.link ?? "")} renderAs='canvas' includeMargin={true} />
            </div>
            {renderSnackbar()}
            <InviteDialog visible={dialog} onHide={() => setDialog(false)} onInvite={sendInvitation} />
            {shareDialog ? <ShareDialog visible={shareDialog} link={window.location.host + (props.link ?? "")} message={shareMessage} onHide={() => setShareDialog(false)} /> : null}
            {qrDialog ? <QrDialog visible={qrDialog} link={window.location.host + (props.link ?? "")} onHide={() => showQrDialog(false)} /> : null}
            {fileUpload ? <FileUploadDialog visible={fileUpload} onHide={() => showFileUpload(false)} onFileUpload={handleFileUpload} /> : null}
            {<ProfileOnboarding visible={!onboarding && profileOnboarding} profile={props.mobile?.pages[0].picture ?? ""} onFileUpload={handleFileUpload} onHide={hideProfileOnboarding} />}
        </Widget>
    );
};
