import React, { CSSProperties } from 'react';

interface PageWrapperProps {
    children?: React.ReactNode;
    style?: CSSProperties;
}

export const PageWrapper = (props: PageWrapperProps) => {
    return (
        <div className="page-wrapper" style={props.style}>
           {props.children} 
        </div>
    );
};
