import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Mobile } from '../models/mobile';
import { MobileService } from '../services/mobile.service';
import { extractData } from './authentication.state';

export interface MobileState {
    mobiles: Mobile[];
    selectedMobileId?: string;
}

const initialState: MobileState = {
    mobiles: [],
    selectedMobileId: undefined,
};

const loadMobilesReducer = (state: MobileState, action: PayloadAction<Mobile[]>) => {
    state.mobiles = action.payload;
};

const addMobileReducer = (state: MobileState, action: PayloadAction<Mobile>) => {
    state.mobiles.push(action.payload);
};

const updateMobileReducer = (state: MobileState, action: PayloadAction<Mobile>) => {
    const mobile = action.payload;
    const sIdx = state.mobiles.findIndex((s) => s._id === mobile._id);
    if (sIdx > -1) {
        state.mobiles[sIdx] = mobile;
    }
    else {
        state.mobiles.push(mobile);
    }
};

const setSelectedMobileReducer = (state: MobileState, action: PayloadAction<string | undefined>) => {
    state.selectedMobileId = action.payload;
};

const { reducer, actions } = createSlice({
    name: 'mobile',
    initialState,
    reducers: {
        loadMobiles: loadMobilesReducer,
        addMobile: addMobileReducer,
        updateMobile: updateMobileReducer,
        setSelectedMobile: setSelectedMobileReducer,
    },
});

export { reducer as MobileReducer };
export const { setSelectedMobile } = actions;

export const loadMobiles = () => {
    return (async (dispatch: any) => {
        const service = new MobileService();
        const res = await service.getMobiles();
        const mobiles = extractData(res, dispatch) || [];
        dispatch(actions.loadMobiles(mobiles));
    });
};

export const getMobile = (id: string) => {
    return (async (dispatch: any) => {
        const service = new MobileService();
        const res = await service.getMobile(id);
        const mobile = extractData(res, dispatch);
        if (mobile) {
            dispatch(actions.addMobile(mobile));
        }
    });
};

export const updateMobile = (mobile: Mobile, onSuccess?: () => void, onError?: () => void) => {
    return (async (dispatch: any) => {
        const service = new MobileService();
        const res = await service.updateMobile(mobile);
        const updatedMobile = extractData(res, dispatch);
        if (updatedMobile) {
            dispatch(actions.updateMobile(updatedMobile));
            if (onSuccess) {
                onSuccess();
            }
        }
        else {
            if (onError) {
                onError();
            }
        }
    });
};
