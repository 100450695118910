import React, { useState, useEffect } from 'react';
import { MobileProfileComponent } from '../mobile-selectors/mobile-identity.component';

export const FollowContactComponent = () => {
    const [following, setFollowing] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setFollowing(!following);
        }, 3000);
    }, [following]);

    const renderFollow = (): JSX.Element => {
        let followText = 'Follow';
        let color = 'white';
        let backgroundColor = '#0095f6';
        let border = '';
        if (following) {
            followText = 'Following';
            backgroundColor = '#00d478';
        }
        return (
            <div className="follow" style={{ cursor: 'default', backgroundColor, color, border }}>{followText}</div>
        );
    };

    return (
        <div style={{ backgroundColor: 'white', borderRadius: '8px 8px 0 0' }}>
            <MobileProfileComponent
                info={{ fullname: "Jon Doe", username: "jondoe", title: "Entrepeneur" }}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {renderFollow()}
                </div>
            </MobileProfileComponent>
        </div>
    );
};
