import React from 'react';
import { FizlinkQrComponent } from './fizlink-qr.component';

export const QrContactCard = () => {
    return (
        <div className="qr-contact-card">
            <div className="phone" />
            <FizlinkQrComponent />
        </div>
    );
};
