import { SecuredService } from "./secured.service";
import { ApiResult } from './api-result';
import { CollectionCount } from '../models/collection-count';

export class MobileCollectionService extends SecuredService {

    private baseUrl = 'api/mobilecollections';

    public getMobileCollectionsCount(tagId: string): Promise<ApiResult<CollectionCount[] | undefined>> {
        return this.get(`${this.baseUrl}/count/${tagId}`);
    }
}