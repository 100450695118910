import React, { useState, useEffect, useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { ApiService, VotieUrl, ConnectionMobile } from '../../services/api.service';
import { HorizontalLoader } from '../horizontal-loader';
import { PortraitMobile } from './portrait-mobile';
import { useQr } from '../../hooks/qr.hooks';
import { QrProfile } from '../overview/qr-profile.component';
import { PageNotFound } from '../page-not-found.component';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../locales/keys';

import './mobile.component.scss';

export const MobileComponent = () => {
    const { t } = useTranslation();
    const match = useRouteMatch();
    const [mobileCollection, setMobileCollection] = useState<ConnectionMobile>();
    const [lat] = useState<number>();
    const [lon] = useState<number>();
    const username = (match.params as any).username;
    const [error, setError] = useState(false);
    const showQr = useQr();

    const parseCookies = useCallback((cookie: string): any => {
        var list: any = {};
        var rc = cookie;
        if (rc) {
            rc.split(';').forEach((cookie) => {
                var parts = cookie.split('=');
                const v = parts.shift() ?? "";
                list[v.trim()] = decodeURI(parts.join('='));
            });
        }
        return list;
    }, []);

    const getCookie = useCallback((cname: string): string => {
        var cookies = parseCookies(document.cookie);

        if (cookies && cookies[cname]) {
            return cookies[cname];
        }
        return '';
    }, [parseCookies]);

    const [cookieConsent, setCookieConsent] = useState(!!getCookie('CookieConsent').length);
    const [mobileUserId, setMobileUserId] = useState('');

    const setCookie = useCallback((c_name: string, value: string, exyears: number) => {
        var exdate = new Date();
        exdate.setDate(exdate.getDate() + (exyears * 365));
        var c_value = escape(value) + ((exyears == null) ? "" : "; expires=" + exdate.toUTCString());
        document.cookie = c_name + "=" + c_value + "; path=/";
    }, []);

    const putMobileConnection = useCallback((tagId: string, lang: string) => {
        let mobileuser_id = 'NA';
        var cookieName = 'fizlink_mobileuser_id';
        const _mobileuser_id = getCookie(cookieName);
        if (_mobileuser_id && _mobileuser_id !== '') {
            mobileuser_id = _mobileuser_id;
        }

        const apiService = new ApiService();
        apiService.getTag(tagId, { mobileuser_id: mobileuser_id, lang: lang, latitude: lat, longitude: lon })
            .then((res) => {
                const result = res.data;
                const mobileconnection_id = result._id;
                mobileuser_id = result.mobileuser;
                setMobileUserId(mobileuser_id);
                if (mobileuser_id && cookieConsent) {
                    setCookie("fizlink_mobileuser_id", mobileuser_id, 10);
                }
                apiService.getConnectionMobile(mobileconnection_id)
                    .then((res) => {
                        const data = res.data;
                        if (data) {
                            document.title = data.tag?.name ? `@${data.tag.name} | FizLink` : "FizLink";
                            setMobileCollection(data);
                        }
                        else {
                            setError(true);
                        }
                    })
                    .catch(() => {
                        setError(true);
                    });
            })
            .catch(() => {
                setError(true);
            });
    }, [cookieConsent, getCookie, setCookie, lat, lon]);

    // const geoLocationSuccess = useCallback((tagId: string, lang: string, position: any) => {
    //     setLat(position.coords.latitude);
    //     setLon(position.coords.longitude);
    //     putMobileConnection(tagId, lang);
    // }, [setLat, setLon, putMobileConnection]);

    // const trackLocation = useCallback((tagId: string, lang: string) => {
    //     const service = new ExternalService();
    //     service.geolocate()
    //         .then((success: any) => {
    //             geoLocationSuccess(tagId, lang, { coords: { latitude: success.location.lat, longitude: success.location.lng } });
    //         })
    //         .catch((err) => {
    //             putMobileConnection(tagId, lang);
    //         });
    // }, [geoLocationSuccess, putMobileConnection]);

    const languageSetup = useCallback((votieurl: VotieUrl) => {
        let nlang = votieurl?.defaultlang;
        const windowNavigator = navigator as any;
        if (windowNavigator.language) {
            if (windowNavigator.language.includes('en') || windowNavigator.language.includes('fr')) {
                nlang = windowNavigator.language;
            }
        } else if (windowNavigator.userLanguage) {
            if (windowNavigator.userLanguage.includes('en') || windowNavigator.userLanguage.includes('fr')) {
                nlang = windowNavigator.userLanguage;
            }
        }
        if (nlang.includes('en')) {
            votieurl.defaultlang = 'en';
        } else if (nlang.includes('fr')) {
            votieurl.defaultlang = 'fr';
        }
        putMobileConnection(votieurl?.tag?._id, votieurl?.defaultlang);
    }, [putMobileConnection]);

    const updateCookie = useCallback(() => {
        if (!!mobileUserId.length) {
            setMobileUserId(mobileUserId);
            setCookieConsent(true);
            setCookie("fizlink_mobileuser_id", mobileUserId, 10);
        }
    }, [mobileUserId, setCookieConsent, setCookie]);

    useEffect(() => {
        const service = new ApiService();
        service.getVotieUrl(username)
            .then((res) => {
                const data = res.data;
                if (data) {
                    languageSetup(data);
                }
                else {
                    setError(true);
                }
            })
            .catch(() => {
                setError(true);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [username]);

    if (error) {
        return <PageNotFound />;
    }

    const minHeight = window.innerHeight;

    if (!mobileCollection) {
        const loaderStyle = {
            width: '100%',
            height: minHeight,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        };
        return <div style={loaderStyle}><HorizontalLoader /></div>;
    }

    const tag = mobileCollection.tag;
    return showQr ? <QrProfile fullname={tag.description} username={tag.name} votieurl={tag.name} picture={mobileCollection.fizlink.picture} title={mobileCollection.fizlink.title} /> : (
        <div className="mobile-collection" style={{ minHeight }}>
            <PortraitMobile mobileCollection={mobileCollection} />
            <CookieConsent location="bottom" buttonText={t(LocalizationKeys.Ok)} onAccept={updateCookie}>{t(LocalizationKeys.CookieConsent)}</CookieConsent>
        </div>
    );
};
