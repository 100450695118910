import React, { useCallback, useEffect, useState } from 'react';
import { FontIcon } from 'react-md';
import { Pagelet } from './pagelet';
import { PageletDivider } from './pagelet-divider';
import { PageletItem } from './pagelet-item';
import { PageletProp } from './pagelet.types';
import { PageletProfileItem } from './pagelet-profile-item';

import './pagelet-button.scss';

interface PageletButtonProps {
    items: PageletProp[];
    icon?: string;
    children?: React.ReactNode;
}

export const PageletButton = ({ icon, items, children }: PageletButtonProps) => {
    const [pagelet, showPagelet] = useState(false);
    const [iconClass, setIconClass] = useState(!pagelet ? "pagelet-button" : "pagelet-button active");

    useEffect(() => {
        setIconClass(!pagelet ? "pagelet-button" : "pagelet-button active");
    }, [pagelet]);
    
    const togglePagelet = useCallback(() => {
        showPagelet(!pagelet);
    }, [pagelet]);

    const createPageletItem = useCallback((item: PageletProp): React.ReactNode => {
        if (item === 'divider') {
            return <PageletDivider />;
        }
        else if (item === 'profile') {
            return <PageletProfileItem />;
        }
        else {
            return <PageletItem {...item} />;
        }
    }, []);

    return (
        <React.Fragment>
            {icon ? <FontIcon className={iconClass} onClick={togglePagelet}>{icon}</FontIcon> : null}
            {children ? <div className={iconClass} onClick={togglePagelet}>{children}</div> : null}
            {pagelet && items?.length
                ? (
                    <Pagelet onHide={togglePagelet}>
                        {items.map(createPageletItem)}
                    </Pagelet>
                )
                : null
            }
        </React.Fragment>
    );
};
