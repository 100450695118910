import React, { useEffect, useState } from 'react';
import { Selector } from '../../../models/selector';
import { MediaService } from '../../../services/media.service';
import { Dialog, DialogContent } from '@react-md/dialog';
import { Chip, TextField } from 'react-md';
import { Button } from '@react-md/button';
import { useTextField } from '../../authentication/signup.component';
import { MobileSelector } from '../../../models/mobile-selector';
import { FileUploadDialog } from '../file-upload.dialog';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../../locales/keys';

interface UpdateSelectorDialogProps {
    selector: Selector;
    mobileSelector: MobileSelector;
    visible: boolean;
    onHide: () => void | undefined;
    onUpdate?: (selectorId: string, label: string, link: string) => void;
    onUpdateMedia?: (selectorId: string, selectormedia: any, mediacontent: string) => void;
    onUpdateFile?: (selectorId: string, file: any, type: string) => void;
}

export const UpdateSelectorDialog = (props: UpdateSelectorDialogProps) => {
    const { t } = useTranslation();
    const [imageUpload, showImageUpload] = useState(false);
    const [fileUpload, showFileUpload] = useState(false);
    const { value: label, bind: bindLabel } = useTextField(props.selector.caption?.en ?? "");
    const { value: link, bind: bindLink } = useTextField(props.mobileSelector.value);

    useEffect(() => {
        bindLink.onChange(props.mobileSelector.value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.mobileSelector.value]);

    useEffect(() => {
        bindLabel.onChange(props.selector.caption?.en ?? "");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selector.caption?.en]);

    const handleUpdate = () => {
        if (props.onUpdate) {
            props.onUpdate(props.selector._id, label, link);
        }
        props.onHide();
    };

    const handleFileUpload = (file: Blob, onDone: () => void, onError: () => void) => {
        const reader = new FileReader();

        reader.onload = (ev: ProgressEvent<FileReader>) => {
            if (ev?.target && props.selector && props.onUpdateFile) {
                props.onUpdateFile(props.selector._id, ev.target.result, file.type);
                onDone();
            }
            else {
                onError();
            }
        };

        reader.readAsDataURL(file);
    };

    const handlePictureUpload = (file: Blob, onDone: () => void, onError: () => void) => {
        const reader = new FileReader();

        reader.onload = (ev: ProgressEvent<FileReader>) => {
            if (ev?.target && props.selector && props.onUpdateMedia) {
                props.onUpdateMedia(props.selector._id, ev.target.result, file.type);
                onDone();
            }
            else {
                onError();
            }
        };

        reader.readAsDataURL(file);
    };

    let tabIndex = 0;
    const renderIcon = (): JSX.Element => {
        return (
            <div className="selector-icon" style={{ padding: '8px', display: 'flex', cursor: 'pointer', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} onClick={() => showImageUpload(true)}>
                <img height={72} width={72} alt="Link" className="selector-profile" src={MediaService.getMediaUrl(props.selector.media)} style={{ objectFit: 'cover', cursor: 'pointer', borderRadius: '50%' }} />
                <div style={{ marginTop: '8px', color: '#03a9f4', fontWeight: 'bold' }} onClick={() => showImageUpload(true)}>{t(LocalizationKeys.ChangePhoto)}</div>
            </div>
        );
    };

    const renderUpdateValueField = (): JSX.Element => {
        if (props.selector.allowmedia === 'yes') {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {!props.mobileSelector.value ? null : <Chip label={props.selector.caption?.en} style={{ margin: '4px' }} />}
                    <Button onClick={() => showFileUpload(true)} style={{ margin: '4px' }}>{t(LocalizationKeys.UploadFile)}</Button>
                </div>
            );
        }
        else {
            return (
                <label className="redirecturl-input" data-domain={selectorRedirectUrl}>
                    <TextField
                        fullWidth={true}
                        id="link"
                        placeholder={!selectorRedirectUrl ? "http://" : undefined}
                        type="text"
                        {...bindLink}
                        tabIndex={tabIndex++}
                    />
                </label>
            );
        }
    };

    // eslint-disable-next-line no-template-curly-in-string
    const selectorRedirectUrl = props.mobileSelector.redirecturl?.replace('${value}', '');
    return (
        <Dialog id="update-selector-dialog" visible={props.visible} onRequestClose={props.onHide} aria-labelledby="update-selector-dialog-title" style={{ borderRadius: '16px' }}>
            <DialogContent>
                <div tabIndex={tabIndex++} />
                {renderIcon()}
                <TextField fullWidth={true} id="label" type="text" {...bindLabel} tabIndex={tabIndex++} />
                {renderUpdateValueField()}
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '8px' }}>
                    <Button
                        id="update-selector-dialog-cancel"
                        onClick={props.onHide}
                        type="button"
                    >
                        {t(LocalizationKeys.Cancel)}
                    </Button>
                    <Button
                        id="update-selector-dialog-save"
                        onClick={handleUpdate}
                        type="button"
                        style={{ color: '#03a9f4' }}
                    >
                        {t(LocalizationKeys.Save)}
                    </Button>
                </div>
            </DialogContent>
            {imageUpload ? <FileUploadDialog visible={imageUpload} onHide={() => showImageUpload(false)} onFileUpload={handlePictureUpload} /> : null}
            {fileUpload ? <FileUploadDialog visible={fileUpload} onHide={() => showFileUpload(false)} onFileUpload={handleFileUpload} acceptedFileTypes={["image/*", "application/pdf"]} /> : null}
        </Dialog>
    );
};
