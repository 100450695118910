import React, { useState } from 'react';
import { ConnectionMobile, ApiService, MobileSelectorResult } from '../../services/api.service';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { useHistory } from 'react-router-dom';
import { FollowingService } from '../../services/following.service';
import { MobileSelectorComponent } from '../mobile-selectors/mobile-selector.component';
import { MobileToolbar } from './mobile-toolbar.component';
import { FizLink } from '../svg/fizlink-logo';
import { UnfollowDialog } from './unfollow.dialog.component';
import { Button } from 'react-md';
import { ShareDialog } from '../share.dialog.component';
import { UserService } from '../../services/user.service';
import { FollowDialogComponent } from './follow.dialog.component';
import { MobileProfileComponent } from '../mobile-selectors/mobile-identity.component';
import { MediaService } from '../../services/media.service';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../locales/keys';

interface PortraitMobileProps {
    mobileCollection: ConnectionMobile;
}

export const PortraitMobile = (props: PortraitMobileProps) => {
    const { t } = useTranslation();
    const token = useSelector((state: RootState) => state.authentication.token)
    const mobileCollection = props.mobileCollection;
    const tag = mobileCollection.tag;
    const history = useHistory();
    const [following, setFollowing] = useState<'req' | 'act' | 'blk' | 'dcl' | undefined>(props.mobileCollection.following);
    const [showUnfollowDialog, toggleUnfollowDialog] = useState(false);
    const [showFollowDialog, toggleFollowDialog] = useState(false);
    const [shareDialog, setShareDialog] = useState(false);

    const getShareMessageName = (): string => {
        const tagDescription = mobileCollection.tag.description;
        return tagDescription.toLowerCase().charAt(tagDescription.length - 1) === 's' ? `${tagDescription}'` : `${tagDescription}'s`;
    };

    const shareMessage = token && token.user_id === mobileCollection.fizlink.id ? "Check out my FizLink!" : `Check out ${getShareMessageName()} FizLink!`;

    // const share = () => {
    //     const nav = (navigator as any);
    //     if (nav.share) {
    //         nav.share({
    //             text: shareMessage,
    //             title: 'FizLink',
    //             url: window.location.href,
    //         });
    //     }
    //     else {
    //         setShareDialog(true);
    //     }
    // };

    const follow = () => {
        if (token) {
            if (following) {
                toggleUnfollowDialog(true);
            }
            else {
                const service = new FollowingService();
                service.postFollow(mobileCollection.fizlink.id)
                    .then((res) => {
                        if (res?.data) {
                            setFollowing(res.data.status?.code);
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }
        }
        else {
            toggleFollowDialog(true);
        }
    };

    const goToNextFollow = (url: string) => {
        history.push(`${url}?next=${mobileCollection.fizlink.id}&source=follow`);
    };

    const goToLogin = () => {
        goToNextFollow("/login");
    };

    const goToSignup = () => {
        goToNextFollow("/signup");
    };

    const mobileclick = (selector_id: string) => {
        const service = new ApiService();
        service.onRedirect(mobileCollection.mobileconnection_id, selector_id);
    };

    const handleUnfollow = () => {
        if (token) {
            const service = new FollowingService();
            service.unfollowFollowing(mobileCollection.fizlink.id)
                .then(() => {
                    setFollowing(undefined);
                    window.location.reload(false);
                })
                .catch((err) => {
                    console.error(err);
                });
        }
        else {
            history.push('/login' + mobileCollection?.fizlink?.id);
        }
        toggleUnfollowDialog(false)
    }

    const renderLinks = (selector: MobileSelectorResult): JSX.Element => {
        const redirecturl = selector.allowmedia === 'yes' && selector.redirecturl ? MediaService.getMediaUrl(selector.redirecturl) : selector.redirecturl;
        return (
            <MobileSelectorComponent
                selectorId={selector.selector._id}
                redirecturl={redirecturl}
                media={selector.selector.media}
                caption={selector.selector.caption?.en}
                highlight={selector.highlight}
                listview={selector.listview}
                onClick={mobileclick}
            />
        );
    };

    const renderSelectorLinks = (): JSX.Element => {
        return (
            <div className="links">
                {/* <div style={{ borderRadius: '16px', margin: '8px', backgroundColor: 'white', width: '100%', padding: '16px', border: '1px solid #e0e0e0' }}>
                    <a href=""><img width="100%" src="" /></a>
                </div> */}
                {mobileCollection?.selectors?.map(renderLinks)}
            </div>
        );
    };

    const renderAlreadyFollow = () => {
        return (
            <span>
                {t(LocalizationKeys.AlreadyFollowUser, { user: tag?.name })}<br />
                <a href="/login">{t(LocalizationKeys.Login)}</a><br />
                {t(LocalizationKeys.ToSeeContactInfo)}<br />
            </span>
        );
    };

    const renderFollowPrivate = () => {
        return (
            <span>
                {t(LocalizationKeys.FollowToSeeContact)}<br />
            </span>
        )
    };

    const renderPrivate = (): JSX.Element => {
        return (
            <div style={{ textAlign: 'center', margin: '16px' }}>
                <b>{t(LocalizationKeys.PrivateLinks)}</b><br />
                {token ? renderFollowPrivate() : renderAlreadyFollow()}
            </div>
        );
    };

    const renderFooter = (): JSX.Element => {
        return (
            <footer>
                <a href="/home">
                    <FizLink height={28} />
                </a>
                <span className="create-your-own">
                    <a href="signup">{t(LocalizationKeys.CreateYourOwn)}</a>{t(LocalizationKeys.ItsEasy)}
                </span>
            </footer>
        );
    };

    const renderEditProfile = (): JSX.Element => {
        return (
            <a className="follow" style={{ textDecoration: 'none', color: '#101010', backgroundColor: 'white', border: '1px solid #e0e0e0' }} href='/'>{t(LocalizationKeys.EditProfile)}</a>
        );
    };

    const renderFollow = (): JSX.Element => {
        let followText = t(LocalizationKeys.Follow);
        let color = 'white';
        let backgroundColor = '#0095f6';
        let border = '';
        if (following === 'act') {
            followText = t(LocalizationKeys.Following);
            backgroundColor = '#00d478';
        }
        else if (following === 'req' || following === 'dcl') {
            followText = t(LocalizationKeys.Requested);
            color = '#101010';
            backgroundColor = 'white';
            border = '1px solid #e0e0e0';
        }
        return (
            <div className="follow" style={{ backgroundColor, color, border }} onClick={follow}>{followText}</div>
        );
    };

    const renderContent = (): JSX.Element => {
        if (mobileCollection?.selectors?.length) {
            return renderSelectorLinks();
        }
        else if (!mobileCollection?.hasSelectors) {
            return (
                <div style={{ textAlign: 'center', margin: '16px' }}>
                    <b>{t(LocalizationKeys.NoContent, { user: tag?.name })}</b><br />
                </div>
            );
        }
        else {
            return renderPrivate();
        }
    };

    const profileStyle = {
        paddingBottom: token ? undefined : '70px',
        paddingTop: token ? '64px' : undefined,
    };

    return (
        <React.Fragment>
            {token ? <MobileToolbar /> : null}
            <div className="mobile-profile-container" style={profileStyle}>
                <MobileProfileComponent
                    info={{ fullname: tag?.description ?? "", username: tag?.name ?? "", title: mobileCollection.fizlink.title, bio: mobileCollection.fizlink.bio, image: mobileCollection?.fizlink?.picture, verified: tag.verified }}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {token?.user_id !== mobileCollection.fizlink.id ? renderFollow() : renderEditProfile()}
                        <a href={`/${UserService.baseUrl}/${mobileCollection.fizlink.id}/vcf`} download={true} style={{ marginLeft: '8px' }}>
                            <Button icon={true} svg={true}>
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="address-card" className="svg-inline--fa fa-address-card fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                    <path fill="currentColor" d="M528 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-352 96c35.3 0 64 28.7 64 64s-28.7 64-64 64-64-28.7-64-64 28.7-64 64-64zm112 236.8c0 10.6-10 19.2-22.4 19.2H86.4C74 384 64 375.4 64 364.8v-19.2c0-31.8 30.1-57.6 67.2-57.6h5c12.3 5.1 25.7 8 39.8 8s27.6-2.9 39.8-8h5c37.1 0 67.2 25.8 67.2 57.6v19.2zM512 312c0 4.4-3.6 8-8 8H360c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-64c0 4.4-3.6 8-8 8H360c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-64c0 4.4-3.6 8-8 8H360c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16z"></path>
                                </svg>
                            </Button>
                        </a>
                        {/* <Button icon={true} onClick={share}>share</Button> */}
                    </div>
                </MobileProfileComponent>
                {renderContent()}
            </div>
            {token ? null : renderFooter()}
            {showFollowDialog ? <FollowDialogComponent visible={showFollowDialog} media={mobileCollection.fizlink.picture} onLogin={goToLogin} onSignUp={goToSignup} onHide={() => toggleFollowDialog(false)} /> : null}
            {showUnfollowDialog ? <UnfollowDialog visible={showUnfollowDialog} fullname={tag?.description ?? ""} username={tag?.name ?? ""} onHide={() => toggleUnfollowDialog(false)} onUnfollow={handleUnfollow} /> : null}
            {shareDialog ? <ShareDialog visible={shareDialog} link={window.location.href} message={shareMessage} onHide={() => setShareDialog(false)} /> : null}
        </React.Fragment>
    );
};
