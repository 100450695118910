import React from 'react';

interface FollowerRequestProps {
    count: number;
    onClick?: () => void;
}

export const FollowerRequestComponent = (props: FollowerRequestProps) => {

    const enhanceCount = (count: number): string => {
        return count > 99 ? '99+' : count.toString();
    };

    return (
        <div style={{ cursor: 'pointer', width: '100%', display: 'flex', alignItems: 'center', padding: '16px' }} onClick={props.onClick}>
            <div style={{ fontSize: '12px', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '16px', padding: '4px', backgroundColor: '#F4AC3D', borderRadius: '50%', height: '24px', width: '24px', color: 'white' }}>
                {enhanceCount(props.count)}
            </div>
            <div>
                <span><b>Follow Requests</b><br /></span>
                <span>Approve or decline requests</span>
            </div>
        </div>
    );
};
