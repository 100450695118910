import { ApiResult } from './api-result';
import { LocalizedString } from '../models/localized-string';
import { SecuredService } from './secured.service';

export class ApiService extends SecuredService {

    public getVotieUrl(username: string): Promise<ApiResult<VotieUrl>> {
        return this.get('/mobile/tag/username/' + username);
    }

    public getTag(tagId: string, data: any): Promise<ApiResult<any>> {
        return this.put('/mobile/tag/' + tagId, data);
    }

    public getConnectionMobile(mobileconnection_id: string): Promise<ApiResult<ConnectionMobile>> {
        return this.get('/mobile/tag/' + mobileconnection_id);
    }

    public onRedirect(mobileconnection_id: string, selector: string): any {
        return this.put('/mobile/onelink/' + mobileconnection_id, { selector });
    }
}

export interface VotieUrl {
    votieurl: string;
    tag: { _id: string, name: string, description: string };
    defaultlang: string;
}

export interface ConnectionMobile {
    mobileconnection_id: string;
    tag: { _id: string, name: string, description: string, verified: boolean };
    selectors: MobileSelectorResult[];
    hasSelectors: boolean;
    fizlink: { id: string, name: string, picture: string, title?: string, bio?: string };
    following: 'req' | 'act' | 'blk' | 'dcl' | undefined;
    favorite: boolean;
    clienturl: string;
}

export interface MobileSelectorResult {
    redirecturl: string;
    selector: {
        _id: string;
        caption: LocalizedString;
        media: string;
        name: string;
    };
    allowmedia: string;
    highlight?: boolean;
    listview?: boolean;
}