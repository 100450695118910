import React, { useState, CSSProperties } from 'react';
import { Selector } from '../../../models/selector';
import { AddSelectorDialog } from './add-selector.dialog';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../../locales/keys';

interface AddSelectorProps {
    selectors: Selector[];
    onAdd: (selectorId: string) => void;
}

export const AddSelectorComponent = (props: AddSelectorProps) => {
    const { t } = useTranslation();
    const [dialog, showDialog] = useState(false);

    const fabStyle: CSSProperties = {
        padding: '8px 32px',
        margin: '16px',
        borderRadius: '8px',
        backgroundColor: '#03a9f4',
        textAlign: 'center',
        color: 'white',
        cursor: 'pointer',
        fontWeight: 'bold'
    };

    const handleHide = (): void => {
        showDialog(false);
    };

    const handleAdd = (selectorId: string): void => {
        props.onAdd(selectorId);
        handleHide();
    };

    return (
        <div>
            <div style={fabStyle} onClick={() => showDialog(true)}>{t(LocalizationKeys.Add).toUpperCase()}</div>
            <AddSelectorDialog selectors={props.selectors} onAdd={handleAdd} onHide={handleHide} visible={dialog} />
        </div>
    );
};
