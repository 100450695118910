import React, { useEffect, useState } from 'react';
import { HorizontalLoader } from '../../horizontal-loader';
import { UserSearch } from '../../../models/user-search';
import { SearchService } from '../../../services/search.service';
import { MemberSearchItem } from './member-search-item';

interface MemberSearchProps {
    query?: string;
    page?: number;
    onClick?: (user: UserSearch) => void;
}

export const MemberSearch = ({ query, page, onClick }: MemberSearchProps) => {
    const [searching, setSearching] = useState(!!query);
    const [users, setUsers] = useState<UserSearch[]>([]);

    const performSearch = (page?: number, previousUsers?: UserSearch[]) => {
        if (query) {
            setSearching(true);
            const service = new SearchService();
            service.searchAll(query, page)
                .then((res) => {
                    if (res.success && res.data) {
                        setUsers([...previousUsers ?? [], ...res.data]);
                    }
                    setSearching(false);
                })
                .catch((res) => {
                    setSearching(false);
                    // no results?
                });
        }
        else {
            setUsers([]);
            setSearching(false);
        }
    };

    useEffect(() => {
        performSearch(1);

        return () => {
            setUsers([]);
            setSearching(false);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    useEffect(() => {
        performSearch(page, users);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    const renderUsers = (): JSX.Element => {
        return (
            <div className="member-search-results">
                {users.map((u) => <MemberSearchItem key={u._id} user={u} onClick={onClick}/>)}
                {searching ? <HorizontalLoader /> : null}
            </div>
        );
    };

    return !query ? null : renderUsers();
};
