import React from 'react';

import './button.scss';

interface ButtonProps {
    children: React.ReactNode;
    className?: string;
    disabled?: boolean;
    type?: 'submit' | 'reset' | 'button';
    color?: string;
    onClick?: () => void;
}

export const Button = (props: ButtonProps) => {
    const defaultColor = '#F4AC3D';
    const style = {
        color: '',
        '--button-color': props.color ?? defaultColor,
    };
    return (
        <button className={`ol-button ${props.className}`} style={style} type={props.type} disabled={props.disabled} onClick={props.onClick}>
            {props.children}
        </button>
    );
};
