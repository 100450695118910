import { User } from '../models/user';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminUserService } from '../services/admin-user.service';
import { extractData } from './authentication.state';
import { UserService } from '../services/user.service';

export interface UserState {
    selectedUser: User | undefined;
    users: User[];
}

const initialState: UserState = {
    selectedUser: undefined,
    users: [],
};

const setUserReducer = (state: UserState, action: PayloadAction<User | undefined>) => {
    state.selectedUser = action.payload;
};

const loadUsersReducer = (state: UserState, action: PayloadAction<User[]>) => {
    state.users = action.payload;
};

const { reducer, actions } = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: setUserReducer,
        loadUsers: loadUsersReducer,
    },
});

export { reducer as UserReducer };

export const getUser = (userId: string) => {
    return (async (dispatch: any) => {
        const service = new UserService();
        const res = await service.getUser(userId);
        const user = extractData(res, dispatch);
        if (user) {
            dispatch(actions.setUser(user));
        }
    });
}

export const updateProfile = (userId: string, firstname?: string, lastname?: string, email?: string, onSuccess?: () => void) => {
    return (async (dispatch: any) => {
        const service = new UserService();
        const res = await service.updateProfile(userId, firstname, lastname, email);
        const updatedUser = extractData(res, dispatch);
        if (updatedUser) {
            if (onSuccess) {
                onSuccess();
            }
            dispatch(actions.setUser(updatedUser));
        }
    });
}

export const updatePassword = (userId: string, password?: string, confirmpassword?: string, onSuccess?: () => void) => {
    return (async (dispatch: any) => {
        const service = new UserService();
        const res = await service.updatePassword(userId, password, confirmpassword);
        const updatedUser = extractData(res, dispatch);
        if (updatedUser) {
            if (onSuccess) {
                onSuccess();
            }
            dispatch(actions.setUser(updatedUser));
        }
    });
}

export const loadUsers = (entityId: string) => {
    return (async (dispatch: any) => {
        const service = new AdminUserService();
        const res = await service.getUsers(entityId);
        const users = extractData(res, dispatch) || [];
        dispatch(actions.loadUsers(users));
    });
}