import React, { useEffect, useState } from 'react';
import { Widget, WidgetProps } from './widget.component';
import { Button, Chip, FontIcon, MenuButton, ListItem } from 'react-md';
import { Selector } from '../../../models/selector';
import { MediaService } from '../../../services/media.service';
import { MobileSelector } from '../../../models/mobile-selector';
import { Tooltip } from '../../tooltip';
import { DeleteSelectorDialog } from '../delete-selector.dialog';
import { Draggable } from 'react-beautiful-dnd';
import { UpdateSelectorDialog } from './update-selector.dialog';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../../locales/keys';

import './selector.component.scss';

interface SelectorProps {
    index: number;
    selector: Selector;
    mobileSelector: MobileSelector;
    clicks: number;
    isMobileDevice?: boolean;
    isEditing?: boolean;
    onUpdateSelector?: (selectorId: string, name: string) => void;
    onUpdatePrivacy?: (selectorId: string, privacy: boolean) => void;
    onUpdateHighlight?: (selectorId: string, highlight: boolean) => void;
    onUpdateMedia?: (selectorId: string, selectormedia: any, mediacontent: string) => void;
    onUpdateFile?: (selectorId: string, file: any, type: string) => void;
    onLinkChange?: (selectorId: string, value: string) => void;
    onToggleLinkView?: (selectorId: string, isList: boolean) => void;
    onDelete?: (selectorId: string) => void;
}

type props = SelectorProps & WidgetProps;
export const SelectorComponent = (props: props) => {
    const { t } = useTranslation();
    const [isEditing, toggleEditing] = useState(false);
    const [isDeleting, toggleDeleting] = useState(false);

    useEffect(() => {
        toggleEditing(!!props.isEditing);
    }, [props.isEditing]);

    const triggerInputChange = (value: string) => {
        if (props.onLinkChange) {
            props.onLinkChange(props.mobileSelector.selector, value);
        }
    };

    const triggerNameChange = (value: string) => {
        if (props.onUpdateSelector) {
            props.onUpdateSelector(props.selector._id, value);
        }
    };

    const triggerPrivacyChange = () => {
        if (props.onUpdatePrivacy) {
            props.onUpdatePrivacy(props.selector._id, !props.mobileSelector.privacy);
        }
    };

    const triggerHighlightChange = () => {
        if (props.onUpdateHighlight) {
            props.onUpdateHighlight(props.selector._id, !props.mobileSelector.highlight);
        }
    };

    const toggleListView = (): void => {
        if (props.onToggleLinkView) {
            props.onToggleLinkView(props.selector._id, !props.mobileSelector.listview);
        }
    };

    const handleDelete = () => {
        if (props.onDelete) {
            props.onDelete(props.selector._id);
        }
        toggleDeleting(false);
    };

    const onStartEditing = (): void => {
        toggleEditing(true);
    };

    const handleUpdateSelector = (_: string, label: string, link: string): void => {
        toggleEditing(false);

        if (props.selector.caption?.en !== label) {
            triggerNameChange(label ?? "");
        }

        if (props.selector.allowmedia !== 'yes' && props.mobileSelector.redirecturl !== link) {
            triggerInputChange(link ?? "");
        }
    };

    const getActionItems = (isPrivate: boolean, isHighlight: boolean, listview: boolean) => {
        return [
            { label: isPrivate ? t(LocalizationKeys.Unlock) : t(LocalizationKeys.Lock), icon: isPrivate ? 'lock' : 'lock_open', color: isPrivate ? '#00b16a' : undefined, onClick: triggerPrivacyChange },
            { label: isHighlight ? t(LocalizationKeys.Default) : t(LocalizationKeys.Highlight), icon: 'priority_high', color: isHighlight ? '#FF8C00' : undefined, onClick: triggerHighlightChange },
            { label: listview ? t(LocalizationKeys.Grid) : t(LocalizationKeys.List), icon: listview ? 'apps' : 'list', onClick: toggleListView },
            { label: t(LocalizationKeys.Delete), icon: 'delete', color: '#EE3D4B', onClick: () => toggleDeleting(true) },
        ];
    };

    const renderActions = (): JSX.Element => {
        const isPrivate = props.mobileSelector.privacy;
        const isHighlight = !!props.mobileSelector.highlight;
        const listview = !!props.mobileSelector.listview
        return (
            <div style={{ display: 'flex' }}>
                <Tooltip tooltip={t(LocalizationKeys.Edit)} position="top"><Button icon={true} onClick={onStartEditing}>edit</Button></Tooltip>
                <MenuButton
                    id="selector-menu"
                    position="br"
                    icon={true}
                    listStyle={{ borderRadius: '16px' }}
                    menuItems={getActionItems(isPrivate, isHighlight, listview).map((item) => <ListItem id={item.label} key={`profile-${item.label}`} primaryText={item.label} leftIcon={<FontIcon style={{ color: item.color }}>{item.icon}</FontIcon>} onClick={item.onClick} />)}
                >
                    more_vert
                </MenuButton>
            </div>
        );
    };

    const renderIcon = (): JSX.Element => {
        return (
            <img height={36} width={36} alt="Link" className="selector-profile" src={MediaService.getMediaUrl(props.selector.media)} style={{ objectFit: 'cover', borderRadius: '50%' }} />
        );
    };

    const renderSelectorContent = (selector: Selector, mobileSelector: MobileSelector): JSX.Element | null => {
        // eslint-disable-next-line no-template-curly-in-string
        const selectorRedirectUrl = props.mobileSelector.redirecturl?.replace('${value}', '');
        if (selector.allowmedia === 'yes') {
            const url = props.mobileSelector.value ? MediaService.getMediaUrl(props.mobileSelector.value) : "";
            return !mobileSelector.value ? null : (
                <Chip label={selector.caption?.en} onClick={() => window.open(url, "_blank")} />
            );
        }
        else {
            return <a style={{ color: 'blue', overflow: 'hidden', wordBreak: 'break-word' }} href={selectorRedirectUrl + props.mobileSelector.value} target="_blank" rel="noopener noreferrer">{props.mobileSelector.value}</a>;
        }
    };

    return (
        <Draggable draggableId={props.selector._id} index={props.index}>
            {provided => (
                <Widget
                    title={props.selector.caption?.en}
                    titleColor={props.selector.color}
                    draggable={true}
                    showActions={true}
                    icon={renderIcon()}
                    actions={renderActions()}
                    draggableRef={provided.innerRef}
                    draggableProps={provided.draggableProps}
                    dragHandleProps={provided.dragHandleProps}
                >
                    {renderSelectorContent(props.selector, props.mobileSelector)}
                    <div className="selector-footer">
                        <div className="selector-clicks">
                            <FontIcon>touch_app</FontIcon>
                            <Tooltip tooltip={props.clicks}>
                                <span>{formatNumber(props.clicks)}</span>
                            </Tooltip>
                        </div>
                        {props.mobileSelector.privacy && <FontIcon style={{ color: '#00b16a' }}>lock</FontIcon>}
                        {props.mobileSelector.highlight && <FontIcon style={{ color: '#FF8C00' }}>priority_high</FontIcon>}
                    </div>
                    {isEditing ? <UpdateSelectorDialog visible={isEditing} selector={props.selector} mobileSelector={props.mobileSelector} onHide={() => toggleEditing(false)} onUpdate={handleUpdateSelector} onUpdateMedia={props.onUpdateMedia} onUpdateFile={props.onUpdateFile} /> : null}
                    <DeleteSelectorDialog selector={props.selector} visible={isDeleting} onHide={() => toggleDeleting(false)} onDelete={handleDelete} />
                </Widget>
            )}
        </Draggable>
    );
};

export const formatNumber = (value: number) => {
    if (isNaN(value)) {
        return "0";
    }
    let result = value.toFixed(0);
    if (value >= 1000000) {
        result = `${(value / 100000).toFixed(0)}M`
    }
    else if (value >= 10000) {
        result = `${(value / 1000).toFixed(0)}K`
    }
    return result;
};