import React, { useRef } from 'react';
import { useWrapperAlerter } from './wrapper.hooks';

interface WrapperProps {
    onClick: () => void;
    children?: React.ReactChild;
}

export const Wrapper = ({ children, onClick }: WrapperProps) => {
    const wrapperRef = useRef(null);
    useWrapperAlerter(wrapperRef, onClick);
    return <div ref={wrapperRef}>{children}</div>;
};
