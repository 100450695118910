import React from 'react';
import { FACEBOOK, PHONE, FIZLINK, TWITTER, INSTAGRAM, SNAPCHAT, LINKEDIN } from '../preview/base64.lib';

export const WelcomeComponent = () => {

    const circleWithCaption = (image: string) => {
        return (
            <div style={{ marginTop: '16px', width: '33%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <img height='64px'width='64px' className="mobile-selector" src={image} style={{ borderRadius: '50%', backgroundColor: image }} alt="" />
                {/* <div className="mobile-selector" style={{ height: '64px', width: '64px', borderRadius: '50%', backgroundColor: image }} /> */}
                {/* <label style={{ marginTop: '8px' }}>{caption}</label> */}
            </div>
        );
    };

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            {circleWithCaption(FIZLINK)}
            {circleWithCaption(FACEBOOK)}
            {circleWithCaption(PHONE)}
            {circleWithCaption(TWITTER)}
            {circleWithCaption(INSTAGRAM)}
            {circleWithCaption(SNAPCHAT)}
            {circleWithCaption(LINKEDIN)}
        </div>
    );
};
