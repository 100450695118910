import { SecuredService } from "./secured.service";
import { UserEntity } from "../models/user-entity";
import { ApiResult } from "./api-result";

export class EntityService extends SecuredService {

    private static baseUrl = 'api/entities';

    public getEntity(entityId: string): Promise<ApiResult<UserEntity>> {
        return this.get(`${EntityService.baseUrl}/${entityId}`);
    }

    public static getEntityLogoUrl(companyId: string): string {
        return `/${EntityService.baseUrl}/logo/${companyId}`;
    }
}