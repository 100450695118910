import { TableCell, TableRow } from '@react-md/table';
import React from 'react';
import { FontIcon } from 'react-md';
import { Link } from 'react-router-dom';
import { ViewerItemProps, useViewerItem } from './viewer-item.hooks';

import './viewer-item.scss';

export const ViewerItem = (props: ViewerItemProps) => {
    const { id, fullname, username, valid, toggleValidity } = useViewerItem(props);
    return (
        <TableRow key={id}>
            <TableCell>
                <div className="valid-button" onClick={toggleValidity}>
                    <FontIcon style={{ color: valid ? '#00d478' : '#EE3D4B' }}>{valid ? 'check_circle' : 'cancel'}</FontIcon>
                </div>
            </TableCell>
            <TableCell>{fullname} (<Link to={`/${username}`} target="_blank">@{username}</Link>)</TableCell>
        </TableRow>
    );
};
