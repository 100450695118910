import React, { useCallback, useState } from 'react';
import { FizLink } from '../svg/fizlink-logo';
import { Button } from '../button';
import { useHistory } from 'react-router-dom';
import { TitlePage } from './title-page.component';
import { PageTwo } from './page-two.component';
import { PageThree } from './page-three.component';
import { PageFour } from './page-four.component';
import { PageFive } from './page-five.component';
import { FizlinkIcon } from '../svg/fizlink-icon';
import { ListItem, MenuButton } from 'react-md';
import { locale } from '../../locales/locale';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../locales/keys';
import { Support } from '../support/support';

import './home.component.scss';

export const Home = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();

    const pages = [<TitlePage />, <PageTwo />, <PageThree />, <PageFour />, <PageFive />];
    const [languages] = useState(Object.keys(i18n.services.resourceStore.data).sort());

    const onLanguageSelected = useCallback((event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        const id = event.currentTarget.id;
        i18n.changeLanguage(id);
    }, [i18n]);

    return (
        <React.Fragment>
            <div className="home">
                <div className="home-header">
                    <FizLink height={32} />
                    <div className="signup-login">
                        <div className="toolbar-action">
                            <MenuButton
                                id="profile-language"
                                position="br"
                                style={{ borderRadius: '16px' }}
                                listStyle={{ borderRadius: '16px' }}
                                menuItems={languages.map((item) => <ListItem id={item} key={`profile-${item}`} primaryText={locale[item] ?? item} onClick={onLanguageSelected} />)}
                            >
                                <div className="profile-action">
                                    <label id="language-label">{locale[i18n.language] ?? i18n.language}</label>
                                </div>
                            </MenuButton>
                        </div>
                        <a className="login-button" href='/login'>{t(LocalizationKeys.Login)}</a>
                        <Button onClick={() => history.push('/signup')}>{t(LocalizationKeys.Signup)}</Button>
                    </div>
                </div>
                <div className="home-content">
                    {pages.map((p) => p)}
                    <div className="home-footer">
                        <FizLink height={24} />
                        <a href="/fizlink">
                            <FizlinkIcon height={24} color="#e0e0e0" />
                        </a>
                    </div>
                </div>
            </div>
            <Support />
        </React.Fragment>
    );
};
