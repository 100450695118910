import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Member } from "../../../models/member";
import { RootState } from '../../../redux/reducers';
import { updateMemberSettings } from '../../../redux/member.state';

export interface MemberTableProps {
    members: Member[];
}

export const useMemberTable = ({ members }: MemberTableProps) => {
    const [dialog, showDialog] = useState(false);
    const settings = useSelector((state: RootState) => state.member.settings);
    const fields = settings?.fields ?? [];

    const dispatch = useDispatch();

    const toggleDialog = useCallback(() => {
        showDialog(!dialog);
    }, [dialog]);

    const handleSave = useCallback((fieldName: string) => {
        if (!settings) {
            return;
        }
        const updatedFields = [...settings.fields, { name: fieldName }];
        updateMemberSettings({ ...settings, fields: updatedFields })(dispatch);
        showDialog(false);
    }, [dispatch, settings]);

    return {
        members, fields, dialog, toggleDialog, handleSave
    };
};