import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Member } from '../models/member';
import { MemberService } from '../services/member.service';
import { ViewerService } from '../services/viewer.service';
import { MemberSetting } from '../models/member-setting';
import { MemberSettingsService, MemberSettingRequest } from '../services/member-settings.service';

export interface MemberState {
    members: Member[];
    viewers: Member[];
    settings?: MemberSetting;
}

const initialState: MemberState = {
    members: [],
    viewers: []
};

const loadMembersReducer = (state: MemberState, action: PayloadAction<{ members: Member[]; viewers: Member[], settings: MemberSetting }>) => {
    state.members = action.payload.members;
    state.viewers = action.payload.viewers;
    state.settings = action.payload.settings;
}

const updateMemberReducer = (state: MemberState, action: PayloadAction<Member>) => {
    const member = action.payload;
    const index = state.members.findIndex((m) => m.user._id === member.user._id);
    if (index < 0) {
        state.members.push(member);
    }
    else {
        state.members[index] = member;
    }
}

const updateViewerReducer = (state: MemberState, action: PayloadAction<Member>) => {
    const viewer = action.payload;
    const index = state.viewers.findIndex((m) => m.user._id === viewer.user._id);
    if (index < 0) {
        state.viewers.push(viewer);
    }
    else {
        state.viewers[index] = viewer;
    }
}

const updateMemberSettingsReducer = (state: MemberState, action: PayloadAction<MemberSetting>) => {
    state.settings = action.payload;
}

const { reducer, actions } = createSlice({
    name: 'member',
    initialState,
    reducers: {
        loadMembers: loadMembersReducer,
        updateMember: updateMemberReducer,
        updateViewer: updateViewerReducer,
        updateMemberSettings: updateMemberSettingsReducer,
    },
});

export { reducer as MemberReducer };

export const loadMembers = () => {
    return (async (dispatch: any) => {
        const member = new MemberService();
        const members = (await member.getAll()).data;

        const viewer = new ViewerService();
        const viewers = (await viewer.getAll()).data;

        const fields = new MemberSettingsService();
        const settings = (await fields.getMemberSettings()).data;

        if (members && viewers && settings) {
            dispatch(actions.loadMembers({ members, viewers, settings }));
        }
    });
};

export const addMember = (userid: string, name?: string, startdate?: Date, enddate?: Date, fields?: any) => {
    return (async (dispatch: any) => {
        const service = new MemberService();
        const newMember = (await service.save(userid, name, startdate, enddate, fields)).data;

        if (newMember) {
            dispatch(actions.updateMember(newMember));
        }
    });
};

export const updateMember = (userid: string, name?: string, startdate?: string, enddate?: string, fields?: any, valid?: boolean) => {
    return (async (dispatch: any) => {
        const service = new MemberService();
        const newMember = (await service.update(userid, name, startdate, enddate, fields, valid)).data;

        if (newMember) {
            dispatch(actions.updateMember(newMember));
        }
    });
};

export const addViewer = (userid: string) => {
    return (async (dispatch: any) => {
        const service = new ViewerService();
        const newViewer = (await service.save(userid)).data;

        if (newViewer) {
            dispatch(actions.updateViewer(newViewer));
        }
    });
};

export const updateViewer = (userid: string, valid?: boolean) => {
    return (async (dispatch: any) => {
        const service = new ViewerService();
        const newViewer = (await service.update(userid, valid)).data;

        if (newViewer) {
            dispatch(actions.updateViewer(newViewer));
        }
    });
};

export const updateMemberSettings = (settings: MemberSettingRequest) => {
    return (async (dispatch: any) => {
        const service = new MemberSettingsService();
        const newMemberField = (await service.update(settings)).data;

        if (newMemberField) {
            dispatch(actions.updateMemberSettings(newMemberField));
        }
    });
};
