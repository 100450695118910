import React from 'react';

import './tooltip.scss';

interface TooltipProps {
    children?: React.ReactNode;
    tooltip?: React.ReactNode;
    position?: "top" | "bottom" | "left" | "right";
}

export const Tooltip = (props: TooltipProps) => {
    const className = "tooltiptext " + (props.position ?? "top");
    return (
        <span className="tooltip">
            {props.children}
            <div className={className}>
                {props.tooltip}
            </div>
        </span>
    );
};
