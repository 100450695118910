import React from 'react';
import { FontIcon, Tab, Tabs, TextField } from 'react-md';
import { PageNotFound } from '../page-not-found.component';
import { useLoyalty } from './loyalty.hooks';
import { MemberDialog } from './member-dialog/member-dialog';
import { MemberSearch } from './member-search/member-search';
import { Wrapper } from '../wrapper/wrapper';
import { MemberTable } from './member-table/member-table';
import { ViewerTable } from './viewer-table/viewer-table';
import { ViewerDialog } from './viewer-dialog/viewer-dialog';

import './loyalty.scss';

export const Loyalty = () => {
    const { members, viewers, settingFields, enterprise, activeIndex, setActiveIndex, selectedUser, setSelectedUser, handleSave, hideMemberDialog, query, handleSearch, hideResult } = useLoyalty();

    if (!enterprise) {
        return <PageNotFound />
    }

    const ismember = activeIndex === 0;
    return (
        <div className="loyalty">
            <Tabs tabId="following-followers" activeTabIndex={activeIndex} onTabChange={setActiveIndex} centered={true} inactiveTabClassName="md-text--secondary">
                <Tab label="Members" />
                <Tab label="Viewers" />
            </Tabs>
            <div className="member-search">
                <TextField
                    id="member-search"
                    value={query}
                    leftIcon={<FontIcon>search</FontIcon>}
                    placeholder={ismember ? 'Search Member...' : 'Search Viewer...'}
                    fullWidth={true}
                    onChange={handleSearch}
                />
                {
                    <Wrapper onClick={hideResult}>
                        <MemberSearch query={query} onClick={setSelectedUser} />
                    </Wrapper>
                }
            </div>
            {ismember ? <MemberTable members={members} /> : <ViewerTable viewers={viewers} />}
            {selectedUser
                ? ismember
                    ? <MemberDialog visible={!!selectedUser} settingFields={settingFields} name={selectedUser.fullname} onSave={handleSave} onHide={hideMemberDialog} />
                    : <ViewerDialog visible={!!selectedUser} name={selectedUser.fullname} onSave={handleSave} onHide={hideMemberDialog} />
                : null}
        </div>
    );
};
