import React from 'react';
import { FontIcon } from 'react-md';
import { WidgetHeader } from './widget-header.component';

import './widget.component.scss';

export interface WidgetProps {
    title?: React.ReactNode;
    className?: string;
    titleColor?: string;
    actions?: JSX.Element;
    icon?: React.ReactNode;
    children?: any;
    draggable?: boolean;
    showActions?: boolean;
    dragHandleProps?: any;
    draggableProps?: any;
    draggableRef?: any;
}

type props = WidgetProps;
export const Widget = (props: props) => {

    const { title, children, className, actions, titleColor, icon, draggable, showActions } = props;
    return (
        <div
            ref={props.draggableRef}
            {...props.draggableProps}
            className={"paper " + (className ?? "")}
        >
            {(title || showActions) && <WidgetHeader title={title} icon={icon} actions={actions} titleColor={titleColor} showActions={showActions} />}
            <div className="widget-content">
                <div className="children">{children}</div>
                {draggable ? <div {...props.dragHandleProps}><FontIcon className="reorder">drag_indicator</FontIcon></div> : null}
            </div>
        </div>
    );
};
