import { SecuredService } from "./secured.service";
import { ApiResult } from "./api-result";
import { MemberSetting } from '../models/member-setting';

export class MemberSettingsService extends SecuredService {

    private baseUrl = 'api/membersettings';

    public getMemberSettings(): Promise<ApiResult<MemberSetting>> {
        return this.get(this.baseUrl);
    }

    public update(settings: MemberSettingRequest): Promise<ApiResult<MemberSetting>> {
        return this.put(this.baseUrl, settings);
    }
}

interface Field {
    name: string;
}

export interface MemberSettingRequest {
    _id: string;
    brand: string;
    fields: Field[];
}