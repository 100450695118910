import React from 'react';
import { Button } from '@react-md/button';
import { Dialog, DialogContent, DialogFooter } from '@react-md/dialog';
import { useCallback } from 'react';

interface MemberDialogProps {
    visible: boolean;
    name?: string;
    onSave: (name?: string, startdate?: Date, enddate?: Date, notes?: string) => void;
    onHide: () => void;
}

export const ViewerDialog = ({ visible, name, onSave, onHide }: MemberDialogProps) => {

    const handleSave = useCallback(() => {
        onSave();
    }, [onSave]);

    return (
        <Dialog
            id="viewer-dialog"
            visible={visible}
            onRequestClose={onHide}
            aria-labelledby="viewer-title"
            style={{ borderRadius: '16px' }}
        >
            <DialogContent>
                <h3>Add {name} as a viewer?</h3>
            </DialogContent>
            <DialogFooter>
                <Button
                    id="viewer-cancel"
                    onClick={onHide}
                    type="button"
                >
                    Cancel
                </Button>
                <Button
                    id="viewer-save"
                    onClick={handleSave}
                    type="button"
                    style={{ color: '#03a9f4' }}
                >
                    Save
                </Button>
            </DialogFooter>
        </Dialog>
    );
};
