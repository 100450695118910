import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface ErrorState {
    loginError?: string;
    signupError?: string;
}

const initialState: ErrorState = {
    loginError: undefined,
};

const setLoginErrorReducer = (state: ErrorState, action: PayloadAction<string | undefined>) => {
    state.loginError = action.payload;
};

const setSignupErrorReducer = (state: ErrorState, action: PayloadAction<string | undefined>) => {
    state.signupError = action.payload;
};

const { reducer, actions } = createSlice({
    name: 'error',
    initialState,
    reducers: {
        setLoginError: setLoginErrorReducer,
        setSignupError: setSignupErrorReducer,
    },
});

export { reducer as ErrorReducer };
export const { setLoginError, setSignupError } = actions;
