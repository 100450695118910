import { BaseService } from "./base.service";
import { ApiResult } from "./api-result";

export class AuthenticationService extends BaseService {
    private baseUrl = 'api/users';

    public login(username: string, password: string): Promise<ApiResult<any>> {
        return this.post(`${this.baseUrl}/login`, { username, password });
    }

    private static token: string | undefined = localStorage.getItem('currentUser') ?? undefined;

    public static getToken(): string | undefined {
        return this.token;
    }

    public static saveToken(token: string) {
        this.token = token;
    }

    public static clearToken() {
        this.token = undefined;
    }
}