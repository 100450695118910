import React from 'react';
import { DEFAULT_PROFILE_PICTURE } from '../preview/base64.lib';
import { MediaService } from '../../services/media.service';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../locales/keys';
import { Follower } from '../../models/follower';

interface ContactFollowerProps {
    follower: Follower;
    sponsored?: boolean;
}

export const ContactFollowerComponent = ({ follower, sponsored }: ContactFollowerProps) => {
    const { t } = useTranslation();

    return (
        <a id={`contact-${follower._id}`} key={`contact-${follower._id}`} className="paper" href={'/' + follower.userid.username}>
            <div className="contact-container">
                <img className="contact-profile" src={follower.media ? MediaService.getMediaUrl(follower.media) : DEFAULT_PROFILE_PICTURE} alt="Profile" />
                <div className="contact-info">
                    <span>{follower.userid?.fullname}</span>
                    <span>@{follower.userid?.username}</span>
                </div>
            </div>
            {follower.status?.code === "req" || follower.status?.code === "dcl" ? <div className="requested">{t(LocalizationKeys.Requested)}</div> : null}
            {sponsored ? <div className="sponsored">{/*<FontIcon style={{ fontSize: '12px', color: '#03a9f4', marginRight: '4px' }}>verified</FontIcon>*/}SPONSORED</div> : null}
        </a>
    );
};
