import { logout, showOnboarding } from "../../redux/authentication.state";
import { LocalizationKeys } from '../../locales/keys';

export interface PageletItemProps {
    title: string;
    href?: string;
    target?: string;
    subheader?: string;
    icon?: string;
    src?: string;
    dispatchAction?: any;
}

export type PageletProp = PageletItemProps | 'profile' | 'divider';

export const defaultSettingsPagelet: PageletProp[] = [
    { title: LocalizationKeys.GiveFeedback, href: 'https://www.votie.cc/fizlinkfeedback', target: "_blank", subheader: LocalizationKeys.HelpImproveFizLink, icon: 'feedback' },
    'divider',
    { title: LocalizationKeys.WhatsNew, subheader: LocalizationKeys.CheckOutNewFeatures, icon: 'new_releases', dispatchAction: showOnboarding(true) },
    'divider',
    { title: LocalizationKeys.SettingsPrivacy, href: '/profile/notifications', icon: 'settings' },
    { title: LocalizationKeys.HelpSupport, href: 'http://www.votie.cc/fizlinksupport', target: '_blank', icon: 'help' },
    { title: LocalizationKeys.Logout, icon: 'exit_to_app', dispatchAction: logout() },
]