import React from 'react';

export const FizlinkQrComponent = () => {
    return (
        <svg className="qr-image" width="30" height="30" viewBox="0 0 264 264"
            xmlns="http://www.w3.org/2000/svg">
            <rect x="0" y="0" width="264" height="264" fill="#ffffff" />
            <g fill="#000000">
                <rect id="p" width="8" height="8" x="32" y="32" />
                <rect id="p" width="8" height="8" x="32" y="40" />
                <rect id="p" width="8" height="8" x="32" y="48" />
                <rect id="p" width="8" height="8" x="32" y="56" />
                <rect id="p" width="8" height="8" x="32" y="64" />
                <rect id="p" width="8" height="8" x="32" y="72" />
                <rect id="p" width="8" height="8" x="32" y="80" />
                <rect id="p" width="8" height="8" x="32" y="96" />
                <rect id="p" width="8" height="8" x="32" y="112" />
                <rect id="p" width="8" height="8" x="32" y="176" />
                <rect id="p" width="8" height="8" x="32" y="184" />
                <rect id="p" width="8" height="8" x="32" y="192" />
                <rect id="p" width="8" height="8" x="32" y="200" />
                <rect id="p" width="8" height="8" x="32" y="208" />
                <rect id="p" width="8" height="8" x="32" y="216" />
                <rect id="p" width="8" height="8" x="32" y="224" />
                <rect id="p" width="8" height="8" x="40" y="32" />
                <rect id="p" width="8" height="8" x="40" y="80" />
                <rect id="p" width="8" height="8" x="40" y="96" />
                <rect id="p" width="8" height="8" x="40" y="120" />
                <rect id="p" width="8" height="8" x="40" y="144" />
                <rect id="p" width="8" height="8" x="40" y="152" />
                <rect id="p" width="8" height="8" x="40" y="160" />
                <rect id="p" width="8" height="8" x="40" y="176" />
                <rect id="p" width="8" height="8" x="40" y="224" />
                <rect id="p" width="8" height="8" x="48" y="32" />
                <rect id="p" width="8" height="8" x="48" y="48" />
                <rect id="p" width="8" height="8" x="48" y="56" />
                <rect id="p" width="8" height="8" x="48" y="64" />
                <rect id="p" width="8" height="8" x="48" y="80" />
                <rect id="p" width="8" height="8" x="48" y="96" />
                <rect id="p" width="8" height="8" x="48" y="104" />
                <rect id="p" width="8" height="8" x="48" y="144" />
                <rect id="p" width="8" height="8" x="48" y="160" />
                <rect id="p" width="8" height="8" x="48" y="176" />
                <rect id="p" width="8" height="8" x="48" y="192" />
                <rect id="p" width="8" height="8" x="48" y="200" />
                <rect id="p" width="8" height="8" x="48" y="208" />
                <rect id="p" width="8" height="8" x="48" y="224" />
                <rect id="p" width="8" height="8" x="56" y="32" />
                <rect id="p" width="8" height="8" x="56" y="48" />
                <rect id="p" width="8" height="8" x="56" y="56" />
                <rect id="p" width="8" height="8" x="56" y="64" />
                <rect id="p" width="8" height="8" x="56" y="80" />
                <rect id="p" width="8" height="8" x="56" y="96" />
                <rect id="p" width="8" height="8" x="56" y="104" />
                <rect id="p" width="8" height="8" x="56" y="120" />
                <rect id="p" width="8" height="8" x="56" y="128" />
                <rect id="p" width="8" height="8" x="56" y="144" />
                <rect id="p" width="8" height="8" x="56" y="152" />
                <rect id="p" width="8" height="8" x="56" y="176" />
                <rect id="p" width="8" height="8" x="56" y="192" />
                <rect id="p" width="8" height="8" x="56" y="200" />
                <rect id="p" width="8" height="8" x="56" y="208" />
                <rect id="p" width="8" height="8" x="56" y="224" />
                <rect id="p" width="8" height="8" x="64" y="32" />
                <rect id="p" width="8" height="8" x="64" y="48" />
                <rect id="p" width="8" height="8" x="64" y="56" />
                <rect id="p" width="8" height="8" x="64" y="64" />
                <rect id="p" width="8" height="8" x="64" y="80" />
                <rect id="p" width="8" height="8" x="64" y="96" />
                <rect id="p" width="8" height="8" x="64" y="112" />
                <rect id="p" width="8" height="8" x="64" y="120" />
                <rect id="p" width="8" height="8" x="64" y="136" />
                <rect id="p" width="8" height="8" x="64" y="176" />
                <rect id="p" width="8" height="8" x="64" y="192" />
                <rect id="p" width="8" height="8" x="64" y="200" />
                <rect id="p" width="8" height="8" x="64" y="208" />
                <rect id="p" width="8" height="8" x="64" y="224" />
                <rect id="p" width="8" height="8" x="72" y="32" />
                <rect id="p" width="8" height="8" x="72" y="80" />
                <rect id="p" width="8" height="8" x="72" y="104" />
                <rect id="p" width="8" height="8" x="72" y="120" />
                <rect id="p" width="8" height="8" x="72" y="136" />
                <rect id="p" width="8" height="8" x="72" y="144" />
                <rect id="p" width="8" height="8" x="72" y="152" />
                <rect id="p" width="8" height="8" x="72" y="160" />
                <rect id="p" width="8" height="8" x="72" y="176" />
                <rect id="p" width="8" height="8" x="72" y="224" />
                <rect id="p" width="8" height="8" x="80" y="32" />
                <rect id="p" width="8" height="8" x="80" y="40" />
                <rect id="p" width="8" height="8" x="80" y="48" />
                <rect id="p" width="8" height="8" x="80" y="56" />
                <rect id="p" width="8" height="8" x="80" y="64" />
                <rect id="p" width="8" height="8" x="80" y="72" />
                <rect id="p" width="8" height="8" x="80" y="80" />
                <rect id="p" width="8" height="8" x="80" y="96" />
                <rect id="p" width="8" height="8" x="80" y="112" />
                <rect id="p" width="8" height="8" x="80" y="128" />
                <rect id="p" width="8" height="8" x="80" y="144" />
                <rect id="p" width="8" height="8" x="80" y="160" />
                <rect id="p" width="8" height="8" x="80" y="176" />
                <rect id="p" width="8" height="8" x="80" y="184" />
                <rect id="p" width="8" height="8" x="80" y="192" />
                <rect id="p" width="8" height="8" x="80" y="200" />
                <rect id="p" width="8" height="8" x="80" y="208" />
                <rect id="p" width="8" height="8" x="80" y="216" />
                <rect id="p" width="8" height="8" x="80" y="224" />
                <rect id="p" width="8" height="8" x="88" y="96" />
                <rect id="p" width="8" height="8" x="88" y="104" />
                <rect id="p" width="8" height="8" x="88" y="128" />
                <rect id="p" width="8" height="8" x="88" y="160" />
                <rect id="p" width="8" height="8" x="96" y="40" />
                <rect id="p" width="8" height="8" x="96" y="56" />
                <rect id="p" width="8" height="8" x="96" y="72" />
                <rect id="p" width="8" height="8" x="96" y="80" />
                <rect id="p" width="8" height="8" x="96" y="96" />
                <rect id="p" width="8" height="8" x="96" y="120" />
                <rect id="p" width="8" height="8" x="96" y="136" />
                <rect id="p" width="8" height="8" x="96" y="144" />
                <rect id="p" width="8" height="8" x="96" y="160" />
                <rect id="p" width="8" height="8" x="96" y="168" />
                <rect id="p" width="8" height="8" x="96" y="176" />
                <rect id="p" width="8" height="8" x="96" y="192" />
                <rect id="p" width="8" height="8" x="96" y="200" />
                <rect id="p" width="8" height="8" x="96" y="208" />
                <rect id="p" width="8" height="8" x="96" y="216" />
                <rect id="p" width="8" height="8" x="96" y="224" />
                <rect id="p" width="8" height="8" x="104" y="56" />
                <rect id="p" width="8" height="8" x="104" y="64" />
                <rect id="p" width="8" height="8" x="104" y="72" />
                <rect id="p" width="8" height="8" x="104" y="88" />
                <rect id="p" width="8" height="8" x="104" y="96" />
                <rect id="p" width="8" height="8" x="104" y="128" />
                <rect id="p" width="8" height="8" x="104" y="136" />
                <rect id="p" width="8" height="8" x="104" y="144" />
                <rect id="p" width="8" height="8" x="104" y="160" />
                <rect id="p" width="8" height="8" x="104" y="168" />
                <rect id="p" width="8" height="8" x="104" y="176" />
                <rect id="p" width="8" height="8" x="104" y="208" />
                <rect id="p" width="8" height="8" x="104" y="216" />
                <rect id="p" width="8" height="8" x="112" y="32" />
                <rect id="p" width="8" height="8" x="112" y="40" />
                <rect id="p" width="8" height="8" x="112" y="56" />
                <rect id="p" width="8" height="8" x="112" y="80" />
                <rect id="p" width="8" height="8" x="112" y="88" />
                <rect id="p" width="8" height="8" x="112" y="104" />
                <rect id="p" width="8" height="8" x="112" y="112" />
                <rect id="p" width="8" height="8" x="112" y="128" />
                <rect id="p" width="8" height="8" x="112" y="144" />
                <rect id="p" width="8" height="8" x="112" y="152" />
                <rect id="p" width="8" height="8" x="112" y="160" />
                <rect id="p" width="8" height="8" x="112" y="176" />
                <rect id="p" width="8" height="8" x="112" y="200" />
                <rect id="p" width="8" height="8" x="120" y="72" />
                <rect id="p" width="8" height="8" x="120" y="88" />
                <rect id="p" width="8" height="8" x="120" y="96" />
                <rect id="p" width="8" height="8" x="120" y="144" />
                <rect id="p" width="8" height="8" x="120" y="152" />
                <rect id="p" width="8" height="8" x="120" y="160" />
                <rect id="p" width="8" height="8" x="120" y="208" />
                <rect id="p" width="8" height="8" x="120" y="216" />
                <rect id="p" width="8" height="8" x="120" y="224" />
                <rect id="p" width="8" height="8" x="128" y="32" />
                <rect id="p" width="8" height="8" x="128" y="48" />
                <rect id="p" width="8" height="8" x="128" y="56" />
                <rect id="p" width="8" height="8" x="128" y="72" />
                <rect id="p" width="8" height="8" x="128" y="80" />
                <rect id="p" width="8" height="8" x="128" y="104" />
                <rect id="p" width="8" height="8" x="128" y="120" />
                <rect id="p" width="8" height="8" x="128" y="128" />
                <rect id="p" width="8" height="8" x="128" y="144" />
                <rect id="p" width="8" height="8" x="128" y="168" />
                <rect id="p" width="8" height="8" x="128" y="184" />
                <rect id="p" width="8" height="8" x="128" y="192" />
                <rect id="p" width="8" height="8" x="128" y="208" />
                <rect id="p" width="8" height="8" x="136" y="32" />
                <rect id="p" width="8" height="8" x="136" y="40" />
                <rect id="p" width="8" height="8" x="136" y="56" />
                <rect id="p" width="8" height="8" x="136" y="64" />
                <rect id="p" width="8" height="8" x="136" y="104" />
                <rect id="p" width="8" height="8" x="136" y="112" />
                <rect id="p" width="8" height="8" x="136" y="120" />
                <rect id="p" width="8" height="8" x="136" y="168" />
                <rect id="p" width="8" height="8" x="136" y="176" />
                <rect id="p" width="8" height="8" x="136" y="184" />
                <rect id="p" width="8" height="8" x="144" y="40" />
                <rect id="p" width="8" height="8" x="144" y="48" />
                <rect id="p" width="8" height="8" x="144" y="56" />
                <rect id="p" width="8" height="8" x="144" y="72" />
                <rect id="p" width="8" height="8" x="144" y="80" />
                <rect id="p" width="8" height="8" x="144" y="88" />
                <rect id="p" width="8" height="8" x="144" y="112" />
                <rect id="p" width="8" height="8" x="144" y="128" />
                <rect id="p" width="8" height="8" x="144" y="152" />
                <rect id="p" width="8" height="8" x="144" y="168" />
                <rect id="p" width="8" height="8" x="144" y="176" />
                <rect id="p" width="8" height="8" x="144" y="192" />
                <rect id="p" width="8" height="8" x="144" y="216" />
                <rect id="p" width="8" height="8" x="152" y="40" />
                <rect id="p" width="8" height="8" x="152" y="56" />
                <rect id="p" width="8" height="8" x="152" y="72" />
                <rect id="p" width="8" height="8" x="152" y="112" />
                <rect id="p" width="8" height="8" x="152" y="144" />
                <rect id="p" width="8" height="8" x="152" y="168" />
                <rect id="p" width="8" height="8" x="152" y="192" />
                <rect id="p" width="8" height="8" x="152" y="208" />
                <rect id="p" width="8" height="8" x="152" y="216" />
                <rect id="p" width="8" height="8" x="160" y="32" />
                <rect id="p" width="8" height="8" x="160" y="40" />
                <rect id="p" width="8" height="8" x="160" y="64" />
                <rect id="p" width="8" height="8" x="160" y="80" />
                <rect id="p" width="8" height="8" x="160" y="88" />
                <rect id="p" width="8" height="8" x="160" y="112" />
                <rect id="p" width="8" height="8" x="160" y="136" />
                <rect id="p" width="8" height="8" x="160" y="152" />
                <rect id="p" width="8" height="8" x="160" y="160" />
                <rect id="p" width="8" height="8" x="160" y="168" />
                <rect id="p" width="8" height="8" x="160" y="176" />
                <rect id="p" width="8" height="8" x="160" y="184" />
                <rect id="p" width="8" height="8" x="160" y="192" />
                <rect id="p" width="8" height="8" x="160" y="208" />
                <rect id="p" width="8" height="8" x="160" y="216" />
                <rect id="p" width="8" height="8" x="168" y="96" />
                <rect id="p" width="8" height="8" x="168" y="120" />
                <rect id="p" width="8" height="8" x="168" y="128" />
                <rect id="p" width="8" height="8" x="168" y="136" />
                <rect id="p" width="8" height="8" x="168" y="160" />
                <rect id="p" width="8" height="8" x="168" y="192" />
                <rect id="p" width="8" height="8" x="168" y="200" />
                <rect id="p" width="8" height="8" x="168" y="216" />
                <rect id="p" width="8" height="8" x="176" y="32" />
                <rect id="p" width="8" height="8" x="176" y="40" />
                <rect id="p" width="8" height="8" x="176" y="48" />
                <rect id="p" width="8" height="8" x="176" y="56" />
                <rect id="p" width="8" height="8" x="176" y="64" />
                <rect id="p" width="8" height="8" x="176" y="72" />
                <rect id="p" width="8" height="8" x="176" y="80" />
                <rect id="p" width="8" height="8" x="176" y="120" />
                <rect id="p" width="8" height="8" x="176" y="128" />
                <rect id="p" width="8" height="8" x="176" y="160" />
                <rect id="p" width="8" height="8" x="176" y="176" />
                <rect id="p" width="8" height="8" x="176" y="192" />
                <rect id="p" width="8" height="8" x="176" y="200" />
                <rect id="p" width="8" height="8" x="176" y="224" />
                <rect id="p" width="8" height="8" x="184" y="32" />
                <rect id="p" width="8" height="8" x="184" y="80" />
                <rect id="p" width="8" height="8" x="184" y="96" />
                <rect id="p" width="8" height="8" x="184" y="104" />
                <rect id="p" width="8" height="8" x="184" y="120" />
                <rect id="p" width="8" height="8" x="184" y="136" />
                <rect id="p" width="8" height="8" x="184" y="144" />
                <rect id="p" width="8" height="8" x="184" y="152" />
                <rect id="p" width="8" height="8" x="184" y="160" />
                <rect id="p" width="8" height="8" x="184" y="192" />
                <rect id="p" width="8" height="8" x="184" y="216" />
                <rect id="p" width="8" height="8" x="184" y="224" />
                <rect id="p" width="8" height="8" x="192" y="32" />
                <rect id="p" width="8" height="8" x="192" y="48" />
                <rect id="p" width="8" height="8" x="192" y="56" />
                <rect id="p" width="8" height="8" x="192" y="64" />
                <rect id="p" width="8" height="8" x="192" y="80" />
                <rect id="p" width="8" height="8" x="192" y="112" />
                <rect id="p" width="8" height="8" x="192" y="128" />
                <rect id="p" width="8" height="8" x="192" y="144" />
                <rect id="p" width="8" height="8" x="192" y="152" />
                <rect id="p" width="8" height="8" x="192" y="160" />
                <rect id="p" width="8" height="8" x="192" y="168" />
                <rect id="p" width="8" height="8" x="192" y="176" />
                <rect id="p" width="8" height="8" x="192" y="184" />
                <rect id="p" width="8" height="8" x="192" y="192" />
                <rect id="p" width="8" height="8" x="192" y="200" />
                <rect id="p" width="8" height="8" x="192" y="216" />
                <rect id="p" width="8" height="8" x="192" y="224" />
                <rect id="p" width="8" height="8" x="200" y="32" />
                <rect id="p" width="8" height="8" x="200" y="48" />
                <rect id="p" width="8" height="8" x="200" y="56" />
                <rect id="p" width="8" height="8" x="200" y="64" />
                <rect id="p" width="8" height="8" x="200" y="80" />
                <rect id="p" width="8" height="8" x="200" y="96" />
                <rect id="p" width="8" height="8" x="200" y="112" />
                <rect id="p" width="8" height="8" x="200" y="136" />
                <rect id="p" width="8" height="8" x="200" y="144" />
                <rect id="p" width="8" height="8" x="200" y="168" />
                <rect id="p" width="8" height="8" x="200" y="184" />
                <rect id="p" width="8" height="8" x="200" y="200" />
                <rect id="p" width="8" height="8" x="200" y="208" />
                <rect id="p" width="8" height="8" x="200" y="216" />
                <rect id="p" width="8" height="8" x="200" y="224" />
                <rect id="p" width="8" height="8" x="208" y="32" />
                <rect id="p" width="8" height="8" x="208" y="48" />
                <rect id="p" width="8" height="8" x="208" y="56" />
                <rect id="p" width="8" height="8" x="208" y="64" />
                <rect id="p" width="8" height="8" x="208" y="80" />
                <rect id="p" width="8" height="8" x="208" y="128" />
                <rect id="p" width="8" height="8" x="208" y="160" />
                <rect id="p" width="8" height="8" x="208" y="176" />
                <rect id="p" width="8" height="8" x="208" y="192" />
                <rect id="p" width="8" height="8" x="208" y="200" />
                <rect id="p" width="8" height="8" x="208" y="208" />
                <rect id="p" width="8" height="8" x="208" y="224" />
                <rect id="p" width="8" height="8" x="216" y="32" />
                <rect id="p" width="8" height="8" x="216" y="80" />
                <rect id="p" width="8" height="8" x="216" y="96" />
                <rect id="p" width="8" height="8" x="216" y="104" />
                <rect id="p" width="8" height="8" x="216" y="112" />
                <rect id="p" width="8" height="8" x="216" y="128" />
                <rect id="p" width="8" height="8" x="216" y="136" />
                <rect id="p" width="8" height="8" x="216" y="144" />
                <rect id="p" width="8" height="8" x="216" y="176" />
                <rect id="p" width="8" height="8" x="216" y="184" />
                <rect id="p" width="8" height="8" x="216" y="200" />
                <rect id="p" width="8" height="8" x="216" y="224" />
                <rect id="p" width="8" height="8" x="224" y="32" />
                <rect id="p" width="8" height="8" x="224" y="40" />
                <rect id="p" width="8" height="8" x="224" y="48" />
                <rect id="p" width="8" height="8" x="224" y="56" />
                <rect id="p" width="8" height="8" x="224" y="64" />
                <rect id="p" width="8" height="8" x="224" y="72" />
                <rect id="p" width="8" height="8" x="224" y="80" />
                <rect id="p" width="8" height="8" x="224" y="112" />
                <rect id="p" width="8" height="8" x="224" y="120" />
                <rect id="p" width="8" height="8" x="224" y="128" />
                <rect id="p" width="8" height="8" x="224" y="144" />
                <rect id="p" width="8" height="8" x="224" y="152" />
                <rect id="p" width="8" height="8" x="224" y="176" />
                <rect id="p" width="8" height="8" x="224" y="192" />
                <rect id="p" width="8" height="8" x="224" y="200" />
                <rect id="p" width="8" height="8" x="224" y="208" />
                <rect id="p" width="8" height="8" x="224" y="216" />
                <rect id="p" width="8" height="8" x="224" y="224" />
            </g>
        </svg>
    );
};
