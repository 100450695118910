import { combineReducers } from 'redux';
import { MobileCollectionReducer } from './mobile-collection.state';
import { MobileConnectionReducer } from './mobile-connection.state';
import { AuthenticationReducer } from './authentication.state';
import { EntityReducer } from './entity.state';
import { ErrorReducer } from './error.state';
import { SelectorReducer } from './selector.state';
import { UserReducer } from './user.state';
import { MobileReducer } from './mobile.state';
import { TagReducer } from './tag.state';
import { FollowingReducer } from './following.state';
import { UserSettingReducer } from './user-setting.state';
import { PushNotificationReducer } from './push-notification.state';
import { MemberReducer } from './member.state';

const rootReducer = combineReducers({
    //reducerName: Reducer exported from slice
    authentication: AuthenticationReducer,
    mobilecollection: MobileCollectionReducer,
    mobileconnection: MobileConnectionReducer,
    mobile: MobileReducer,
    following: FollowingReducer,
    tag: TagReducer,
    user: UserReducer,
    selector: SelectorReducer,
    entity: EntityReducer,
    error: ErrorReducer,
    usersetting: UserSettingReducer,
    pushnotification: PushNotificationReducer,
    member: MemberReducer,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;