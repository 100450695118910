import { SecuredService } from "./secured.service";
import { ApiResult } from "./api-result";
import { UserSetting } from '../models/user-setting';

export class UserSettingService extends SecuredService {

    private baseUrl = 'api/usersettings';

    public getSettings(): Promise<ApiResult<UserSetting>> {
        return this.get(this.baseUrl);
    }

    public updateSettings(settings: UserSetting): Promise<ApiResult<UserSetting>> {
        return this.put(`${this.baseUrl}/${settings._id}`, settings);
    }
}