import React, { useState } from 'react';
import { Dialog, DialogHeader, DialogTitle, DialogFooter, DialogContent } from '@react-md/dialog';
import { DEFAULT_PROFILE_PICTURE } from '../preview/base64.lib';
import { Button } from '@react-md/button';
import { Follower } from '../../models/follower';
import { FollowingService } from '../../services/following.service';
import { Following } from '../../models/following';
import { MediaService } from '../../services/media.service';

import './follower.dialog.component.scss';

interface FollowerDialogProps {
    visible: boolean;
    following: Following[];
    pending: Follower[];
    onHide: (refreshFollowing: boolean, refreshFollowers: boolean) => void | undefined;
}

export const FollowerDialogComponent = (props: FollowerDialogProps) => {
    const [approved, setApproved] = useState<string[]>([]);
    const [declined, setDeclined] = useState<string[]>([]);
    const [followedBack, setFollowedBack] = useState<string[]>([]);

    const handleApprove = (id: string) => {
        const service = new FollowingService();
        service.approveFollower(id)
            .then(() => {
                setApproved([...approved, id]);
            })
            .catch(() => {

            });
    };

    const handleDecline = (id: string) => {
        const service = new FollowingService();
        service.declineFollower(id)
            .then(() => {
                setDeclined([...declined, id]);
            })
            .catch(() => {

            });
    };

    const handleOnFollowBack = (userId: string) => {
        const service = new FollowingService();
        service.postFollow(userId)
            .then(() => {
                setFollowedBack([...followedBack, userId]);
            })
            .catch(() => {

            });
    };

    const handleOnHide = () => {
        props.onHide(!!followedBack.length, !!approved.length || !!declined.length);
    }

    const renderApproveDecline = (id: string): JSX.Element => {
        return (
            <div>
                <button className="approve-follower" onClick={() => handleApprove(id)}>Approve</button>
                <button className="decline-follower" onClick={() => handleDecline(id)}>Decline</button>
            </div>
        );
    };

    const renderFollowBack = (userId: string): JSX.Element => {
        return <button className="follow-back" onClick={() => handleOnFollowBack(userId)}>Follow Back</button>;
    };

    const renderPending = (follower: Follower): JSX.Element => {
        return (
            <div className="pending-follower">
                <div style={{ display: 'flex', marginBottom: '16px' }}>
                    <img height={32} width={32} alt="Profile" className="pending-follower-profile" src={follower.media ? MediaService.getMediaUrl(follower.media) : DEFAULT_PROFILE_PICTURE} style={{ objectFit: 'cover' }} />
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '16px' }}>
                        <span>{follower.userid?.fullname}</span>
                        <span>@{follower.userid?.username}</span>
                    </div>
                </div>
                {approved.find((a) => a === follower.userid._id) ? renderFollowBack(follower.userid._id) : renderApproveDecline(follower.userid._id)}
            </div>
        );
    };

    // hide pending request if approved/declined already and user is following
    const approvedDeclinedFollowing = props.following
        .filter((f) => approved.includes(f.followingid._id) || declined.includes(f.followingid._id))
        .map((f) => f.followingid._id);
    const filteredPending = props.pending
        .filter((p) => !followedBack.includes(p.userid._id)
            && !declined.includes(p.userid._id)
            && !approvedDeclinedFollowing.includes(p.userid._id));
    if (!filteredPending.length) {
        handleOnHide();
    }

    return (
        <Dialog
            id="follower-dialog"
            visible={props.visible}
            onRequestClose={handleOnHide}
            aria-labelledby="follower-title"
            style={{ borderRadius: '16px' }}
        >
            <DialogHeader>
                <DialogTitle id="follower-title">Follower Requests</DialogTitle>
            </DialogHeader>
            <DialogContent>
                {filteredPending.map(renderPending)}
            </DialogContent>
            <DialogFooter>
                <Button
                    id="follower-cancel"
                    onClick={handleOnHide}
                    type="button"
                >
                    Cancel
                </Button>
            </DialogFooter>
        </Dialog>
    );
};
