import React from 'react';
import { Checkbox } from 'react-md';
import { useNotifications } from './notifications.hooks';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../../locales/keys';

import './notifications.component.scss';

export const NotificationsComponent = () => {
    const { t } = useTranslation();
    const { notifications, update } = useNotifications();

    return (
        <>
            <h3>{t(LocalizationKeys.SubscribeTo)}</h3>
            <div className="email-settings">
                <Checkbox id="feedback" labelClassName="email-headline" label={`${t(LocalizationKeys.FeedbackEmails)}`} name={`${t(LocalizationKeys.FeedbackEmails)}`} value={`${t(LocalizationKeys.FeedbackEmails)}`} checked={notifications?.feedback} onChange={update} />
                <span className="email-subheadline">{t(LocalizationKeys.FeedbackEmailsInfo)}</span>
            </div>
            <div className="email-settings">
                <Checkbox id="reminders" labelClassName="email-headline" label={`${t(LocalizationKeys.ReminderEmails)}`} name={`${t(LocalizationKeys.ReminderEmails)}`} value={`${t(LocalizationKeys.ReminderEmails)}`} checked={notifications?.reminders} onChange={update} />
                <span className="email-subheadline">{t(LocalizationKeys.ReminderEmailsInfo)}</span>
            </div>
            <div className="email-settings">
                <Checkbox id="news" labelClassName="email-headline" label={`${t(LocalizationKeys.NewsEmails)}`} name={`${t(LocalizationKeys.NewsEmails)}`} value={`${t(LocalizationKeys.NewsEmails)}`} checked={notifications?.news} onChange={update} />
                <span className="email-subheadline">{t(LocalizationKeys.NewsEmailsInfo)}</span>
            </div>
        </>
    );
};
