import React from 'react';
import { UserSearch } from '../../../models/user-search';
import { MediaService } from '../../../services/media.service';
import { DEFAULT_PROFILE_PICTURE } from '../../preview/base64.lib';

interface MemberSearchItemProps {
    user: UserSearch;
    onClick?: (user: UserSearch) => void;
}

export const MemberSearchItem = ({ user, onClick }: MemberSearchItemProps) => {
    return (
        <div id={`member-${user._id}`} key={`member-${user._id}`} className="member-container" onClick={() => onClick?.(user)}>
            <img className="member-profile" src={user.media ? MediaService.getMediaUrl(user.media) : DEFAULT_PROFILE_PICTURE} alt="Profile" />
            <div className="member-info">
                <span>{user.fullname}</span>
                <span style={{ display: 'flex', alignItems: 'center' }}>@{user.username}</span>
            </div>
        </div>
    );
};
