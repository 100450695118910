import { SecuredService } from "./secured.service";
import { ApiResult } from "./api-result";
import { Selector } from '../models/selector';

export class DefaultSelectorService extends SecuredService {

    private baseUrl = 'api/defaultselectors';

    public getSelectors(): Promise<ApiResult<Selector[]>> {
        return this.get(this.baseUrl);
    }
}