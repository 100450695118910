import React from 'react';
import { MenuButton, ListItem } from 'react-md';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { MediaService } from '../../services/media.service';
import { DEFAULT_PROFILE_PICTURE } from '../preview/base64.lib';
import { useSelectedMobile } from '../../hooks/mobile.hooks';

export const OnboardingReminderComponent = () => {
    const token = useSelector((state: RootState) => state.authentication.token);
    const selectedMobile = useSelectedMobile();
    const page = selectedMobile?.pages[0];
    const profilePicture = page?.picture ? MediaService.getMediaUrl(page.picture) : DEFAULT_PROFILE_PICTURE;

    const menuItems = ['...', "What's New?", "..."];
    return (
        <div style={{ paddingBottom: '136px' }}>
            <MenuButton
                id="onboarding-profile-menu"
                position="br"
                visible={true}
                style={{ borderRadius: '16px' }}
                listStyle={{ borderRadius: '16px' }}
                menuItems={menuItems.map((item) => <ListItem id={item} key={`profile-${item}`} primaryText={item} active={item === "What's New?"} activeBoxStyle={{ backgroundColor: 'lightgray' }} />)}
            >
                <div className="onboarding-profile-action">
                    <img height={24} width={24} alt="Profile" className="onboarding-profile-picture" src={profilePicture} />
                    <label style={{ marginLeft: '8px' }}>{token?.firstname ?? "Jon"}</label>
                </div>
            </MenuButton>
        </div>
    );
};
