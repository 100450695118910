import { WelcomeComponent } from './welcome.component';
import { EditLinkComponent } from './edit-link.component';
import { FollowContactComponent } from './follow-contact.component';
import { QrContactCard } from './qr-contact-card';
import { FizlinkIconsComponent } from './fizlink-icons.component';
import { AddToHomescreenComponent } from './add-to-homescreen.component';
import { OnboardingReminderComponent } from './onboarding-reminder.component';

export interface Onboarding {
    title: string;
    content: string;
    image?: React.ComponentType<any>;
    titleFirst?: boolean;
}

export const onboardingPages: Onboarding[] = [
    { title: 'Welcome to FizLink!', content: 'One link for all your content.', image: WelcomeComponent },
    { title: 'Editing links', content: 'You can edit link labels, URLs, and images. Press the lock button to make links private so only friends can see them. You can even reorder links to show your most important ones first!', image: EditLinkComponent },
    { title: 'Contacts', content: 'Follow people so you never miss out on their most up to date contact info.', image: FollowContactComponent, titleFirst: true },
    { title: 'QR Contact Card', content: 'Rotate your phone to view and show off your contactless business card! This is great to share when meeting new colleagues. Did you know you can use your phone\'s camera app to scan QR codes? Rotate and try it out.', image: QrContactCard },
    { title: 'FizLink Icons', content: 'Keep an eye out for these icons.', image: FizlinkIconsComponent, titleFirst: true },
];

export const AddToHomescreenOnboarding: Onboarding = {
    title: 'Add to Home Screen',
    content: 'Save your FizLink to your home screen so you can access it with one click.',
    image: AddToHomescreenComponent,
};

export const OnboardingReminder: Onboarding = {
    title: 'All done!',
    content: 'Now you can start enjoying FizLink! You can always review the onboarding by following the above instructions.',
    image: OnboardingReminderComponent,
};