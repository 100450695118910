import React from 'react';

import './terms-and-conditions.scss';

export const TermsAndConditions = () => {
    return (
        <div className="WordSection1">
        <p className="MsoNormal" style={{msTextAutospace: 'none'}}><i><span lang="FR-CA" style={{fontSize: '14.0pt', color: 'black'}}>*La version française suit ci-dessous</span></i></p>
        <p className="MsoNormal" style={{msTextAutospace: 'none'}}><b><span lang="FR-CA" style={{fontSize: '17.0pt', color: 'black'}}>&nbsp;</span></b></p>
        <p className="MsoNormal" style={{msTextAutospace: 'none'}}><b><span style={{fontSize: '17.0pt', color: 'black'}}>Terms And Conditions</span></b></p>
        <p className="MsoNormal" style={{msTextAutospace: 'none'}}><span style={{color: 'black'}}>Votie
            Solutions Inc. &amp; FizLink Inc. , and its affiliates and subsidiaries
            (collectively, “FizLink” “Votie”,&nbsp; “we” “us,” or “our”) takes your privacy
            seriously. These Terms and Conditions describes </span><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>how you are
              bound by these terms in using the platform operated by Votie Solutions Inc.
              &amp; our </span></span><span style={{color: 'black'}}>Privacy Policy and how we
            collect, use, disclose, transfer, and otherwise process your Personal
            Information. It also tells you about your rights with respect to your Personal
            Information, and how you can reach us to update it or get answers to questions
            you may have about our privacy practices. By continuing to use FizLink you
            agree to the following terms and condition and to be bound by the following.• </span><span className="styleswrappedcontent-sc-1w7ms31-0"><b><span style={{color: 'black'}}>Your
                access to and use of the Service is</span></b><span style={{color: 'black'}}> <b>conditioned
                on your acceptance of and compliance with these Terms</b>. These Terms apply to
              all visitors, users and others who access or use the Service. You warrant that
              you are at least 18-years-old and you are legally capable of entering into
              binding contracts. If you are under 18-years-old, you warrant that you have
              obtained consent from your parent or guardian and they agree to be bound by
              these Terms on your behalf.</span></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>By accessing
              or using the Service you agree to be bound by these Terms. If you disagree with
              any part of the terms then you may not access the Service.</span></span></p>
        <p className="MsoNormal" style={{msTextAutospace: 'none'}}><span style={{color: 'black'}}>&nbsp;</span></p>
        <p className="MsoNormal" style={{marginBottom: '2.0pt', msTextAutospace: 'none'}}><b><span style={{fontSize: '14.0pt', color: 'black'}}>1. Scope</span></b></p>
        <p className="MsoNormal" style={{msTextAutospace: 'none'}}><span style={{color: 'black'}}>This Terms
            &amp; Conditions and Privacy Policy covers the processing of Personal
            Information related to FizLink's products and services (“Services"), including:
            (1) website(s) that may be accessed at https://www.FizLink.com/ (“Site”), (2)
            services https://www.goFizLink.com/, https://www.FizLink.cc/, &amp; https://www.FizLink.ca,
            including technical support and services accessible through the Site(s) (“Web
            Apps”), (3) software that may be downloaded to your smartphone or tablet to
            access services (“Mobile Apps”), and (4) subscription services, including
            services you can access using the Web Apps and Mobile Apps (“Subscription Services”).
            Third parties may also integrate FizLink's Products and Services into their
            products and services and we are not responsible for this integration.
            Additional information on our privacy practices may be provided in other
            documents such as offer descriptions, supplemental privacy statements, or
            notices provided prior to or at the time of data collection.</span></p>
        <p className="MsoNormal" style={{marginBottom: '2.0pt', msTextAutospace: 'none'}}><b><span style={{fontSize: '14.0pt', color: 'black'}}>2. Personal Information We May Collect</span></b></p>
        <p className="MsoNormal" style={{msTextAutospace: 'none'}}><span style={{color: 'black'}}>We
            obtain personal information relating to you from various sources such as
            described below. For the purpose of this Privacy Policy, “personal information”
            means any information that personally identifies you, such as your name, your
            age, you phone number, your sex, your email address or billing information, or
            other data that can be reasonably used to infer this information. Where
            applicable, we indicate whether and why you must provide us with your Personal
            Information, as well as the consequences of failing to do so. If you do not
            provide Personal Information when requested, you may not be able to benefit from
            our Services if that information is necessary to provide you with the Service.</span></p>
        <p className="MsoNormal" style={{marginBottom: '2.0pt', msTextAutospace: 'none'}}><b><span style={{fontSize: '14.0pt', color: 'black'}}>a. Personal Information We Collect
              Directly From You</span></b></p>
        <p className="MsoNormal" style={{msTextAutospace: 'none'}}><span style={{color: 'black'}}>○
            Registration. In order to use the Services, we may ask you to provide certain
            Personal Information when you create an account on FizLink.com or install and
            register to FizLink-marked Web Apps and Mobile Apps, register a product,
            complete a survey, or sign up to be on our mailing list. We may ask you to
            submit the following types of Personal Information: first and last name,
            country, email address, product serial number, date of purchase, telephone
            number, mailing address, and proof of purchase. We may also collect additional
            personal information such as your interests or hobbies, your gender or age. ○
            Information you voluntarily share or post. We may collect Personal Information
            that you provide on FizLink.com and FizLink-marked Web Apps and Mobile Apps, or
            post in a public space on our website, such as message boards. ○ Customer
            support. We may collect information through your communications with our
            customer support team or other communications that you may send us and their
            contents. ○ Payment details. We may collect credit card and billing
            information, including payment transactions, to process your order. For
            example, we collect your credit card number when you buy a subscription to use
            some of our Services, for shipping services related to a warranty replacement,
            or when you purchase a license to application from FizLink.</span></p>
        <p className="MsoNormal" style={{marginBottom: '2.0pt', msTextAutospace: 'none'}}><b><span style={{fontSize: '14.0pt', color: 'black'}}>b. Personal Information We Collect From
              Your Interactions With Our Products and Services</span></b></p>
        <p className="MsoNormal" style={{marginBottom: '2.0pt', msTextAutospace: 'none'}}><b><span style={{fontSize: '14.0pt', color: 'black'}}>Cookies and Tracking Technologies. We use
              automatic data collection tools like cookies, web beacons, and other tracking
              technologies in our products to collect data and related information, including
              IP addresses, device identifiers, advertising identifiers and other information
              about your device, system and application software and peripherals. We may
              collect information about your browser and what sites you visit. We might also
              look at how and how often you use one of our applications and certain features.
              Third parties may also collect information using cookies. Some websites have
              “do not track” features that allow you to tell a website not to track you.
              These features are not all uniform. We do not currently respond to those
              signals.&nbsp;</span></b></p>
        <p className="MsoNormal" style={{marginBottom: '2.0pt', msTextAutospace: 'none'}}><b><span style={{fontSize: '14.0pt', color: 'black'}}>Product Usage. We collect information
              related to the use our products and devices and statistics about the
              performance of your FizLink devices. This information includes Internet speed,
              voltage information, storage information, error rates, and other performance
              information.</span></b></p>
        <p className="MsoNormal" style={{marginBottom: '2.0pt', msTextAutospace: 'none'}}><b><span style={{fontSize: '14.0pt', color: 'black'}}>c. Personal Information We Obtain From
              Other Sources</span></b></p>
        <p className="MsoNormal" style={{marginBottom: '2.0pt', msTextAutospace: 'none'}}><b><span style={{fontSize: '14.0pt', color: 'black'}}>Facebook. If you access our Services using
              your Facebook account login, we may receive Personal Information about you such
              as your name, email address, and gender. Any Personal Information that we
              collect from your Facebook account may depend on your Facebook account’s
              privacy settings.</span></b></p>
        <p className="MsoNormal" style={{marginBottom: '2.0pt', msTextAutospace: 'none'}}><b><span style={{fontSize: '14.0pt', color: 'black'}}>Third party Personal Information. If you
              choose to provide us with a third party's Personal Information (such as name, email,
              and phone number), you must ensure that you have the third party's permission
              to do so. Examples include providing us with the names and images that you
              associate with account users, forwarding reference or marketing material to a
              friend, or sending job referrals.</span></b></p>
        <p className="MsoNormal" style={{marginBottom: '2.0pt', msTextAutospace: 'none'}}><b><span style={{fontSize: '14.0pt', color: 'black'}}>3. How We May Use Your Personal
              Information</span></b></p>
        <p className="MsoNormal" style={{msTextAutospace: 'none'}}><span style={{color: 'black'}}>We may
            use your Personal Information for the following purposes:</span></p>
        <p className="MsoNormal" style={{marginBottom: '2.0pt', msTextAutospace: 'none'}}><b><span style={{fontSize: '14.0pt', color: 'black'}}>a. Provide you with the Service and
              communicate with you. We will use your Personal Information to deliver our
              Services and to interact with you directly.</span></b></p>
        <p className="MsoNormal" style={{marginBottom: '2.0pt', msTextAutospace: 'none'}}><b><span style={{fontSize: '14.0pt', color: 'black'}}>b. Maintain, improve and develop products
              and Services. We will use your Personal Information to ensure our Services are
              working as intended and to make improvements to our Services. We will also use
              the information we collect in existing Services to help us develop new ones or
              to customize Services for you.</span></b></p>
        <p className="MsoNormal" style={{marginBottom: '2.0pt', msTextAutospace: 'none'}}><b><span style={{fontSize: '14.0pt', color: 'black'}}>c. Internal data analyses. We use Personal
              Information for internal analytics to understand how our Services are used.</span></b></p>
        <p className="MsoNormal" style={{marginBottom: '2.0pt', msTextAutospace: 'none'}}><b><span style={{fontSize: '14.0pt', color: 'black'}}>d. Marketing. We may send you marketing
              communications about products, services, offers, programs and promotions
              (including contests, sweepstakes and any other marketing activities) either
              directly from us or from our partners. These might be our own offers or
              products, or offers for third-party products we think you might find
              interesting, but we do not share information with third parties for their
              independent marketing or promotional purposes.</span></b></p>
        <p className="MsoNormal" style={{marginBottom: '2.0pt', msTextAutospace: 'none'}}><b><span style={{fontSize: '14.0pt', color: 'black'}}>e. Protect our company, prevent fraud and
              securing our products. We may use your Personal Information to protect our company,
              our customers, or our websites.</span></b></p>
        <p className="MsoNormal" style={{marginBottom: '2.0pt', msTextAutospace: 'none'}}><b><span style={{fontSize: '14.0pt', color: 'black'}}>f. Compliance with law and other
              standards. We may use your Personal Information as required by applicable laws
              and regulations or requested by any judicial process or governmental agency
              having or claiming jurisdiction over FizLink or its affiliates.</span></b></p>
        <p className="MsoNormal" style={{marginBottom: '2.0pt', msTextAutospace: 'none'}}><b><span style={{fontSize: '14.0pt', color: 'black'}}>g. Other activities. We may process your
              Personal Information for other purposes for which we provide specific notice at
              the time of collection.</span></b></p>
        <p className="MsoNormal" style={{marginBottom: '2.0pt', msTextAutospace: 'none'}}><b><span style={{fontSize: '14.0pt', color: 'black'}}>4. How We Share Your Personal Information</span></b></p>
        <p className="MsoNormal" style={{msTextAutospace: 'none'}}><span style={{color: 'black'}}>We may
            share Personal Information in the following ways or as otherwise disclosed to
            you at the time of data collection:</span></p>
        <p className="MsoNormal" style={{marginBottom: '2.0pt', msTextAutospace: 'none'}}><b><span style={{fontSize: '14.0pt', color: 'black'}}>a. Within the FizLink family of companies
              worldwide. We may share the Personal Information we collect with our
              headquarters and affiliates.</span></b></p>
        <p className="MsoNormal" style={{marginBottom: '2.0pt', msTextAutospace: 'none'}}><b><span style={{fontSize: '14.0pt', color: 'black'}}>b. With service providers. We also may
              share Personal Information with our service providers who perform services on
              our behalf based on our instructions and in compliance with appropriate
              confidentiality and security measures. Examples of services include: processing
              of orders and credit card transactions, sending marketing communications,
              sharing your email address to assist us with sales-related efforts or
              post-sales support, and providing customer support.</span></b></p>
        <p className="MsoNormal" style={{marginBottom: '2.0pt', msTextAutospace: 'none'}}><b><span style={{fontSize: '14.0pt', color: 'black'}}>c. With friends you invite. You can decide
              to share your FizLink experience by sending an invitation link to friends.</span></b></p>
        <p className="MsoNormal" style={{marginBottom: '2.0pt', msTextAutospace: 'none'}}><b><span style={{fontSize: '14.0pt', color: 'black'}}>d. We may share information with any
              successor to all or part of our business. We may sell, transfer or otherwise
              share some or all of its assets, including Personal Information, in connection
              with a merger, acquisition, reorganization, sale of assets, spin off of assets
              into a separate business, or similar transaction, or in the event of insolvency
              or bankruptcy. Should such an event occur, we will use reasonable efforts to
              direct the transferee to use Personal Information you have provided to us in a
              manner that is consistent with this Privacy Policy.</span></b></p>
        <p className="MsoNormal" style={{marginBottom: '2.0pt', msTextAutospace: 'none'}}><b><span style={{fontSize: '14.0pt', color: 'black'}}>e. We may share information for legal
              reasons. We will share Personal Information with companies, organizations or
              individuals outside of FizLink if we have a good faith belief that access, use,
              preservation or disclosure of the information is reasonably necessary to:</span></b></p>
        <p className="MsoNormal" style={{msTextAutospace: 'none'}}><span style={{color: 'black'}}>○ meet
            any applicable law, regulation, legal process or enforceable governmental
            request ○ enforce applicable Terms of Use, including investigation of potential
            violations ○ detect, prevent, or otherwise address fraud, security or technical
            issues or ○ protect against harm to the rights, property or safety of FizLink,
            our users or the public as required or permitted by law.</span></p>
        <p className="MsoNormal" style={{marginBottom: '2.0pt', msTextAutospace: 'none'}}><b><span style={{fontSize: '14.0pt', color: 'black'}}>f. Others. If we otherwise notify you and
              you consent to the sharing.</span></b></p>
        <p className="MsoNormal" style={{marginBottom: '2.0pt', msTextAutospace: 'none'}}><b><span style={{fontSize: '14.0pt', color: 'black'}}>5. Your Rights and Choices</span></b></p>
        <p className="MsoNormal" style={{msTextAutospace: 'none'}}><span style={{color: 'black'}}>You
            have certain rights regarding the Personal Information we maintain about you
            and certain choices about what Personal Information we collect from you, how we
            use it, and how we communicate with you.</span></p>
        <p className="MsoNormal" style={{marginBottom: '2.0pt', msTextAutospace: 'none'}}><b><span style={{fontSize: '14.0pt', color: 'black'}}>a. You can opt out of receiving our
              marketing and promotional emails. To stop receiving our marketing and
              promotional emails, follow the instructions in any marketing email you get from
              us. Even if you opt-out of getting marketing and promotional emails, we will
              still be sure to send you important transactional messages. For example, we may
              still contact you about your account or orders, you will also continue to
              receive critical technical and security information about your FizLink
              products, and FizLink will continue to send you notifications depending on the
              system settings that you implement.</span></b></p>
        <p className="MsoNormal" style={{marginBottom: '2.0pt', msTextAutospace: 'none'}}><b><span style={{fontSize: '14.0pt', color: 'black'}}>b. You can update or delete your account.
              You can contact us at any time using the contact information provided below in
              the “How to Contact Us” section to update your Personal Information or delete
              your account. However, if you delete your account, you may not be able to fully
              use the Service.</span></b></p>
        <p className="MsoNormal" style={{marginBottom: '2.0pt', msTextAutospace: 'none'}}><b><span style={{fontSize: '14.0pt', color: 'black'}}>To update your preferences, ask us to
              remove your information from our mailing lists, delete your account or submit a
              request to exercise your rights under applicable law, please contact us as
              specified in the “How to Contact Us” section below.</span></b></p>
        <p className="MsoNormal" style={{marginBottom: '2.0pt', msTextAutospace: 'none'}}><b><span style={{fontSize: '14.0pt', color: 'black'}}>6. Data Transfers</span></b></p>
        <p className="MsoNormal" style={{msTextAutospace: 'none'}}><span style={{color: 'black'}}>FizLink
            is a global business. We may transfer your Personal Information to recipients
            in countries other than your country, including Canada, where we are
            headquartered. These countries may not have the same data protection laws as
            the country in which you initially provided the information. When we transfer
            your Personal Information to other countries, we will protect that information
            as described in this Privacy Policy or as disclosed to you at the time of data
            collection. By using our websites and solutions or providing any Personal
            Information, where applicable law permits, you agree to the transfers, processing,
            and storage of your Personal Information outside of your country of residence.</span></p>
        <p className="MsoNormal" style={{marginBottom: '2.0pt', msTextAutospace: 'none'}}><b><span style={{fontSize: '14.0pt', color: 'black'}}>7. Individuals Located in the EEA or
              Switzerland</span></b></p>
        <p className="MsoNormal" style={{msTextAutospace: 'none'}}><span style={{color: 'black'}}>If you
            are located in the European Economic Area (“EEA”), we process your Personal
            Information for the purposes listed under Section 3 above based on the
            following legal grounds:</span></p>
        <p className="MsoNormal" style={{marginBottom: '2.0pt', msTextAutospace: 'none'}}><b><span style={{fontSize: '14.0pt', color: 'black'}}>a. You have consented to the use of your
              Personal Information. For example, we may seek to obtain your consent for our
              uses of cookies when you visit our website, or to send you marketing communications.</span></b></p>
        <p className="MsoNormal" style={{marginBottom: '2.0pt', msTextAutospace: 'none'}}><b><span style={{fontSize: '14.0pt', color: 'black'}}>b.&nbsp;We need your Personal Information
              to provide you with Products and Services requested by you, or to respond to
              your inquiries.</span></b></p>
        <p className="MsoNormal" style={{marginBottom: '2.0pt', msTextAutospace: 'none'}}><b><span style={{fontSize: '14.0pt', color: 'black'}}>c.&nbsp;We have a legal obligation to use
              or disclose your Personal Information, for example, if we are responding to a
              legal process or an enforceable governmental request.</span></b></p>
        <p className="MsoNormal" style={{marginBottom: '2.0pt', msTextAutospace: 'none'}}><b><span style={{fontSize: '14.0pt', color: 'black'}}>d.&nbsp;We (or a third party) have a
              legitimate interest in using your Personal Information. In particular, we have
              a legitimate interest in using your Personal Information for things like:</span></b></p>
        <p className="MsoNormal" style={{msTextAutospace: 'none'}}><span style={{color: 'black'}}>○
            Providing, maintaining, and improving our Services to meet the needs of our
            users ○ Developing new products and features that are useful for our users ○
            Understanding how people use our Services to ensure and improve the performance
            of our Services ○ Customizing our Services to provide you with a better user
            experience ○ Marketing to inform existing customers about our Services ○
            Detecting, preventing, or otherwise addressing fraud, abuse, security, or
            technical issues with our Services ○ Protecting against harm to the rights,
            property or safety of FizLink, our users, or the public as required or
            permitted by law ○ Performing research that improves our services for our users
            and benefits the public ○ Fulfilling obligations to our partners like
            developers and rights holders; and ○ Enforcing legal claims, including
            investigation of potential violations of applicable Terms of Use. You have the
            right to request access and receive information about the Personal Information
            we maintain about you, to update and correct inaccuracies in your Personal
            Information, to restrict or object to the processing of your Personal
            Information, to have the information blocked or deleted, as appropriate, or to
            exercise your right to data portability to easily transfer your Personal
            Information to another company. Those rights may be limited in some
            circumstances by local law requirements. In addition to the above-mentioned
            rights, you also have the right to lodge a complaint with a competent
            supervisory authority subject to applicable law. In addition, if we rely on
            consent for the processing of your Personal Information, you have the right to
            withdraw it at any time and free of charge. When you do so, this will not
            affect the lawfulness of the processing before your consent withdrawal. FizLink
            Inc. &nbsp;is the entity responsible for the processing of your Personal
            Information, and you may exercise your rights to your Personal Information by
            emailing us at: privacy.policy@FizLink.com with the subject: PRIVACY POLICY</span></p>
        <p className="MsoNormal" style={{marginBottom: '2.0pt', msTextAutospace: 'none'}}><b><span style={{fontSize: '14.0pt', color: 'black'}}>8. Children’s Privacy&nbsp;</span></b></p>
        <p className="MsoNormal" style={{msTextAutospace: 'none'}}><span style={{color: 'black'}}>Our
            Products and Services are meant for adults. We do not knowingly allow children
            under 18 to set up FizLink, FizLink accounts, or use FizLink experiences. If
            you believe that we may have collected Personal Information from someone under 18,
            please let us know using the methods described in the Contact Us section and we
            will take appropriate measures to investigate and address the issue promptly.</span></p>
        <p className="MsoNormal" style={{marginBottom: '2.0pt', msTextAutospace: 'none'}}><b><span style={{fontSize: '14.0pt', color: 'black'}}>9. How We Protect Your Personal
              Information&nbsp;</span></b></p>
        <p className="MsoNormal" style={{msTextAutospace: 'none'}}><span style={{color: 'black'}}>We
            intend to protect the Personal Information entrusted to us and treat it
            securely in accordance with this Privacy Policy. We maintain administrative,
            technical and physical safeguards to protect Personal Information against
            accidental or unlawful destruction, accidental loss, unauthorized alteration,
            unauthorized disclosure or access, misuse, and any other unlawful form of
            processing of the Personal Information in our possession. We also contractually
            require that our suppliers protect such information from unauthorized access,
            use, and disclosure. The Internet is not 100% secure. We cannot promise that
            your use of our sites will be completely safe. We encourage you to use caution
            when using the Internet. This includes not sharing your passwords. If you
            participate in a discussion forum, local communities, or chat room on an FizLink
            website, you should be aware that the information you provide there (i.e. your
            public profile) will be made broadly available to others, and could be used to
            contact you, send you unsolicited messages, or for purposes neither FizLink nor
            you have control over. Also, please recognize that individual forums and chat
            rooms may have additional rules and conditions. FizLink is not responsible for
            the Personal Information or any other information you choose to submit in these
            forums. In some cases, we may not be able to remove your Personal Information,
            in which case we will let you know if we are unable to do so and why. Retention
            of Personal Information We also take measures to delete your Personal
            Information or keep it in a form that does not permit identifying you when this
            information is no longer necessary for the purposes for which we process it or
            when you request its deletion, unless we are allowed or required by law to keep
            the information for a longer period. We complete periodic reviews of our
            databases, and have established time limits for certain data deletion, taking
            into account the type of services provided in the context of the Products and
            Services, the length of our relationship with you, mandatory retention periods,
            and the statute of limitations.</span></p>
        <p className="MsoNormal" style={{marginBottom: '2.0pt', msTextAutospace: 'none'}}><b><span style={{fontSize: '14.0pt', color: 'black'}}>10. Third Party Services&nbsp;</span></b></p>
        <p className="MsoNormal" style={{msTextAutospace: 'none'}}><span style={{color: 'black'}}>The
            Service may contain features or links to websites and services provided by
            third parties. If you click on a link to a third party site, you will be taken
            to websites we do not control. Any information you provide on third-party sites
            or services may be provided directly to the operators of such services and is
            subject to those operators’ policies, if any, governing privacy and security,
            even if accessed through the Service. This policy does not apply to the privacy
            practices of that website. Read the privacy policy of other websites carefully.
            We are not responsible for these third party sites or services to which links
            or access are provided through the Service.</span></p>
        <p className="MsoNormal" style={{marginBottom: '2.0pt', msTextAutospace: 'none'}}><b><span style={{fontSize: '14.0pt', color: 'black'}}>11. Results and Data</span></b></p>
        <p className="MsoNormal"><span style={{color: 'black'}}>FizLink leverages crowd sourced
            data and is not responsible and shall not be held liable for any inaccuracies
            to the collected data and results. Geo-location is mapped based on a
            combination of GPS and or IP address and in some cases the use of mobile
            networks or VPN could affect the accuracy of the results. Results and data are
            regularly backed up, but FizLink will not be liable for and lost data or errors
            that occur to collected data. FizLink will not be held liable for any data that
            is maliciously taken from our databases and end user will not pursue or seek
            remedies for such acts. </span></p>
        <p className="MsoNormal"><b><span style={{fontSize: '14.0pt', color: 'black'}}>12.
              Content&nbsp;</span></b></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>Our Service
              allows you to post, link, store, share and otherwise make available certain
              information, text, graphics, videos, or other material ("Content"). <b>You
                are responsible for the Content that you post to the Service, including its
                legality, reliability, and appropriateness.</b></span></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span style={{color: 'black'}}>By
            posting Content to the Service, you grant us the right and license to use,
            modify, publicly perform, publicly display, reproduce, and distribute such
            Content on and through the Service. You retain any and all of your rights to
            any Content you submit, post or display on or through the Service and you are
            responsible for protecting those rights. You agree that this license includes
            the right for us to make your Content available to other users of the Service,
            who may also use your Content subject to these Terms.You represent and warrant
            that: (i) the Content is yours (you own it) or you have the right to use it and
            grant us the rights and license as provided in these Terms, and (ii) the
            posting of your Content on or through the Service does not violate the privacy
            rights, publicity rights, copyrights, contract rights or any other rights of
            any person. Further, you warrant that: (i) the Content will not cause you or us
            to breach any law, regulation, rule, code or other legal obligation; (ii) the
            Content will not or could not be reasonably considered to be obscene,
            inappropriate, defamatory, disparaging, indecent, seditious, offensive,
            pornographic, threatening, abusive, liable to incite racial hatred,
            discriminatory, blasphemous, in breach of confidence or in breach of privacy;
            (iii) the Content will not be unsolicited, undisclosed or unauthorised
            advertising; (iv) the Content does not contain software viruses or any other
            computer code, files, or programs designed to interrupt, destroy, or limit the
            functionality of any computer software, hardware or telecommunications
            equipment; and (v): the Content does not bring us or the Service into
            disrepute.You agree to keep all records necessary to establish that your
            Content does not violate any of the requirements this clause and make such
            records available upon our reasonable request.</span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>We are under
              no obligation to regularly monitor the accuracy or reliability of your Content
              incorporated into the Service. We reserve the right to modify or remove any
              Content at any time.</span></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>You
              acknowledge and agree that all Content you provide on the Service will be
              publicly available information and you bear the risks involved with such public
              disclosures.</span></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><b><span style={{fontSize: '14.0pt', color: 'black'}}>13. Accounts</span></b></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>When you
              create an account with us, you must provide us information that is accurate,
              complete, and current at all times. Failure to do so constitutes a breach of
              the Terms, which may result in immediate termination of your account on our
              Service. You are responsible for safeguarding the password that you use to
              access the Service and for any activities or actions under your password,
              whether your password is with our Service or a third-party service.</span></span><span style={{color: 'black'}}> <span className="styleswrappedcontent-sc-1w7ms31-0">You agree
              not to disclose your password to any third party. You agree to be fully
              responsible for activities that relate to your account or your password. You
              must notify us immediately upon becoming aware of any breach of security or
              unauthorized use of your account. You may not use as a username the name of
              another person or entity or that is not lawfully available for use, a name or
              trade mark that is subject to any rights of another person or entity other than
              you without appropriate authorization, or a name that is otherwise offensive,
              vulgar or obscene.</span></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><b><span style={{color: 'black'}}>Additional
                terms for subscription FizLink accounts (Pro)</span></b></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt', textIndent: '36.0pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><b><span style={{color: 'black'}}>Subscriptions</span></b></span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '.0001pt', marginLeft: '36.0pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>Some parts of the Service are billed on a subscription
              basis ("Subscription(s)"). You will be billed in advance on a
              recurring and periodic basis ("Billing Cycle"). Billing cycles are
              set either on a monthly or annual basis, depending on the type of subscription
              plan you select when purchasing a Subscription.</span></span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '.0001pt', marginLeft: '36.0pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>At the end of each Billing Cycle, your Subscription will
              automatically renew under the exact same conditions unless you cancel it or
              Votie Solutions Inc. cancels it. You may cancel your Subscription renewal
              either through your online account management page or by contacting Votie
              Solutions Inc. customer support team.</span></span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '.0001pt', marginLeft: '36.0pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>A valid payment method, including credit card, is required
              to process the payment for your Subscription. You shall provide Votie Solutions
              Inc. with accurate and complete billing information including full name,
              address, state, zip code, and a valid payment method information. By submitting
              such payment information, you automatically authorize Votie Solutions Inc. to
              charge all Subscription fees incurred through your account to any such payment
              instruments.Should automatic billing fail to occur for any reason, Votie
              Solutions Inc. will issue an electronic invoice indicating that you must
              proceed manually, within a certain deadline date, with the full payment
              corresponding to the billing period as indicated on the invoice.</span></span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '.0001pt', marginLeft: '36.0pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><b><span style={{color: 'black'}}>Free Trial</span></b></span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '.0001pt', marginLeft: '36.0pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>Votie Solutions Inc. may, at its sole discretion, offer a
              Subscription with a free trial for a limited period of time ("Free
              Trial").You may be required to enter your billing information in order to
              sign up for the Free Trial.If you do enter your billing information when
              signing up for the Free Trial, you will not be charged by Votie Solutions Inc.
              until the Free Trial has expired. On the last day of the Free Trial period,
              unless you cancelled your Subscription, you will be automatically charged the
              applicable Subscription fees for the type of Subscription you have selected.</span></span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '.0001pt', marginLeft: '36.0pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>At any time and without notice, Votie Solutions Inc.
              reserves the right to (i) modify the terms and conditions of the Free Trial
              offer, or (ii) cancel such Free Trial offer.</span></span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '.0001pt', marginLeft: '36.0pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><b><span style={{color: 'black'}}>Fee Changes</span></b></span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '.0001pt', marginLeft: '36.0pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>Votie Solutions Inc., in its sole discretion and at any
              time, may modify the Subscription fees for the Subscriptions. Any Subscription
              fee change will become effective at the end of the then-current Billing Cycle.</span></span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '.0001pt', marginLeft: '36.0pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>Votie Solutions Inc. will provide you with a reasonable
              prior notice of any change in Subscription fees to give you an opportunity to
              terminate your Subscription before such change becomes effective.</span></span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '.0001pt', marginLeft: '36.0pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>Your continued use of the Service after the Subscription
              fee change comes into effect constitutes your agreement to pay the modified
              Subscription fee amount.</span></span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '.0001pt', marginLeft: '36.0pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><b><span style={{color: 'black'}}>Refunds</span></b></span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '.0001pt', marginLeft: '36.0pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>Except when required by law, paid Subscription fees are
              non-refundable.</span></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><b><span style={{fontSize: '14.0pt', color: 'black'}}>14. IP (Intellectual Property)</span></b></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>The Service
              and its original content (excluding Content provided by users), features and
              functionality are and will remain the exclusive property of Votie Solutions
              Inc. and its licensors. The Service is protected by copyright, trademark, and
              other laws of both Canada and foreign countries. Our trademarks and trade dress
              may not be used in connection with any product or service without the prior
              written consent of Votie Solutions Inc.. Nothing in these Terms constitutes a
              transfer of any Intellectual Property rights from us to you.</span></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>You are
              permitted to use the Service only as authorised by us. As a user, you are granted
              a limited, non-exclusive, revocable, non-transferable right to use the Service
              to create, display, use, play, and download Content subject to these Terms.</span></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>Our
              Intellectual Property must not be used in connection with a product or service
              that is not affiliated with us or in any way brings us in disrepute.</span></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>You must not
              modify the physical or digital copies of any Content you print off or download
              in any way, and you must not use any illustrations, photographs, video or
              audio, or any graphics separately from any accompanying text.</span></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>Any opinions,
              advice, statements, services, offers, or other information or content expressed
              or made available by any other users are those of the respective authors or
              distributors and not of us.</span></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><b><span style={{fontSize: '14.0pt', color: 'black'}}>15. Links To Other Web Sites</span></b></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>Our Service
              may contain links to third-party web sites or services that are not owned or
              controlled by FizLink or Votie Solutions Inc..</span></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><b><span style={{color: 'black'}}>Votie
                Solutions Inc. has no control over, and assumes no responsibility for, the
                content, privacy policies, or practices of any third party web sites or
                services. You further acknowledge and agree that Votie Solutions Inc. shall not
                be responsible or liable, directly or indirectly, for any damage or loss caused
                or alleged to be caused by or in connection with use of or reliance on any such
                content, goods or services available on or through any such web sites or
                services. Fizlink only provides links to external websites as a convenience,
                and the inclusion of such a link to external websites do not imply our
                endorsement of those websites. You acknowledge and agree that when you access
                other websites on the Internet, you do so at your own risk.We strongly advise
                you to read the terms and conditions and privacy policies of any third-party
                web sites or services that you visit.</span></b></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><b><span style={{fontSize: '14.0pt', color: 'black'}}>16. Termination</span></b></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>We may
              terminate or suspend your account immediately, without prior notice or
              liability, for any reason whatsoever, including without limitation if you
              breach the Terms.</span></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>Upon
              termination, your right to use the Service will immediately cease. If you wish
              to terminate your account, you may simply discontinue using the Service.</span></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>All
              provisions of the Terms which by their nature should survive termination shall
              survive termination, including, without limitation, ownership provisions,
              warranty disclaimers, indemnity and limitations of liability.</span></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>We shall not
              be liable to you or any third party for any claims or damages arising out of
              any termination or suspension or any other actions taken by us in connection
              therewith.</span></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>If applicable
              law requires us to provide notice of termination or cancellation, we may give
              prior or subsequent notice by posting it on the Service or by sending a communication
              to any address (email or otherwise) that we have for you in our records.</span></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><b><span style={{fontSize: '14.0pt', color: 'black'}}>17. Indemnification</span></b></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>As a
              condition of your access to and use of the Service, you agree to indemnify us
              and our successors and assigns for all damages, costs, expenses and other
              liabilities, including but not limited to legal fees and expenses, relating to
              any claim arising out of or related to your access to and use of the Service or
              your breach of these Terms and any applicable law or the rights of another
              person or party.</span></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>This
              indemnification section survives the expiration of your registration, and
              applies to claims arising both before and after the registration ends.</span></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><b><span style={{fontSize: '14.0pt', color: 'black'}}>18. Limitation Of Liability</span></b></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>You agree
              that we shall not be liable for any damages suffered as a result of using the
              Service, copying, distributing, or downloading Content from the Service.</span></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>In no event
              shall we be liable for any indirect, punitive, special, incidental or
              consequential damage (including loss of business, revenue, profits, use,
              privacy, data, goodwill or other economic advantage) however it arises, whether
              for breach of contract or in tort, even if it has been previously advised of
              the possibility of such damage.</span></span><span style={{color: 'black'}}> <span className="styleswrappedcontent-sc-1w7ms31-0">You have sole responsibility for
              adequate security protection and backup of data and/or equipment used in
              connection with your usage of the Service and will not make a claim against for
              lost data, re-run time, inaccurate instruction, work delays or lost profits
              resulting from the use of the Service. You must not assign or otherwise dispose
              of your account to any other person. Without limiting the foregoing, in no
              event will our aggregate liability to you exceed, in total, the amounts paid by
              you to us.</span></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><b><span style={{fontSize: '14.0pt', color: 'black'}}>19. Disclaimer</span></b></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>Your use of
              the Service is at your sole risk. The Service is provided on an "AS
              IS" and "AS AVAILABLE" basis. The Service is provided without
              warranties of any kind, whether express or implied, including, but not limited
              to, implied warranties of merchantability, fitness for a particular purpose,
              non-infringement or course of performance.</span></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>Votie
              Solutions Inc. its subsidiaries, affiliates, and its licensors do not warrant
              that a) the Service will function uninterrupted, secure or available at any
              particular time or location; b) any errors or defects will be corrected; c) the
              Service is free of viruses or other harmful components; or d) the results of
              using the Service will meet your requirements.</span></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>This
              disclaimer of liability applies to any damages or injury caused by any failure
              of performance, error, omission, interruption, deletion, defect, delay in
              operation or transmission, computer virus, communication line failure, theft,
              or destruction or unauthorized access or, alteration of or use of record in
              connection with the use or operation of the Service, whether for breach of contract,
              tortious behaviour, negligence or any other cause of action.</span></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>We make no
              representations or warranties of any kind, express or implied, about the
              completeness, accuracy, reliability, suitability or availability with respect
              to the content contained on the Service for any purpose. Any reliance you place
              on such information is therefore strictly at your own risk. We disclaim any
              express or implied warranty representation or guarantee as to the effectiveness
              or profitability of the Service or that the operation of our Service will be
              uninterrupted or error-free. We are not liable for the consequences of any
              interruptions or error in the Service.</span></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><b><span style={{fontSize: '14.0pt', color: 'black'}}>20. Exclusions</span></b></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>Some
              jurisdictions do not allow the exclusion of certain warranties or the exclusion
              or limitation of liability for consequential or incidental damages, so the
              limitations above may not apply to you.</span></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><b><span style={{fontSize: '14.0pt', color: 'black'}}>21. Governing Law</span></b></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>These Terms
              shall be governed and construed in accordance with the laws of Quebec, Canada,
              without regard to its conflict of law provisions.</span></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>Our failure
              to enforce any right or provision of these Terms will not be considered a
              waiver of those rights. If any provision of these Terms is held to be invalid
              or unenforceable by a court, the remaining provisions of these Terms will remain
              in effect. These Terms constitute the entire agreement between us regarding our
              Service, and supersede and replace any prior agreements we might have between
              us regarding the Service.</span></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><b><span style={{fontSize: '14.0pt', color: 'black'}}>22. Changes</span></b></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>We reserve
              the right, at our sole discretion, to modify or replace these Terms at any
              time. If a revision is material we will try to provide at least 15 days notice
              prior to any new terms taking effect.</span></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>It is your
              sole responsibility to periodically check these Terms for any changes. If you
              do not agree with any of the changes to these Terms, it is your sole
              responsibility to stop using the Service. Your continued use of the Service
              will be deemed as your acceptance thereof.</span></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><b><span style={{fontSize: '14.0pt', color: 'black'}}>23. Customer Data</span></b></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>The Customer
              owns the rights to its data as data controller, and the service acts as data
              processor on the Customer's behalf. All processing by the service of the
              personal data and other data provided by the Customer shall be in accordance
              with the applicable laws. The service's processing of personal data on behalf
              of the Customer shall therefore only be done in order to provide the Product
              and shall be subject to the Customer's written instructions.</span></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>The Customer
              is obligated to keep user logins and passwords to the Product secret from any
              unauthorized users or third parties. The Customer is obligated to ensure that
              the personal data provided by the Customer and used in the Product is processed
              by the Customer in accordance with all applicable laws. The Customer is
              obligated to ensure that the Customer's data provided in the Product, including
              personal data, do not violate any third party intellectual property rights
              and/or any applicable legislation.</span></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>The service
              is entitled to delete any data that in the sole discretion of the service
              constitutes a breach of the aforesaid undertaking by the Customer, and the
              Customer will not be entitled to any compensation in that respect.</span></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><b><span style={{fontSize: '14.0pt', color: 'black'}}>24. Facebook Pixel Integration Feature</span></b></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>When you
              utilize the Facebook Pixel Integration feature of Fizlink, you are placing your
              Facebook Pixel on your profile and you therefore become the controller of your
              Facebook data, and you are providing this data to Facebook Inc. By using this
              feature you agree to Facebook Inc’s terms and agree that you now are in a data
              controller / data processor relationship with Facebook Inc.</span></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><b><span style={{fontSize: '14.0pt', color: 'black'}}>25. Data Location</span></b></span></p>
        <p style={{margin: '0cm', marginBottom: '.0001pt'}}><span className="styleswrappedcontent-sc-1w7ms31-0"><span style={{color: 'black'}}>You agree
              that by using this service, all data held as outlined in the privacy policy and
              here within, as necessary for the product to function is stored and processed
              in The United States of America and is not store within Canada or the European
              Union.</span></span></p>
        <p className="MsoNormal"><b><span style={{fontSize: '14.0pt', color: 'black'}}>26. Update to
              this policy&nbsp;</span></b></p>
        <p className="MsoNormal" style={{msTextAutospace: 'none'}}><span style={{color: 'black'}}>We may
            update this Privacy Policy from time to time. If we modify our Privacy Policy,
            we will post the revised version here, with an updated revision date. We
            recommend that you visit these pages periodically to be aware of and review any
            such revisions. If we make material changes to our Privacy Policy, we may also
            notify you by other means prior to the changes taking effect, such as by
            posting a notice on our websites or sending you a notification.</span></p>
        <p className="MsoNormal" style={{marginBottom: '2.0pt', msTextAutospace: 'none'}}><b><span style={{fontSize: '14.0pt', color: 'black'}}>27. How to Contact Us&nbsp;</span></b></p>
        <p className="MsoNormal"><span style={{color: 'black'}}>If you have any questions,
            comments or complaints about this Privacy Policy and our privacy practices, or
            would like to update your privacy preferences, please contact us
            at:&nbsp;privacy.policy@FizLink.com with the subject: PRIVACY POLICY This FizLink
            Privacy Policy was revised and posted as of March 20, 2020.</span></p>
        <p className="MsoNormal" style={{marginBottom: '2.0pt', msTextAutospace: 'none'}}><b><span style={{fontSize: '14.0pt', color: 'black'}}>28. Warranties</span></b></p>
        <p className="MsoNormal"><span style={{color: 'black'}}>FizLink is provided&nbsp;"AS
            IS" AND ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO,
            THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE
            ARE DISCLAIMED. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR CONTRIBUTORS BE
            LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR
            CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
            GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS INTERRUPTION)
            HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT
            LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT
            OF THE USE OF THIS SOFTWARE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.</span></p>
        <p className="MsoNormal"><span style={{color: 'black'}}>&nbsp;</span></p>
        <div style={{border: 'none', borderBottom: 'solid windowtext 1.0pt', padding: '0cm 0cm 1.0pt 0cm'}}>
          <p className="MsoNormal" style={{border: 'none', padding: '0cm'}}><span style={{color: 'black'}}>&nbsp;</span></p>
        </div>
        <p className="MsoNormal"><span style={{color: 'black'}}>&nbsp;</span></p>
        <p className="MsoNormal"><b><span lang="EN-US" style={{fontSize: '17.0pt', color: 'black'}}>&nbsp;</span></b></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{fontSize: '17.0pt', color: 'black'}}>Termes
              et conditions</span></b></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Votie Solutions Inc. et
            FizLink Inc., et ses sociétés affiliées et filiales (collectivement, «FizLink»
            «Votie», «nous» «notre» ou «notre») prennent votre vie privée au sérieux. Les
            présentes conditions générales décrivent comment vous êtes lié par ces
            conditions en utilisant la plate-forme exploitée par Votie Solutions Inc. et
            notre politique de confidentialité et comment nous recueillons, utilisons,
            divulguons, transférons et traitons vos informations personnelles. Il vous
            informe également de vos droits concernant vos informations personnelles et de
            la manière dont vous pouvez nous contacter pour les mettre à jour ou obtenir
            des réponses aux questions que vous pourriez avoir sur nos pratiques de
            confidentialité. En continuant à utiliser FizLink, vous acceptez les conditions
            générales suivantes et vous êtes lié par ce qui suit: • Votre accès et votre
            utilisation du Service sont conditionnés par votre acceptation et votre respect
            de ces Conditions. Ces conditions s'appliquent à tous les visiteurs,
            utilisateurs et autres personnes qui accèdent ou utilisent le service. Vous
            garantissez que vous avez au moins 18 ans et que vous êtes légalement capable
            de conclure des contrats contraignants. Si vous avez moins de 18 ans, vous
            garantissez que vous avez obtenu le consentement de vos parents ou tuteurs et
            qu'ils acceptent d'être liés par ces Conditions en votre nom.</span></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>En accédant ou en
            utilisant le Service, vous acceptez d'être lié par ces Conditions. Si vous
            n'êtes pas d'accord avec une partie des conditions, vous ne pouvez pas accéder
            au Service.</span></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{fontSize: '14.0pt', color: 'black'}}>1.
              Portée</span></b></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Cette politique de
            confidentialité couvre le traitement des informations personnelles liées aux
            produits et services de FizLink (les «Services), notamment: (1) le ou les sites
            Web auxquels vous pouvez accéder à l'adresse https://www.FizLink.com/ (le« Site
            »), (2) services https://www.goFizLink.com/, https://www.FizLink.cc/,
            https://www.FizLink.ca, y compris les services techniques support et services
            accessibles via le ou les sites («applications Web»), (3) logiciels pouvant
            être téléchargés sur votre smartphone ou votre tablette pour accéder à des
            services («applications mobiles»), et (4) services d'abonnement, y compris les
            services pouvez accéder à l'aide des applications Web et mobiles («services
            d'abonnement»). Des tiers peuvent également intégrer les produits et services
            de FizLink à leurs produits et services et nous ne sommes pas responsables de
            cette intégration. Des informations supplémentaires sur nos pratiques en
            matière de confidentialité peuvent être fournies dans d'autres documents, tels
            que des descriptions d'offre, des déclarations de confidentialité supplémentaires
            ou des notifications fournies avant ou au moment de la collecte des données.</span></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{fontSize: '14.0pt', color: 'black'}}>2.
              Informations personnelles que nous pouvons collecter</span></b></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Nous obtenons des
            informations personnelles vous concernant de différentes sources, telles que
            décrites ci-dessous. Aux fins de la présente politique de confidentialité, on
            entend par «informations personnelles» les informations qui vous identifient
            personnellement, telles que votre nom, votre âge, votre numéro de téléphone,
            votre sexe, votre adresse e-mail ou vos informations de facturation, ou toute
            autre donnée pouvant être raisonnablement utilisée pour déduire ces
            informations. Le cas échéant, nous indiquons si et pourquoi vous devez nous
            fournir vos informations personnelles, ainsi que les conséquences de ne pas le
            faire. Si vous ne fournissez pas d'informations personnelles à la demande, vous
            ne pourrez peut-être pas bénéficier de nos services si ces informations sont
            nécessaires pour vous fournir le service.</span></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{fontSize: '14.0pt', color: 'black'}}>a.
              Informations personnelles que nous recueillons directement auprès de vous</span></b></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>○ Enregistrement. Pour
            utiliser les services, nous pouvons vous demander de fournir certaines
            informations personnelles lorsque vous créez un compte sur FizLink.com ou que
            vous installez et vous enregistrez sur le Web Apps et/ou applications mobiles
            marquées FizLink, enregistrez un produit, remplissez un sondage ou vous
            inscrire à notre liste de diffusion. Nous pouvons vous demander de fournir les
            types d'informations personnelles suivants: nom et prénom, pays, âge, sexe,
            adresse électronique, numéro de série du produit, date d'achat, numéro de
            téléphone, adresse postale et preuve d'achat. Nous pouvons également collecter
            des informations personnelles supplémentaires telles que vos intérêts ou vos
            passe-temps, votre sexe ou votre âge. ○ Informations que vous partagez ou
            publiez volontairement. Nous pouvons collecter les informations personnelles
            que vous avez fournies sur les applications Web et mobiles Mobile FizLinks.io
            et FizLink-marquées, ou les publier dans un espace public de notre site Web,
            tel que des forums de discussion. ○ Support client. Nous pouvons collecter des
            informations par le biais de vos communications avec notre équipe d'assistance
            clientèle ou d'autres communications que vous pouvez nous envoyer et leur
            contenu. ○ Détails du paiement. Nous pouvons collecter des informations de
            carte de crédit et de facturation, y compris des transactions de paiement, pour
            traiter votre commande. Par exemple, nous recueillons votre numéro de carte de
            crédit lorsque vous souscrivez un abonnement pour utiliser certains de nos
            Services, pour des services d’expédition liés à un remplacement sous garantie
            ou lorsque vous achetez une licence pour une application de FizLink.</span></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{fontSize: '14.0pt', color: 'black'}}>b.
              Informations personnelles collectées lors de vos interactions avec nos produits
              et services</span></b></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{color: 'black'}}>Témoins (cookies) et
              technologies de suivi. Nous utilisons des outils de collecte automatique de
              données tels que des témoins (cookies), des balises Web et d'autres
              technologies de suivi dans nos produits pour collecter des données et des
              informations connexes, notamment des adresses IP, des identifiants d'appareil,
              des identifiants de publicité et d'autres informations relatives à votre
              appareil, système, logiciel d'application et périphériques. Nous pouvons
              collecter des informations sur votre navigateur et sur les sites que vous
              visitez. Nous pourrions également regarder comment et à quelle fréquence vous
              utilisez l’une de nos applications et certaines fonctionnalités. Des tiers
              peuvent également collecter des informations à l'aide de témoins (cookies).
              Certains sites Web ont des fonctionnalités «ne pas suivre» qui vous permettent
              d'indiquer à un site Web de ne pas vous suivre. Ces caractéristiques ne sont pas
              toutes uniformes. Nous ne répondons pas actuellement à ces signaux.</span></b></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{color: 'black'}}>Utilisation du
              produit. Nous recueillons des informations relatives à l'utilisation de nos
              produits et appareils ainsi que des statistiques sur les performances de vos
              appareils FizLink. Ces informations incluent la vitesse Internet, les
              informations de tension, les informations de stockage, les taux d'erreur et
              d'autres informations sur les performances.</span></b></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{color: 'black'}}>c. Informations
              personnelles que nous obtenons d'autres sources</span></b></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{color: 'black'}}>Facebook. Si vous
              accédez à nos services en utilisant votre identifiant de compte Facebook, nous
              pouvons recevoir des informations personnelles vous concernant, telles que
              votre nom, votre adresse électronique et votre sexe. Toutes les informations
              personnelles que nous recueillons à partir de votre compte Facebook peuvent
              dépendre des paramètres de confidentialité de votre compte Facebook.</span></b></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{color: 'black'}}>Informations
              personnelles de tiers. Si vous choisissez de nous fournir les informations
              personnelles d'un tiers (telles que son nom, son adresse électronique et son
              numéro de téléphone), vous devez vous assurer que vous disposez de
              l'autorisation de ce tiers. Par exemple, nous fournissons les noms et les
              images que vous associez aux utilisateurs du compte, vous transmettez du
              matériel de référence ou de marketing à un ami ou vous envoyez des parrainages.</span></b></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{fontSize: '14.0pt', color: 'black'}}>3.
              Comment nous pouvons utiliser vos informations personnelles</span></b></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{color: 'black'}}>Nous pouvons
              utiliser vos informations personnelles aux fins suivantes:</span></b></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{color: 'black'}}>a. Vous fournir le
              service et communiquer avec vous. Nous utiliserons vos informations
              personnelles pour fournir nos services et pour interagir directement avec vous.</span></b></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{color: 'black'}}>b. Maintenir,
              améliorer et développer des produits et services. Nous utiliserons vos
              informations personnelles pour nous assurer que nos services fonctionnent comme
              prévu et pour améliorer nos services. Nous utiliserons également les
              informations que nous collectons dans les services existants pour nous aider à
              en développer de nouveaux ou à personnaliser les services pour vous.</span></b></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{color: 'black'}}>c. Analyses de
              données internes. Nous utilisons les informations personnelles pour les
              analyses internes afin de comprendre comment nos services sont utilisés.</span></b></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{color: 'black'}}>d.
              Commercialisation. Nous pouvons vous envoyer des communications marketing sur
              des produits, services, offres, programmes et promotions (y compris des
              concours, des loteries et toute autre activité marketing) directement de nous
              ou de nos partenaires. Celles-ci peuvent être nos propres offres ou produits,
              ou des offres de produits tiers que nous pensons susceptibles de vous
              intéresser, mais nous ne partageons aucune information avec des tiers à des
              fins de marketing ou de promotion indépendantes.</span></b></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{color: 'black'}}>e. Protégez notre
              entreprise, évitez la fraude et sécurisez nos produits. Nous pouvons utiliser
              vos informations personnelles pour protéger notre société, nos clients ou nos
              sites Web.</span></b></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{color: 'black'}}>F. Conformité à la
              loi et aux autres normes. Nous pouvons utiliser vos informations personnelles
              conformément aux lois et réglementations en vigueur ou à la demande de tout
              processus judiciaire ou de toute agence gouvernementale ayant ou se déclarant
              compétente à l'égard de FizLink ou de ses sociétés affiliées.</span></b></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{color: 'black'}}>g. D'autres
              activités. Nous pouvons traiter vos informations personnelles à d'autres fins
              pour lesquelles nous fournissons un avis spécifique au moment de la collecte.</span></b></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{fontSize: '14.0pt', color: 'black'}}>4.
              Comment nous partageons vos informations personnelles</span></b></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Nous pouvons partager
            des informations personnelles de la manière suivante ou de toute autre manière
            qui vous a été divulguée au moment de la collecte des données:</span></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{color: 'black'}}>a. Au sein de la
              famille d'entreprises FizLink à travers le monde. Nous pouvons partager les
              informations personnelles que nous recueillons avec notre siège social et nos
              sociétés affiliées.</span></b></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{color: 'black'}}>b. Avec les
              fournisseurs de services. Nous pouvons également partager des informations
              personnelles avec nos fournisseurs de services qui fournissent des services en
              notre nom, conformément à nos instructions et dans le respect des mesures de
              confidentialité et de sécurité appropriées. Les exemples de services incluent:
              le traitement des commandes et des transactions par carte de crédit, l'envoi de
              communications marketing, le partage de votre adresse e-mail pour nous aider
              dans les efforts liés aux ventes ou le support après-vente et la fourniture
              d'un support client.</span></b></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{color: 'black'}}>c. Avec des amis que
              vous invitez. Vous pouvez décider de partager votre expérience FizLink en
              envoyant un lien d'invitation à des amis.</span></b></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{color: 'black'}}>d. Nous pouvons
              partager des informations avec tout successeur de tout ou partie de notre
              entreprise. Nous pouvons vendre, transférer ou partager de quelque manière que
              ce soit tout ou partie de ses actifs, y compris des informations personnelles,
              dans le cadre d'une fusion, d'une acquisition, d'une restructuration, de la
              vente d'actifs, d'une cession d'actifs à une entreprise distincte ou d'une
              transaction similaire, ou en cas de: d'insolvabilité ou de faillite. Si un tel
              événement se produisait, nous déploierons des efforts raisonnables pour inciter
              le cessionnaire à utiliser les informations personnelles que vous nous avez
              fournies de manière conforme à la présente politique de confidentialité.</span></b></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{color: 'black'}}>e. Nous pouvons
              partager des informations pour des raisons juridiques. Nous partagerons les
              informations personnelles avec des sociétés, des organisations ou des personnes
              extérieures à FizLink si nous croyons de bonne foi que l'accès, l'utilisation,
              la conservation ou la divulgation de l'information est raisonnablement
              nécessaire pour:</span></b></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>○ respectez toutes les
            lois, réglementations, procédures judiciaires ou demandes gouvernementales
            contraignantes applicables ○ appliquez les conditions d'utilisation
            applicables, y compris les enquêtes sur les violations potentielles ○ détectez,
            empêchez ou corrigez les problèmes de fraude, de sécurité ou techniques ou ○
            protégez contre les atteintes aux droits, à la propriété ou la sécurité de FizLink,
            de nos utilisateurs ou du public, tel que requis ou autorisé par la loi.</span></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{color: 'black'}}>f. Autres. Si nous
              vous en avisons autrement et que vous consentez au partage.</span></b></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{fontSize: '14.0pt', color: 'black'}}>5.
              Vos droits et vos choix</span></b></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Vous disposez de
            certains droits concernant les informations personnelles que nous conservons à
            votre sujet et de certains choix concernant les informations personnelles que
            nous recueillons de vous, comment nous les utilisons et comment nous
            communiquons avec vous.</span></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{color: 'black'}}>a. Vous pouvez
              choisir de ne plus recevoir nos courriels marketing et promotionnels. Pour ne
              plus recevoir nos e-mails marketing et promotionnels, suivez les instructions
              qui figurent dans chaque e-mail marketing que vous recevez de notre part. Même
              si vous décidez de ne pas recevoir d'e-mails marketing et promotionnels, nous
              ne manquerons pas de vous envoyer des messages transactionnels importants. Par
              exemple, nous pouvons toujours vous contacter à propos de votre compte ou de
              vos commandes, vous continuerez également à recevoir des informations
              techniques et de sécurité essentielles sur vos produits FizLink, et FizLink
              continuera à vous envoyer des notifications en fonction des paramètres système
              que vous avez mis en œuvre.</span></b></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{color: 'black'}}>b. Vous pouvez
              mettre à jour ou supprimer votre compte. Vous pouvez nous contacter à tout
              moment en utilisant les coordonnées fournies ci-dessous dans la section
              «Comment nous contacter» pour mettre à jour vos informations personnelles ou
              supprimer votre compte. Toutefois, si vous supprimez votre compte, vous ne
              pourrez peut-être pas utiliser pleinement le service.</span></b></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{color: 'black'}}>Pour mettre à jour
              vos préférences, demandez-nous de supprimer vos informations de nos listes de
              diffusion, de supprimer votre compte ou de soumettre une demande d’exercice de
              vos droits en vertu de la loi applicable. Veuillez nous contacter, comme
              indiqué dans la section «Comment nous contacter» ci-dessous.</span></b></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{fontSize: '14.0pt', color: 'black'}}>6.
              Transferts de données</span></b></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>FizLink est une
            entreprise mondiale. Nous pouvons transférer vos informations personnelles à
            des destinataires situés dans des pays autres que votre pays, y compris le
            Canada, où nous avons notre siège social. Ces pays peuvent ne pas avoir les
            mêmes lois sur la protection des données que le pays dans lequel vous avez
            initialement fourni les informations. Lorsque nous transférons vos informations
            personnelles vers d'autres pays, nous les protégeons comme décrit dans la
            présente politique de confidentialité ou tel qu'il vous a été communiqué au moment
            de la collecte des données. En utilisant nos sites Web et nos solutions ou en
            fournissant des informations personnelles, là où la loi le permet, vous
            acceptez les transferts, le traitement et le stockage de vos informations
            personnelles en dehors de votre pays de résidence.</span></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{fontSize: '14.0pt', color: 'black'}}>7.
              Particuliers situés dans l'EEE ou en Suisse</span></b></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Si vous résidez dans
            l'Espace économique européen («EEE»), nous traitons vos informations
            personnelles aux fins énumérées à la section 3 ci-dessus, sur la base des
            motifs juridiques suivants:</span></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{color: 'black'}}>a. Vous avez
              consenti à l'utilisation de vos informations personnelles. Par exemple, nous
              pouvons chercher à obtenir votre consentement pour notre utilisation des
              témoins (cookies) lorsque vous visitez notre site Web ou pour vous envoyer des
              communications marketing.</span></b></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{color: 'black'}}>b. Nous avons besoin
              de vos informations personnelles pour vous fournir les produits et services que
              vous avez demandés, ou pour répondre à vos questions.</span></b></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{color: 'black'}}>c. Nous avons
              l'obligation légale d'utiliser ou de divulguer vos informations personnelles,
              par exemple si nous répondons à une procédure légale ou à une demande
              gouvernementale exécutoire.</span></b></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{color: 'black'}}>d. Nous (ou un
              tiers) avons un intérêt légitime à utiliser vos informations personnelles. En
              particulier, nous avons un intérêt légitime à utiliser vos informations
              personnelles pour des tâches telles que:</span></b></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>○ Fournir, maintenir et
            améliorer nos services pour répondre aux besoins de nos utilisateurs ○
            Développer de nouveaux produits et fonctionnalités utiles pour nos utilisateurs
            ○ Comprendre comment les gens utilisent nos services pour assurer et améliorer la
            performance de nos services ○ Personnalisation de nos services pour fournir
            vous avec une meilleure expérience utilisateur ○ Marketing pour informer les
            clients existants de nos services ○ Détecter, prévenir ou traiter les problèmes
            de fraude, d’abus, de sécurité ou techniques avec nos services ○ Protection
            contre les atteintes aux droits, à la propriété ou à la sécurité de FizLink,
            nos les utilisateurs ou le public, tel que requis ou autorisé par la loi ○
            Effectuer des recherches qui améliorent nos services pour nos utilisateurs et
            qui profitent au public ○ Respect des obligations vis-à-vis de nos partenaires
            tels que les développeurs et les détenteurs de droits; et ○ Mise en œuvre des
            réclamations légales, y compris des enquêtes sur les violations potentielles
            des Conditions d'utilisation applicables. Vous avez le droit de demander
            l'accès et de recevoir des informations sur les informations personnelles que
            nous conservons à votre sujet, de mettre à jour et de corriger les
            inexactitudes dans vos informations personnelles, de restreindre ou de vous
            opposer au traitement de vos informations personnelles, de bloquer ou de
            supprimer les informations, approprié, ou d’exercer votre droit à la
            transférabilité des données pour transférer facilement vos informations
            personnelles à une autre société. Ces droits peuvent être limités dans
            certaines circonstances par les exigences de la législation locale. Outre les
            droits susmentionnés, vous avez également le droit de déposer une plainte
            auprès d'une autorité de surveillance compétente, dans le respect du droit
            applicable. De plus, si nous comptons sur votre consentement pour le traitement
            de vos informations personnelles, vous avez le droit de les retirer à tout
            moment et gratuitement. Dans ce cas, cela n’affectera pas la licéité du
            traitement avant votre retrait de consentement. FizLink Inc. &nbsp;est l'entité
            responsable du traitement de vos informations personnelles. Vous pouvez exercer
            vos droits sur vos informations personnelles en nous envoyant un courrier
            électronique à l'adresse: privacy.policy@FizLink.com avec le sujet suivant:
            POLITIQUE DE CONFIDENTIALITÉ</span></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{fontSize: '14.0pt', color: 'black'}}>8.
              Confidentialité des enfants</span></b></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Nos produits et
            services sont destinés aux adultes. Nous n'autorisons pas sciemment les enfants
            de moins de 18 ans à créer des comptes FizLink ou utiliser les expériences FizLink.
            Si vous pensez que nous avons pu collecter des informations personnelles auprès
            d'une personne de moins de 18 ans, veuillez-nous en informer en utilisant les
            méthodes décrites dans la section Contactez-nous. Nous prendrons les mesures
            nécessaires pour enquêter et résoudre le problème rapidement.</span></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{fontSize: '14.0pt', color: 'black'}}>9.
              Comment nous protégeons vos informations personnelles</span></b></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Nous avons l'intention
            de protéger les informations personnelles qui nous sont confiées et de les
            traiter de manière sécurisée conformément à la présente politique de
            confidentialité. Nous appliquons des mesures de protection administratives,
            techniques et physiques pour protéger les informations personnelles contre
            toute destruction accidentelle ou illicite, perte accidentelle, modification
            non autorisée, divulgation ou accès non autorisés, utilisation abusive et toute
            autre forme de traitement illicite des informations personnelles en notre
            possession. Nous exigeons également de manière contractuelle que nos fournisseurs
            protègent ces informations contre tout accès, utilisation et divulgation non
            autorisés. Internet n'est pas sécurisé à 100%. Nous ne pouvons pas garantir que
            votre utilisation de nos sites sera totalement sûre. Nous vous encourageons à
            faire preuve de prudence lorsque vous utilisez Internet. Cela inclut de ne pas
            partager vos mots de passe. Si vous participez à un forum de discussion, à des
            communautés locales ou à un forum de discussion sur un site Web FizLink, vous
            devez être conscient du fait que les informations que vous fournissez (votre
            profil public) seront largement diffusées et pourront être utilisées pour vous
            contacter. , vous envoient des messages non sollicités, ou à des fins que ni FizLink
            ni vous n’exerciez le contrôle. Veuillez également reconnaître que des forums
            et des forums de discussion individuels peuvent comporter des règles et des
            conditions supplémentaires. FizLink n'est pas responsable des informations
            personnelles ou de toute autre information que vous choisissez de soumettre sur
            ces forums. Dans certains cas, nous pourrions ne pas être en mesure de
            supprimer vos informations personnelles. Dans ce cas, nous vous ferons savoir
            si nous ne pouvons pas le faire et pourquoi. Conservation des informations
            personnelles Nous prenons également des mesures pour supprimer vos informations
            personnelles ou les conserver sous une forme ne permettant pas de vous
            identifier lorsque ces informations ne sont plus nécessaires aux fins pour
            lesquelles nous les traitons ou lorsque vous demandez leur suppression, à moins
            que nous sommes requis par la loi de conserver les informations plus longtemps.
            Nous effectuons des examens périodiques de nos bases de données et avons établi
            des délais pour la suppression de certaines données, en tenant compte du type
            de services fournis dans le contexte des produits et services, de la durée de
            notre relation avec vous, des délais de conservation obligatoires et des lois
            de limitations.</span></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{fontSize: '14.0pt', color: 'black'}}>10.
              Services tiers</span></b></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Le Service peut
            contenir des fonctionnalités ou des liens vers des sites Web et des services
            fournis par des tiers. Si vous cliquez sur un lien vers un site tiers, vous
            serez redirigé vers des sites Web que nous ne contrôlons pas. Toutes les
            informations que vous fournissez sur des sites ou services tiers peuvent être
            fournies directement aux opérateurs de ces services et sont soumises aux
            politiques de ces opérateurs, le cas échéant, régissant la confidentialité et
            la sécurité, même si elles sont accessibles via le service. Cette politique ne
            s'applique pas aux pratiques de confidentialité de ce site Web. Lisez
            attentivement la politique de confidentialité des autres sites Web. Nous ne
            sommes pas responsables des sites ou services tiers auxquels des liens ou des
            accès sont fournis via le service.</span></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{fontSize: '14.0pt', color: 'black'}}>11
              Résultats et données</span></b></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>FizLink exploite les
            données provenant de la foule et n'est pas responsable et ne sera pas tenu
            responsable des inexactitudes des données et des résultats collectés. La
            géolocalisation est cartographier sur la base d'une combinaison de GPS et / ou
            d'adresse IP et dans certains cas, l'utilisation de réseaux mobiles ou VPN
            pourrait affecter la précision des résultats. Les résultats et les données sont
            régulièrement sauvegardés, mais FizLink ne sera pas responsable des données
            perdues ou des erreurs qui surviennent dans les données collectées. FizLink ne
            sera pas tenue responsable des données malveillantes extraites de nos bases de
            données et les utilisateur de nos services ne poursuivra ni ne recherchera de
            recours pour de tels actes. </span></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{fontSize: '14.0pt', color: 'black'}}>12.
              Contenu</span></b></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Notre service vous
            permet de publier, de lier, de stocker, de partager et de rendre disponible
            certaines informations, textes, graphiques, vidéos ou autre matériel
            ("Contenu"). Vous êtes responsable du contenu que vous publiez sur le
            service, y compris sa légalité, sa fiabilité et son caractère approprié.</span></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>En publiant du Contenu
            sur le Service, vous nous accordez le droit et la licence d'utiliser, de
            modifier, d'exécuter publiquement, d'afficher publiquement, de reproduire et de
            distribuer ce Contenu sur et via le Service. Vous conservez tous vos droits sur
            tout Contenu que vous soumettez, publiez ou affichez sur ou via le Service et
            vous êtes responsable de la protection de ces droits. Vous acceptez que cette
            licence inclut le droit pour nous de rendre votre contenu disponible à d'autres
            utilisateurs du service, qui peuvent également utiliser votre contenu sous
            réserve des présentes conditions.</span></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Vous déclarez et
            garantissez que: (i) le Contenu vous appartient (vous en êtes propriétaire) ou
            vous avez le droit de l'utiliser et de nous accorder les droits et la licence
            prévus dans les présentes Conditions, et (ii) la publication de votre Contenu
            sur ou par le biais du Service ne viole pas les droits de confidentialité, les
            droits de publicité, les droits d'auteur, les droits contractuels ou tout autre
            droit de toute personne. De plus, vous garantissez que: (i) le Contenu ne vous
            amènera ni vous ni nous à enfreindre une loi, un règlement, une règle, un code
            ou toute autre obligation légale; (ii) le Contenu ne sera pas ou ne pourra
            raisonnablement pas être considéré comme obscène, inapproprié, diffamatoire,
            dénigrant, indécent, séditieux, offensant, pornographique, menaçant, abusif,
            susceptible d'inciter à la haine raciale, discriminatoire, blasphématoire, en
            violation de la confiance ou en violation de la vie privée; (iii) le contenu ne
            sera pas une publicité non sollicitée, non divulguée ou non autorisée; (iv) le
            contenu ne contient pas de virus logiciels ou tout autre code informatique,
            fichiers ou programmes conçus pour interrompre, détruire ou limiter la
            fonctionnalité de tout logiciel informatique, matériel ou équipement de
            télécommunications; et (v): le Contenu ne nous discrédite pas ou ne discrédite
            pas le Service.</span></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Vous acceptez de
            conserver tous les enregistrements nécessaires pour établir que votre contenu
            ne viole aucune des exigences de cette clause et de rendre ces enregistrements
            disponibles à notre demande raisonnable.</span></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>&nbsp;</span></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>&nbsp;</span></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Nous n'avons aucune
            obligation de surveiller régulièrement l'exactitude ou la fiabilité de votre
            contenu incorporé dans le service. Nous nous réservons le droit de modifier ou
            de supprimer tout contenu à tout moment.</span></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Vous reconnaissez et
            acceptez que tout le contenu que vous fournissez sur le service sera une
            information accessible au public et que vous supportez les risques liés à ces
            divulgations publiques.</span></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{fontSize: '14.0pt', color: 'black'}}>13.
              Comptes</span></b></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Lorsque vous créez un
            compte chez nous, vous devez nous fournir des informations exactes, complètes
            et à jour à tout moment. Le non-respect de cette consigne constitue une
            violation des Conditions, ce qui peut entraîner la résiliation immédiate de
            votre compte sur notre Service.</span></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Vous êtes responsable
            de la protection du mot de passe que vous utilisez pour accéder au Service et
            de toute activité ou action sous votre mot de passe, que votre mot de passe
            soit avec notre Service ou un service tiers.</span></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Vous acceptez de ne
            divulguer votre mot de passe à aucun tiers. Vous acceptez d'être entièrement
            responsable des activités liées à votre compte ou à votre mot de passe. Vous
            devez nous informer immédiatement dès que vous avez connaissance d'une
            violation de la sécurité ou d'une utilisation non autorisée de votre compte.</span></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Vous ne pouvez pas
            utiliser comme nom d'utilisateur le nom d'une autre personne ou entité ou qui
            n'est pas légalement disponible pour une utilisation, un nom ou une marque qui
            est soumis à des droits d'une autre personne ou entité autre que vous sans
            autorisation appropriée, ou un nom qui est autrement offensant, vulgaire ou obscène.</span></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Conditions
            supplémentaires pour l'abonnement aux comptes FizLink (Pro)</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt'}}><b><span lang="FR-CA" style={{color: 'black'}}>Abonnements</span></b></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt'}}><span lang="FR-CA" style={{color: 'black'}}>Certaines parties du Service sont facturées sur la base
            d'un abonnement («Abonnement (s)»). Vous serez facturé à l'avance sur une base
            récurrente et périodique («Cycle de facturation»). Les cycles de facturation
            sont définis sur une base mensuelle ou annuelle, selon le type de plan
            d'abonnement que vous sélectionnez lors de l'achat d'un abonnement.</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt'}}><span lang="FR-CA" style={{color: 'black'}}>À la fin de chaque cycle de facturation, votre abonnement
            sera automatiquement renouvelé dans les mêmes conditions, à moins que vous ne
            l'annuliez ou que Votie Solutions Inc. ne l'annule. Vous pouvez annuler le
            renouvellement de votre abonnement via la page de gestion de votre compte en
            ligne ou en contactant l'équipe d'assistance à la clientèle de Votie Solutions
            Inc.</span></p>
        <p className="MsoNormal" style={{marginLeft: '72.0pt'}}><span lang="FR-CA" style={{color: 'black'}}>&nbsp;</span></p>
        <p className="MsoNormal" style={{marginLeft: '72.0pt'}}><span lang="FR-CA" style={{color: 'black'}}>&nbsp;</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt'}}><span lang="FR-CA" style={{color: 'black'}}>Un mode de paiement valide, y compris une carte de crédit,
            est nécessaire pour traiter le paiement de votre abonnement. Vous devez fournir
            à Votie Solutions Inc. des informations de facturation exactes et complètes, y
            compris le nom complet, l'adresse, l'état, le code postal et une méthode de
            paiement valide. En soumettant ces informations de paiement, vous autorisez
            automatiquement Votie Solutions Inc. à facturer tous les frais d'abonnement
            engagés via votre compte sur ces instruments de paiement.</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt'}}><span lang="FR-CA" style={{color: 'black'}}>Si la facturation automatique échoue pour quelque raison
            que ce soit, Votie Solutions Inc. émettra une facture électronique indiquant
            que vous devez procéder manuellement, dans une certaine date limite, avec le paiement
            intégral correspondant à la période de facturation indiquée sur la facture.</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt'}}><b><span lang="FR-CA" style={{color: 'black'}}>Essai gratuit</span></b></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt'}}><span lang="FR-CA" style={{color: 'black'}}>Votie Solutions Inc. peut, à sa seule discrétion, offrir un
            abonnement avec un essai gratuit pour une période limitée («Essai gratuit»).</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt'}}><span lang="FR-CA" style={{color: 'black'}}>Vous devrez peut-être saisir vos informations de
            facturation pour vous inscrire à l'essai gratuit.</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt'}}><span lang="FR-CA" style={{color: 'black'}}>Si vous entrez vos informations de facturation lors de
            votre inscription à l'essai gratuit, Votie Solutions Inc. ne vous facturera pas
            avant l'expiration de l'essai gratuit. Le dernier jour de la période d'essai
            gratuit, à moins que vous n'annuliez votre abonnement, les frais d'abonnement
            applicables vous seront automatiquement facturés pour le type d'abonnement que
            vous avez sélectionné.</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt'}}><span lang="FR-CA" style={{color: 'black'}}>À tout moment et sans préavis, Votie Solutions Inc. se
            réserve le droit de (i) modifier les termes et conditions de l'offre d'essai
            gratuit, ou (ii) d'annuler cette offre d'essai gratuit.</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt'}}><b><span lang="FR-CA" style={{color: 'black'}}>Modifications des frais</span></b></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt'}}><span lang="FR-CA" style={{color: 'black'}}>Votie Solutions Inc., à sa seule discrétion et à tout
            moment, peut modifier les frais d'abonnement pour les abonnements. Toute
            modification des frais d'abonnement entrera en vigueur à la fin du cycle de
            facturation en cours.</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt'}}><b><span lang="FR-CA" style={{color: 'black'}}>Votie Solutions Inc. vous fournira un préavis raisonnable
              de tout changement des frais d'abonnement pour vous donner la possibilité de
              résilier votre abonnement avant que ce changement ne devienne effectif.</span></b></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt'}}><b><span lang="FR-CA" style={{color: 'black'}}>Votre utilisation continue du Service après l'entrée en
              vigueur de la modification des frais d'abonnement constitue votre accord pour
              payer le montant des frais d'abonnement modifié.</span></b></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt'}}><b><span lang="FR-CA" style={{color: 'black'}}>Remboursements</span></b></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt'}}><span lang="FR-CA" style={{color: 'black'}}>Sauf lorsque la loi l'exige, les frais d'abonnement payés
            ne sont pas remboursables.</span></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{fontSize: '14.0pt', color: 'black'}}>14.
              PI (propriété intellectuelle)</span></b></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Le Service et son
            contenu original (à l'exclusion du Contenu fourni par les utilisateurs), ses
            caractéristiques et ses fonctionnalités sont et resteront la propriété
            exclusive de Votie Solutions Inc. et de ses concédants de licence. Le Service
            est protégé par les droits d'auteur, les marques de commerce et d'autres lois
            du Canada et des pays étrangers. Nos marques de commerce et notre présentation
            commerciale ne peuvent être utilisées en relation avec aucun produit ou service
            sans le consentement écrit préalable de Votie Solutions Inc. Rien dans ces
            Conditions ne constitue un transfert de tout droit de propriété intellectuelle
            de notre part à vous.</span></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Vous êtes autorisé à
            utiliser le Service uniquement comme autorisé par nous. En tant qu'utilisateur,
            vous bénéficiez d'un droit limité, non exclusif, révocable et non transférable
            d'utiliser le Service pour créer, afficher, utiliser, lire et télécharger du
            Contenu soumis aux présentes Conditions.</span></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Notre propriété
            intellectuelle ne doit pas être utilisée en relation avec un produit ou un
            service qui ne nous est pas affilié ou ne nous discrédite d'aucune manière.</span></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Vous ne devez pas
            modifier les copies physiques ou numériques de tout contenu que vous imprimez
            ou téléchargez de quelque manière que ce soit, et vous ne devez pas utiliser
            d'illustrations, de photographies, de vidéo ou d'audio ou de graphiques
            séparément de tout texte d'accompagnement.</span></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Les opinions, conseils,
            déclarations, services, offres ou autres informations ou contenus exprimés ou
            mis à disposition par tout autre utilisateur sont ceux des auteurs ou
            distributeurs respectifs et non de nous.</span></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{fontSize: '14.0pt', color: 'black'}}>15.
              Liens vers d'autres sites Web</span></b></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Notre service peut
            contenir des liens vers des sites Web ou des services tiers qui ne sont ni
            détenus ni contrôlés par FizLink ou Votie Solutions Inc.</span></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Votie Solutions Inc.
            n'a aucun contrôle sur, et n'assume aucune responsabilité pour, le contenu, les
            politiques de confidentialité ou les pratiques de tout site Web ou service
            tiers. Vous reconnaissez et acceptez en outre que Votie Solutions Inc. ne sera
            pas responsable, directement ou indirectement, de tout dommage ou perte causé
            ou prétendument causé par ou en relation avec l'utilisation ou la confiance en
            ces contenus, biens ou services disponibles. sur ou via ces sites ou services
            Web.</span></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Nous ne fournissons des
            liens vers des sites Web externes que par commodité, et l'inclusion d'un tel lien
            vers des sites Web externes n'implique pas notre approbation de ces sites Web.
            Vous reconnaissez et acceptez que lorsque vous accédez à d'autres sites Web sur
            Internet, vous le faites à vos propres risques.</span></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Nous vous conseillons
            vivement de lire les termes et conditions et les politiques de confidentialité
            de tous les sites Web ou services tiers que vous visitez.</span></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{fontSize: '14.0pt', color: 'black'}}>16.
              Résiliation</span></b></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Nous pouvons résilier
            ou suspendre votre compte immédiatement, sans préavis ni responsabilité, pour
            quelque raison que ce soit, y compris, sans limitation, si vous enfreignez les
            Conditions.</span></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>En cas de résiliation,
            votre droit d'utiliser le Service cessera immédiatement. Si vous souhaitez
            résilier votre compte, vous pouvez simplement cesser d'utiliser le Service.</span></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Toutes les dispositions
            des Conditions qui, de par leur nature, devraient survivre à la résiliation
            survivront à la résiliation, y compris, sans limitation, les dispositions
            relatives à la propriété, les exclusions de garantie, l'indemnisation et les
            limitations de responsabilité.</span></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Nous ne serons pas
            responsables envers vous ou tout tiers pour toute réclamation ou tout dommage
            découlant de toute résiliation ou suspension ou de toute autre action que nous
            prendrons à ce sujet.</span></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Si la loi applicable
            nous oblige à fournir un avis de résiliation ou d'annulation, nous pouvons
            donner un avis préalable ou ultérieur en le publiant sur le Service ou en
            envoyant une communication à toute adresse (e-mail ou autre) que nous avons
            pour vous dans nos dossiers.</span></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{fontSize: '14.0pt', color: 'black'}}>17.
              Indemnisation</span></b></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>En tant que condition
            de votre accès et de votre utilisation du Service, vous acceptez de nous
            indemniser ainsi que nos successeurs et ayants droit pour tous les dommages,
            coûts, dépenses et autres responsabilités, y compris, mais sans s'y limiter,
            les frais juridiques et les dépenses, liés à toute réclamation découlant de ou
            lié à votre accès et à votre utilisation du Service ou à votre violation des
            présentes Conditions et de toute loi applicable ou des droits d'une autre
            personne ou partie.</span></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Cette section
            d'indemnisation survit à l'expiration de votre inscription et s'applique aux
            réclamations survenant avant et après la fin de l'enregistrement.</span></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{fontSize: '14.0pt', color: 'black'}}>18.
              Limitation de responsabilité</span></b></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Vous acceptez que nous
            ne serons pas responsables des dommages subis à la suite de l'utilisation du
            service, de la copie, de la distribution ou du téléchargement du contenu du
            service.</span></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>En aucun cas, nous ne
            serons responsables de tout dommage indirect, punitif, spécial, accidentel ou
            consécutif (y compris la perte d'activité, de revenus, de bénéfices,
            d'utilisation, de confidentialité, de données, de clientèle ou de tout autre
            avantage économique) de quelque manière qu'il survienne, que ce soit pour
            rupture de contrat. ou délictuelle, même si elle a été préalablement informée
            de la possibilité d'un tel dommage.</span></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Vous êtes seul
            responsable de la protection de sécurité adéquate et de la sauvegarde des
            données et / ou de l'équipement utilisé dans le cadre de votre utilisation du
            Service et ne fera aucune réclamation pour perte de données, temps de réexécution,
            instructions inexactes, retards de travail ou perte de profits en résultant. de
            l'utilisation du Service. Vous ne devez pas céder ou autrement céder votre
            compte à une autre personne.</span></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Sans limiter ce qui
            précède, en aucun cas notre responsabilité globale envers vous ne dépassera, au
            total, les montants que vous nous avez payés.</span></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{fontSize: '14.0pt', color: 'black'}}>19.
              Clause de non-responsabilité</span></b></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Votre utilisation du
            Service est à vos risques et périls. Le Service est fourni «TEL QUEL» et «TEL
            QUE DISPONIBLE». Le Service est fourni sans garantie d'aucune sorte, expresse
            ou implicite, y compris, mais sans s'y limiter, les garanties implicites de
            qualité marchande, d'adéquation à un usage particulier, de non-contrefaçon ou
            de cours de performance.</span></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Votie Solutions Inc.,
            ses filiales, sociétés affiliées et ses concédants de licence ne garantissent
            pas que a) le Service fonctionnera de manière ininterrompue, sécurisée ou
            disponible à un moment ou à un endroit particulier; b) toute erreur ou défaut
            sera corrigé; c) le Service est exempt de virus ou d'autres composants
            nuisibles; ou d) les résultats de l'utilisation du Service répondront à vos
            exigences.</span></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Cette exclusion de
            responsabilité s'applique à tout dommage ou blessure causé par toute
            défaillance de performance, erreur, omission, interruption, suppression,
            défaut, retard de fonctionnement ou de transmission, virus informatique, panne
            de ligne de communication, vol ou destruction ou accès non autorisé ou,
            modification ou l'utilisation d'un dossier en relation avec l'utilisation ou le
            fonctionnement du Service, que ce soit pour rupture de contrat, comportement
            délictueux, négligence ou toute autre cause d'action.</span></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Nous ne faisons aucune
            représentation ou garantie d'aucune sorte, expresse ou implicite, quant à
            l'exhaustivité, l'exactitude, la fiabilité, l'adéquation ou la disponibilité en
            ce qui concerne le contenu contenu sur le Service à quelque fin que ce soit.
            Toute confiance que vous accordez à ces informations est donc strictement à vos
            propres risques. Nous déclinons toute représentation ou garantie expresse ou
            implicite quant à l'efficacité ou la rentabilité du Service ou que le
            fonctionnement de notre Service sera ininterrompu ou sans erreur. Nous ne
            sommes pas responsables des conséquences de toute interruption ou erreur dans
            le service.</span></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{fontSize: '14.0pt', color: 'black'}}>20.
              Exclusions</span></b></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Certaines juridictions
            n'autorisent pas l'exclusion de certaines garanties ou l'exclusion ou la
            limitation de responsabilité pour les dommages indirects ou accessoires, de
            sorte que les limitations ci-dessus peuvent ne pas s'appliquer à vous.</span></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{fontSize: '14.0pt', color: 'black'}}>21.
              Loi applicable</span></b></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Ces conditions seront
            régies et interprétées conformément aux lois du Québec, Canada, sans égard à
            ses dispositions relatives aux conflits de lois.</span></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Notre incapacité à
            appliquer un droit ou une disposition de ces Conditions ne sera pas considéré
            comme une renonciation à ces droits. Si une disposition de ces Conditions est
            jugée invalide ou inapplicable par un tribunal, les autres dispositions de ces
            Conditions resteront en vigueur. Ces Conditions constituent l'intégralité de
            l'accord entre nous concernant notre Service, et remplacent et remplacent tout
            accord antérieur que nous pourrions avoir entre nous concernant le Service.</span></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{fontSize: '14.0pt', color: 'black'}}>22.
              Modifications</span></b></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Nous nous réservons le
            droit, à notre seule discrétion, de modifier ou de remplacer ces Conditions à
            tout moment. Si une révision est importante, nous essaierons de fournir un
            préavis d'au moins 15 jours avant l'entrée en vigueur de toute nouvelle
            condition.</span></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Il est de votre seule
            responsabilité de vérifier périodiquement ces conditions pour tout changement.
            Si vous n'êtes pas d'accord avec l'une des modifications apportées aux
            présentes Conditions, il est de votre seule responsabilité d'arrêter d'utiliser
            le Service. Votre utilisation continue du Service sera considérée comme votre
            acceptation de celui-ci.</span></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{fontSize: '14.0pt', color: 'black'}}>23.
              Données client</span></b></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Le client détient les
            droits sur ses données en tant que responsable du traitement et le service agit
            en tant que sous-traitant pour le compte du client. Tout traitement par le
            service des données personnelles et autres données fournies par le client doit
            être conforme aux lois applicables. Le traitement des données personnelles par
            le service pour le compte du Client ne sera donc effectué que pour fournir le
            Produit et sera soumis aux instructions écrites du Client.</span></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Le client est tenu de
            garder secrets les identifiants et les mots de passe des utilisateurs pour le
            produit de tout utilisateur non autorisé ou de tiers. Le Client est tenu de
            s'assurer que les données personnelles fournies par le Client et utilisées dans
            le Produit sont traitées par le Client conformément à toutes les lois
            applicables. Le Client est tenu de s'assurer que les données du Client fournies
            dans le Produit, y compris les données personnelles, ne violent aucun droit de
            propriété intellectuelle de tiers et / ou aucune législation applicable.</span></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Le service est en droit
            de supprimer toutes les données qui, à la seule discrétion du service,
            constituent une violation de l'engagement susmentionné par le client, et le
            client n'aura droit à aucune compensation à cet égard.</span></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{fontSize: '14.0pt', color: 'black'}}>24.
              Fonction d'intégration de Facebook Pixel</span></b></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Lorsque vous utilisez
            la fonctionnalité d'intégration de pixels Facebook de Fizlink, vous placez
            votre pixel Facebook sur votre profil et vous devenez donc le contrôleur de vos
            données Facebook, et vous fournissez ces données à Facebook Inc. En utilisant
            cette fonctionnalité, vous acceptez Facebook Inc. conditions générales et
            acceptez que vous êtes maintenant dans une relation de contrôleur de données /
            processeur de données avec Facebook Inc.</span></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{fontSize: '14.0pt', color: 'black'}}>25.
              Emplacement des données</span></b></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Vous acceptez qu'en
            utilisant ce service, toutes les données détenues comme indiqué dans la
            politique de confidentialité et ici dans, comme nécessaire pour que le produit
            fonctionne, soient stockées et traitées aux États-Unis d'Amérique et ne soient
            pas stockées au Canada ou dans l'Union européenne.</span></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{fontSize: '14.0pt', color: 'black'}}>26.
              Mise à jour de cette politique</span></b></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Nous pouvons mettre à
            jour cette politique de confidentialité de temps à autre. Si nous modifions
            notre politique de confidentialité, nous publierons la version révisée ici,
            avec une date de révision mise à jour. Nous vous recommandons de visiter ces
            pages périodiquement pour prendre connaissance de ces révisions et les
            examiner. Si nous apportons des modifications importantes à notre politique de
            confidentialité, nous pouvons également vous en informer par d'autres moyens
            avant l'entrée en vigueur des modifications, par exemple en affichant un avis
            sur nos sites Web ou en vous envoyant une notification.</span></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{fontSize: '14.0pt', color: 'black'}}>27.
              Comment nous contacter</span></b></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>Si vous avez des
            questions, des commentaires ou des plaintes concernant la présente politique de
            confidentialité et nos pratiques de confidentialité, ou si vous souhaitez
            mettre à jour vos préférences en matière de confidentialité, veuillez nous
            contacter à l'adresse suivante: privacy.policy@FizLink.com avec le sujet
            suivant: POLITIQUE DE CONFIDENTIALITÉ révisé et affiché à compter du 20 mars 2020.</span></p>
        <p className="MsoNormal"><b><span lang="FR-CA" style={{fontSize: '14.0pt', color: 'black'}}>28.
              Garanties</span></b></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>FizLink est fourni
            "TEL QUEL" ET TOUTE GARANTIE EXPRESSE OU IMPLICITE, Y COMPRIS, MAIS
            SANS S'Y LIMITER, LES GARANTIES IMPLICITES DE QUALITÉ MARCHANDE ET D'ADÉQUATION
            À UN USAGE PARTICULIER SONT EXCLUES. EN AUCUN CAS, LE TITULAIRE DU DROIT
            D'AUTEUR OU LES CONTRIBUTEURS NE SERONT RESPONSABLES DES DOMMAGES DIRECTS,
            INDIRECTS, ACCESSOIRES, SPÉCIAUX, EXEMPLAIRES OU CONSÉCUTIFS (Y COMPRIS, MAIS
            SANS S'Y LIMITER, L'APPROVISIONNEMENT DE BIENS OU SERVICES DE SUBSTITUTION;
            PERTE D'UTILISATION, DE DONNÉES OU DE PROFITS; OU INTERRUPTION D'AFFAIRES)
            TOUTEFOIS CAUSÉE ET SUR TOUTE THÉORIE DE LA RESPONSABILITÉ, QUE CE SOIT DANS LE
            CONTRAT, LA RESPONSABILITÉ STRICTE OU LE TORT (Y COMPRIS LA NÉGLIGENCE OU
            AUTREMENT) DÉCOULANT DE TOUTE MANIÈRE D'UTILISER CE LOGICIEL, MÊME SI IL EST AVISÉ
            DE LA POSSIBILITÉ DE TELS DOMMAGES.</span></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>&nbsp;</span></p>
        <div style={{border: 'none', borderBottom: 'solid windowtext 1.0pt', padding: '0cm 0cm 1.0pt 0cm'}}>
          <p className="MsoNormal" style={{border: 'none', padding: '0cm'}}><span lang="FR-CA" style={{color: 'black'}}>&nbsp;</span></p>
        </div>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>&nbsp;</span></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>&nbsp;</span></p>
        <p className="MsoNormal"><span lang="FR-CA" style={{color: 'black'}}>&nbsp;</span></p>
      </div>
    );
};