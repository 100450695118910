import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { PageNotFound } from '../page-not-found.component';
import { updateProfile } from '../../redux/user.state';
import { useTextField } from '../authentication/signup.component';
import { TextField, Snackbar } from 'react-md';
import { Button } from '@react-md/button';
import { updateMobile } from '../../redux/mobile.state';
import { updateTag } from '../../redux/tag.state';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../locales/keys';
import { MediaService } from '../../services/media.service';
import { DEFAULT_PROFILE_PICTURE } from '../preview/base64.lib';
import { FileUploadDialog } from '../overview/file-upload.dialog';

import './edit-profile.component.scss';

export const EditProfileComponent = () => {
    const { t } = useTranslation();
    const token = useSelector((state: RootState) => state.authentication.token);
    const user = useSelector((state: RootState) => state.user.selectedUser);
    const { tags, selectedTagId } = useSelector((state: RootState) => state.tag);
    const selectedTag = tags.find((t) => t._id === selectedTagId);
    const { mobiles } = useSelector((state: RootState) => state.mobile);
    const selectedMobile = mobiles.find((m) => m._id === selectedTag?.mobile);
    const { value: firstname, bind: bindFirstname } = useTextField(user?.contact?.firstname ?? '');
    const { value: lastname, bind: bindLastname } = useTextField(user?.contact?.lastname ?? '');
    const { value: email, bind: bindEmail } = useTextField(user?.contact?.email ?? '');
    const { value: title, bind: bindTitle } = useTextField(selectedMobile?.pages[0].title ?? '');
    const { value: bio, bind: bindBio } = useTextField(selectedMobile?.pages[0].bio ?? '');
    const [toasts, setToasts] = useState<Array<{ text: React.ReactNode }>>([]);
    const [fileUpload, showFileUpload] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (user) {
            bindFirstname.onChange(user.contact.firstname);
            bindLastname.onChange(user.contact.lastname);
            bindEmail.onChange(user.contact.email);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if (selectedMobile) {
            bindTitle.onChange(selectedMobile.pages[0].title ?? '');
            bindBio.onChange(selectedMobile.pages[0].bio ?? '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMobile]);

    if (!token && !user) {
        return <PageNotFound />
    }

    const renderSnackbar = (): JSX.Element => {
        return (
            <Snackbar
                id="copied-snackbar"
                toasts={toasts}
                autohide={true}
                onDismiss={() => setToasts([])}
            />
        );
    };

    const onUpdatedSuccessfully = () => {
        const newToasts = Array<{ text: React.ReactNode }>();
        if (selectedMobile && (selectedMobile.pages[0].title !== title || selectedMobile.pages[0].bio !== bio)) {
            newToasts.push({ text: "Profile updated." });
        }
        else if (firstname !== user?.contact.firstname || lastname !== user?.contact.lastname || email !== user?.contact.email) {
            newToasts.push({ text: "Contact info updated." });
        }
        setToasts(newToasts);
    };

    const onMobileUpdateError = (text: string) => {
        setToasts([{ text }]);
    };

    const onSubmit = () => {
        const emailToSave = email !== user?.contact.email ? email : undefined;
        if (user && (firstname !== user.contact.firstname || lastname !== user.contact.lastname || email !== user.contact.email)) {
            updateProfile(user._id, firstname, lastname, emailToSave, onUpdatedSuccessfully)(dispatch);
            if (selectedTag) {
                updateTag({ ...selectedTag, description: `${firstname} ${lastname}` })(dispatch);
            }
        }
        if (selectedMobile && (selectedMobile.pages[0].title !== title || selectedMobile.pages[0].bio !== bio)) {
            const page0 = { ...selectedMobile.pages[0], title, bio };
            const updatedMobile = { ...selectedMobile, pages: [page0, ...selectedMobile.pages.slice(1)] };
            updatedMobile.pages[0].title = title;
            updatedMobile.pages[0].bio = bio;
            updateMobile(updatedMobile, onUpdatedSuccessfully, () => onMobileUpdateError('Could not update your profile.'))(dispatch);
        }
    };

    const handleFileUpload = (file: Blob, onDone: () => void, onError: () => void) => {
        const reader = new FileReader();

        reader.onload = (ev: ProgressEvent<FileReader>) => {
            if (ev?.target && selectedMobile) {
                const pages = [...selectedMobile.pages];
                pages.splice(0, 1, { ...pages[0], profilepicture: ev.target.result, picturecontent: file.type });
                const updatedMobile = { ...selectedMobile, pages };
                updateMobile(updatedMobile, onDone, onError)(dispatch);
            }
            else {
                onError();
            }
        };

        reader.readAsDataURL(file);
    };

    const chooseProfilePicture = () => {
        showFileUpload(true);
    };

    let enabled = (firstname !== user?.contact.firstname || lastname !== user?.contact.lastname || email !== user.contact.email);
    enabled = enabled || (!!selectedMobile && (selectedMobile.pages[0].title !== title || selectedMobile.pages[0].bio !== bio));

    const source = selectedMobile?.pages[0]?.picture ? MediaService.getMediaUrl(selectedMobile?.pages[0]?.picture) : DEFAULT_PROFILE_PICTURE;
    return (
        <React.Fragment>

            <div className="edit-profile">
                <div className="edit-profile-picture">
                    <img className="profilepicture" height="64" width="64" alt="Profile" src={source} />
                    <div className="edit-profile-picture-info">
                        <span>{token?.username}</span>
                        <button className="forgot-password-link" onClick={chooseProfilePicture}>Change Profile Picture</button>
                    </div>
                </div>
                <TextField fullWidth={true} id="firstname" type="text" label={t(LocalizationKeys.FirstName)} {...bindFirstname} />
                <TextField fullWidth={true} id="lastname" type="text" label={t(LocalizationKeys.LastName)} {...bindLastname} />
                <TextField fullWidth={true} id="email" type="email" label={t(LocalizationKeys.Email)} {...bindEmail} />
                <TextField fullWidth={true} id="title" type="text" label={t(LocalizationKeys.Title)} {...bindTitle} helpText={t(LocalizationKeys.TitleHelperText)} />
                <TextField fullWidth={true} id="bio" type="text" label={t(LocalizationKeys.Bio)} {...bindBio} helpText={t(LocalizationKeys.BioHelperText)} rows={2} />
                <Button className="edit-submit" disabled={!enabled} onClick={onSubmit}>{t(LocalizationKeys.Submit)}</Button>
            </div>
            {renderSnackbar()}
            {fileUpload ? <FileUploadDialog visible={fileUpload} onHide={() => showFileUpload(false)} onFileUpload={handleFileUpload} /> : null}
        </React.Fragment>
    );
};
