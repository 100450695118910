import React from 'react'
import { RouteProps, Route, Redirect } from 'react-router-dom';
import { RootState } from './redux/reducers';
import { useSelector } from 'react-redux';

interface PrivateRouteProps extends RouteProps { }

export const PrivateRoute = (props: PrivateRouteProps) => {
    const token = useSelector((state: RootState) => state.authentication.token);
    return !token ? <Redirect to="/home" /> : <Route {...props} />;
};
