import React from 'react';

interface FizLinkProps {
    height?: number;
}

export const FizLink = (props: FizLinkProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height={props.height ?? 64} viewBox="0 0 200.035 57.545">
            <g transform="translate(-223.571 -377)">
                <g transform="translate(-423.429 130.162)">
                    <g transform="translate(647 246.838)">
                        <circle cx="14.91" cy="14.91" r="14.91" transform="translate(0)" fill="#c7eb5c" />
                        <circle cx="8.76" cy="8.76" r="8.76" transform="translate(30.865 19.107)" fill="#5cc7eb" />
                        <path d="M5.619,0A5.619,5.619,0,1,1,0,5.619,5.619,5.619,0,0,1,5.619,0Z" transform="translate(0 31.388)" fill="#ebad5c" />
                        <circle cx="11.246" cy="11.246" r="11.246" transform="translate(12.805 35.053)" fill="#eb5c5c" />
                    </g>
                </g>
                <g transform="translate(0 2.22)">
                    <path d="M18.176-42.24l1.792.256a3.515,3.515,0,0,1,2.56,2.432A3.3,3.3,0,0,1,21.5-36.1a3.027,3.027,0,0,1-3.328.768L16-35.584a2.2,2.2,0,0,0-1.792.768,3.706,3.706,0,0,0-.64,2.56v3.2h5.5a2.97,2.97,0,0,1,2.944,1.664,2.957,2.957,0,0,1,0,3.328,3.223,3.223,0,0,1-2.944,1.792h-5.5V-3.328A3.446,3.446,0,0,1,12.032-.256a3.392,3.392,0,0,1-3.584,0A3.372,3.372,0,0,1,6.784-3.328V-22.272H6.016a3.322,3.322,0,0,1-3.072-1.792,3.31,3.31,0,0,1,0-3.328,3.077,3.077,0,0,1,3.072-1.664h.768v-3.2A10.322,10.322,0,0,1,8.96-39.168a8.077,8.077,0,0,1,4.992-2.944A13.953,13.953,0,0,1,18.176-42.24Zm13.184-.512a3.1,3.1,0,0,0-2.816,2.3,2.54,2.54,0,0,0-.256,1.152v.512a3.262,3.262,0,0,0,1.664,2.944,3.171,3.171,0,0,0,3.456,0,3.262,3.262,0,0,0,1.664-2.944V-39.3a3.684,3.684,0,0,0-1.152-2.688A2.91,2.91,0,0,0,31.36-42.752Zm.256,12.288a2.9,2.9,0,0,0-2.944,2.048,2.685,2.685,0,0,0-.384,1.408V-3.456A3.262,3.262,0,0,0,29.952-.512a3.171,3.171,0,0,0,3.456,0,3.262,3.262,0,0,0,1.664-2.944V-27.008a3.322,3.322,0,0,0-1.024-2.432A3.066,3.066,0,0,0,31.616-30.464Zm11.136.64H60.416A3.04,3.04,0,0,1,63.36-27.9,3.1,3.1,0,0,1,63.1-24.32L49.664-6.784H60.416A3.262,3.262,0,0,1,63.36-5.12a3.171,3.171,0,0,1,0,3.456A3.262,3.262,0,0,1,60.416,0H42.752A3.293,3.293,0,0,1,39.68-1.92,3.239,3.239,0,0,1,40.064-5.5L53.5-23.04H42.752A3.262,3.262,0,0,1,39.808-24.7a3.171,3.171,0,0,1,0-3.456A3.262,3.262,0,0,1,42.752-29.824Z" transform="translate(274.773 424.659)" fill="#454545" />
                    <path d="M75.776-38.4a3.216,3.216,0,0,0-2.048-3.2,2.85,2.85,0,0,0-1.28-.256,3.293,3.293,0,0,0-3.072,1.92A4.134,4.134,0,0,0,69.12-38.4V-3.328A3.04,3.04,0,0,0,71.04-.384a3.758,3.758,0,0,0,1.408.256,3.133,3.133,0,0,0,3.072-1.92,3.424,3.424,0,0,0,.256-1.28Zm9.344-4.352a3.1,3.1,0,0,0-2.816,2.3,2.54,2.54,0,0,0-.256,1.152v.512a3.262,3.262,0,0,0,1.664,2.944,3.171,3.171,0,0,0,3.456,0,3.262,3.262,0,0,0,1.664-2.944V-39.3a3.684,3.684,0,0,0-1.152-2.688A2.91,2.91,0,0,0,85.12-42.752Zm.256,12.288a2.9,2.9,0,0,0-2.944,2.048,2.685,2.685,0,0,0-.384,1.408V-3.456A3.262,3.262,0,0,0,83.712-.512a3.171,3.171,0,0,0,3.456,0,3.262,3.262,0,0,0,1.664-2.944V-27.008a3.322,3.322,0,0,0-1.024-2.432A3.066,3.066,0,0,0,85.376-30.464ZM94.848-17.92a12.053,12.053,0,0,1,2.688-7.808q3.2-4.1,9.344-4.1,9.216.9,11.392,8.32a22.644,22.644,0,0,1,.512,3.584V-3.328a3.04,3.04,0,0,1-1.92,2.944A3.186,3.186,0,0,1,115.456,0a2.9,2.9,0,0,1-2.944-2.048,3.424,3.424,0,0,1-.256-1.28V-17.92q-.256-4.736-5.376-5.248-4.352,0-5.248,4.1a5.932,5.932,0,0,0-.128,1.152V-3.328A3.039,3.039,0,0,1,99.456-.384,2.073,2.073,0,0,1,98.176,0,3.125,3.125,0,0,1,95.1-2.048a3.424,3.424,0,0,1-.256-1.28ZM127.36-42.88a3.322,3.322,0,0,1,2.432,1.024,3.322,3.322,0,0,1,1.024,2.432v18.816L141.7-30.976a3.362,3.362,0,0,1,3.84-.64,4.011,4.011,0,0,1,1.024.768,3.189,3.189,0,0,1,.64,3.712,13.488,13.488,0,0,1-.768,1.152L138.5-18.56,148.1-5.5a3.083,3.083,0,0,1,.128,3.84,4.458,4.458,0,0,1-.9.9,3.075,3.075,0,0,1-3.84.128,2.54,2.54,0,0,1-.9-.768L133.5-13.824l-2.688,2.56v7.808a3.262,3.262,0,0,1-1.664,2.944,3.171,3.171,0,0,1-3.456,0,3.262,3.262,0,0,1-1.664-2.944V-39.424a3.133,3.133,0,0,1,1.92-3.072A2.685,2.685,0,0,1,127.36-42.88Z" transform="translate(274.773 424.659)" fill="#ebad5c" />
                </g>
            </g>
        </svg>
    );
};
