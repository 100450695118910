import React from 'react';
import { MediaService } from '../../services/media.service';
import { FontIcon } from 'react-md';

import './mobile-selector.component.scss';

interface SelectorProps {
    selectorId: string;
    redirecturl: string;
    media: string;
    caption: string;
    privacy?: boolean;
    highlight?: boolean;
    listview?: boolean;
    onClick?: (selectorId: string) => void;
}

export const MobileSelectorComponent = (props: SelectorProps) => {

    const handleOnClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
        if (props.onClick) {
            props.onClick(props.selectorId);
        }
        event.stopPropagation();
    };

    const className = props.highlight ? "mobile-selector highlight" : "mobile-selector";
    const className2 = props.highlight ? "mobile-selector-list highlight" : "mobile-selector-list";
    return !props.listview
        ? (
            <a className="mobile-selector" href={props.redirecturl} target="_blank" rel="noopener noreferrer" onClick={handleOnClick}>
                <div className={className}>
                    <img className="mobile-selector" src={MediaService.getMediaUrl(props.media)} alt={props.selectorId} />
                    {props.privacy ? <FontIcon className="selector-privacy">lock</FontIcon> : null}
                </div>
                <label>{props.caption}</label>
            </a>
        )
        : (
            <a className="mobile-selector-list" href={props.redirecturl} target="_blank" rel="noopener noreferrer" onClick={handleOnClick}>
                <div className={className2}>
                    <img className="mobile-selector-list" src={MediaService.getMediaUrl(props.media)} alt={props.selectorId} />
                    {props.privacy ? <FontIcon className="selector-privacy">lock</FontIcon> : null}
                </div>
                <div className="mobile-caption-list glass-background"><label>{props.caption}</label></div>
            </a>
        );
};
