import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { loadUsers } from '../../../redux/user.state';

export const UsersComponent = () => {
    const users = useSelector((state: RootState) => state.user.users);
    const dispatch = useDispatch();

    useEffect(() => {
        loadUsers("")(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // empty to only run when component is mounted

    return (
        <div>
            {users.map((u) => <div>{u._id}</div>)}
        </div>
    );
};
