import { SecuredService } from "./secured.service";
import { ApiResult } from "./api-result";
import { User } from "../models/user";

export class UserService extends SecuredService {

    public static baseUrl = 'api/users';

    public signup(payload: SignupPayload): Promise<ApiResult<any>> {
        return this.post(UserService.baseUrl + '/signup', { ...payload });
    }

    public getUser(userId: string): Promise<ApiResult<User>> {
        return this.get(UserService.baseUrl + '/' + userId);
    }

    public updateProfile(userId: string, firstname?: string, lastname?: string, email?: string, password?: string, confirmpassword?: string): Promise<ApiResult<User>> {
        return this.put(UserService.baseUrl + '/updateprofile/' + userId, { password, confirmpassword, contact: { firstname, lastname, email } });
    }

    public updatePassword(userId: string, password?: string, confirmpassword?: string): Promise<ApiResult<User>> {
        return this.put(UserService.baseUrl + '/updateprofile/' + userId, { password, confirmpassword });
    }

    public forgotPasswordUsername(username: string): Promise<ApiResult<any>> {
        return this.put(UserService.baseUrl + '/forgotpasswordusername/' + encodeURI(username));
    }
    
    public forgotPasswordEmail(email: string): Promise<ApiResult<any>> {
        return this.put(UserService.baseUrl + '/forgotpasswordemail/' + encodeURI(email));
    }

    public resetPassword(userId: string, password: string): Promise<ApiResult<any>> {
        return this.put(UserService.baseUrl + '/resetpassword/' + userId, { password });
    }

    public invite(emails: string[]): Promise<ApiResult<any>> {
        return this.post(UserService.baseUrl + '/invite', { emails });
    }
}

export interface SignupPayload {
    firstname: string;
    lastname: string;
    email: string;
    username: string;
    password: string;
    confirmpassword: string;
    followingid?: string;
    facebookid?: string;
    googleid?: string;
}