import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Following } from '../models/following';
import { FollowingService } from '../services/following.service';
import { Follower } from '../models/follower';
import { extractData } from './authentication.state';

export interface FollowingState {
    followings: Following[];
    followers: Follower[];
}

const initialState: FollowingState = {
    followings: [],
    followers: [],
};

const loadFollowings = (state: FollowingState, action: PayloadAction<Following[]>) => {
    state.followings = action.payload;
}

const loadFollowers = (state: FollowingState, action: PayloadAction<Follower[]>) => {
    state.followers = action.payload;
}

const addFollowingReducer = (state: FollowingState, action: PayloadAction<Following>) => {
    state.followings.push(action.payload);
}

const deleteFollowingReducer = (state: FollowingState, action: PayloadAction<string>) => {
    state.followings = state.followings.filter((f) => f.followingid._id !== action.payload);
}

const { reducer, actions } = createSlice({
    name: 'following',
    initialState,
    reducers: {
        loadFollowings: loadFollowings,
        loadFollowers: loadFollowers,
        addFollowing: addFollowingReducer,
        deleteFollowing: deleteFollowingReducer,
    },
});

export { reducer as FollowingReducer };
export const { addFollowing } = actions;

export const loadAllFollowings = () => {
    return (async (dispatch: any) => {
        const service = new FollowingService();
        const res = await service.getFollowings();
        const followings = extractData(res, dispatch) || [];
        dispatch(actions.loadFollowings(followings));
    });
};

export const loadAllFollowers = () => {
    return (async (dispatch: any) => {
        const service = new FollowingService();
        const res = await service.getFollowers();
        const followers = extractData(res, dispatch) || [];
        dispatch(actions.loadFollowers(followers));
    });
};

export const unfollow = (id: string) => {
    return (async (dispatch: any) => {
        const service = new FollowingService();
        await service.unfollowFollowing(id);
        dispatch(actions.deleteFollowing(id));
    });
};
