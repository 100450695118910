import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Selector } from '../models/selector';
import { SelectorService } from '../services/selector.service';
import { extractData } from './authentication.state';
import { DefaultSelectorService } from '../services/default-selector.service';

export interface SelectorState {
    selectors: Selector[];
    defaultSelectors: Selector[];
}

const initialState: SelectorState = {
    selectors: [],
    defaultSelectors: [],
};

const loadSelectorsReducer = (state: SelectorState, action: PayloadAction<Selector[]>) => {
    state.selectors = action.payload;
};

const loadDefaultSelectorsReducer = (state: SelectorState, action: PayloadAction<Selector[]>) => {
    state.defaultSelectors = action.payload;
}

const addSelectorReducer = (state: SelectorState, action: PayloadAction<Selector>) => {
    state.selectors.push(action.payload);
};

const updateSelectorReducer = (state: SelectorState, action: PayloadAction<Selector>) => {
    const selector = action.payload;
    const sIdx = state.selectors.findIndex((s) => s._id === selector._id);
    if (sIdx > -1) {
        state.selectors[sIdx] = selector;
    }
    else {
        state.selectors.push(selector);
    }
};

const deleteSelectorReducer = (state: SelectorState, action: PayloadAction<string>) => {
    state.selectors = state.selectors.filter((s) => s._id !== action.payload);
};

const { reducer, actions } = createSlice({
    name: 'selector',
    initialState,
    reducers: {
        loadSelectors: loadSelectorsReducer,
        loadDefaultSelectors: loadDefaultSelectorsReducer,
        addSelector: addSelectorReducer,
        updateSelector: updateSelectorReducer,
        deleteSelector: deleteSelectorReducer,
    },
});

export { reducer as SelectorReducer };

export const loadSelectors = () => {
    return (async (dispatch: any) => {
        const service = new SelectorService();
        const res = await service.getSelectors();
        const selectors = extractData(res, dispatch) || [];
        dispatch(actions.loadSelectors(selectors));
    });
};

export const loadDefaultSelectors = () => {
    return (async (dispatch: any) => {
        const service = new DefaultSelectorService();
        const res = await service.getSelectors();
        const selectors = extractData(res, dispatch) || [];
        let sortedSelectors = selectors.sort((a, b) => (a.name?.toLowerCase() ?? "") > (b.name?.toLowerCase() ?? "") ? 1 : -1);
        const fileIdx = selectors.findIndex((s) => s.name === 'File');
        if (fileIdx > -1) {
            const fileSelector = sortedSelectors.splice(fileIdx, 1);
            sortedSelectors = [...fileSelector, ...sortedSelectors];
        }
        const createIdx = sortedSelectors.findIndex((s) => s.name === 'Create...');
        if (createIdx > -1) {
            const createSelector = sortedSelectors.splice(createIdx, 1);
            sortedSelectors = [...createSelector, ...sortedSelectors];
        }
        dispatch(actions.loadDefaultSelectors(sortedSelectors));
    });
};

export const addSelector = (selector: any, onAdded: (selector: Selector) => void) => {
    return (async (dispatch: any) => {
        const service = new SelectorService();
        const res = await service.addSelector(selector);
        if (res.data) {
            dispatch(actions.addSelector(res.data));
            onAdded(res.data);
        }
    });
};

export const updateSelector = (selector: Selector) => {
    return (async (dispatch: any) => {
        const service = new SelectorService();
        const res = await service.updateSelector(selector);
        const updatedSelector = extractData(res, dispatch);
        if (updatedSelector) {
            dispatch(actions.updateSelector(updatedSelector));
        }
    });
};

export const deleteSelector = (selectorId: string) => {
    return (async (dispatch: any) => {
        const service = new SelectorService();
        await service.deleteSelector(selectorId);
        dispatch(actions.deleteSelector(selectorId));
    });
};
