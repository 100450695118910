import React from 'react';

import './horizontal-loader.scss';

export const HorizontalLoader = () => {
    return (
        <div className="horizontal-loader">
            <div className="bounce1" />
            <div className="bounce2" />
            <div className="bounce3" />
            <div className="bounce4" />
        </div>
    );
};
