import React, { useState } from 'react';
import { PageWrapper } from './page-wrapper.component';
import { Button, TextField } from 'react-md';
import { useHistory } from 'react-router-dom';
import { INSTAGRAM, TWITTER, FACEBOOK, LINKEDIN, TIKTOK, SNAPCHAT, PHONE, EMAIL, FIZLINK } from '../preview/base64.lib';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../locales/keys';

import './bubbling.scss';

export const TitlePage = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [username, setUsername] = useState("");

    const goToLogin = () => {
        history.push("/signup?username=" + username);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
        if (event.keyCode === 13) {
            goToLogin();
        }
    };

    var iconIdx = 0;
    const bubbles = [FIZLINK, INSTAGRAM, TWITTER, FACEBOOK, LINKEDIN, TIKTOK, SNAPCHAT, PHONE, EMAIL, FIZLINK];

    return (
        <PageWrapper style={{ maxWidth: '100%', height: '100%', position: 'relative', padding: '16px', overflowX: 'hidden' }}>
            <div id="background-wrap">
                <div className="bubble x1" style={{ backgroundImage: `url(${bubbles[iconIdx++]})`}}></div>
                <div className="bubble x2" style={{ backgroundImage: `url(${bubbles[iconIdx++]})`}}></div>
                <div className="bubble x3" style={{ backgroundImage: `url(${bubbles[iconIdx++]})`}}></div>
                <div className="bubble x4" style={{ backgroundImage: `url(${bubbles[iconIdx++]})`}}></div>
                <div className="bubble x5" style={{ backgroundImage: `url(${bubbles[iconIdx++]})`}}></div>
                <div className="bubble x6" style={{ backgroundImage: `url(${bubbles[iconIdx++]})`}}></div>
                <div className="bubble x7" style={{ backgroundImage: `url(${bubbles[iconIdx++]})`}}></div>
                <div className="bubble x8" style={{ backgroundImage: `url(${bubbles[iconIdx++]})`}}></div>
                <div className="bubble x9" style={{ backgroundImage: `url(${bubbles[iconIdx++]})`}}></div>
                <div className="bubble x10" style={{ backgroundImage: `url(${bubbles[iconIdx++]})`}}></div>
            </div>
            <span className="home-title">{t(LocalizationKeys.HomeTitle)}</span>
            <h2 className="home-subheader">{t(LocalizationKeys.HomeSubheader)}</h2>
            <div className="home-input">
                <label className="redirecturl-input" data-domain={`${window.location.host}/`}>
                    <TextField
                        id="username-title-input"
                        placeholder={t(LocalizationKeys.HomeInputPlaceholder)}
                        tabIndex={-1}
                        type="text"
                        value={username}
                        onChange={(val) => setUsername(val.toString())}
                        onKeyDown={handleKeyDown}
                    />
                    <Button style={{ marginLeft: '16px' }} icon={true} primary={true} swapTheming={true} onClick={goToLogin}>arrow_forward</Button>
                </label>
            </div>
        </PageWrapper>
    );
};
