import { SecuredService } from "./secured.service";
import { ApiResult } from "./api-result";
import { User } from "../models/user";

export class AdminUserService extends SecuredService {

    private baseUrl = 'api/users';

    public getUsers(entityId: string): Promise<ApiResult<User[]>> {
        return this.get(`${this.baseUrl}/${entityId}`);
    }
}