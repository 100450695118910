import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Tag } from '../models/tag';
import { TagService } from '../services/tag.service';
import { getMobile } from './mobile.state';
import { extractData } from './authentication.state';

export interface TagState {
    tags: Tag[];
    selectedTagId?: string;
}

const initialState: TagState = {
    tags: [],
    selectedTagId: undefined,
};

const loadTagsReducer = (state: TagState, action: PayloadAction<Tag[]>) => {
    state.tags = action.payload;
};

const updateTagReducer = (state: TagState, action: PayloadAction<Tag>) => {
    const tag = action.payload;
    const tIdx = state.tags.findIndex((t) => t._id === tag._id);
    if (tIdx > -1) {
        state.tags[tIdx] = tag;
    }
    else {
        state.tags.push(tag);
    }
};

const setSelectedTagReducer = (state: TagState, action: PayloadAction<string | undefined>) => {
    state.selectedTagId = action.payload;
};

const { reducer, actions } = createSlice({
    name: 'tag',
    initialState,
    reducers: {
        loadTags: loadTagsReducer,
        updateTag: updateTagReducer,
        setSelectedTag: setSelectedTagReducer,
    },
});

export { reducer as TagReducer };

export const loadTags = () => {
    return (async (dispatch: any) => {
        const service = new TagService();
        const res = await service.getTags();
        const tags = extractData(res, dispatch) || [];
        dispatch(actions.loadTags(tags));
        if (tags.length) {
            dispatch(actions.setSelectedTag(tags[0]._id));
            getMobile(tags[0].mobile)(dispatch);
        }
    });
};

export const updateTag = (tag: Tag) => {
    return (async (dispatch: any) => {
        const service = new TagService();
        const res = await service.updateTag(tag);
        const updatedTag = extractData(res, dispatch);
        if (updatedTag) {
            dispatch(actions.updateTag(updatedTag));
        }
    });
};
