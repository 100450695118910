import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { LinkPreviewComponent } from '../preview/link-preview.component';
import { FizLinkWidget } from './fiz-link-widget.component';
import { addSelector, updateSelector, deleteSelector } from '../../redux/selector.state';
import { SelectorComponent } from './widgets/selector.component';
import { updateMobile, setSelectedMobile } from '../../redux/mobile.state';
import { MobileSelector } from '../../models/mobile-selector';
import { loadMobileCollectionsCount } from '../../redux/mobile-collection.state';
import { loadMobileConnections } from '../../redux/mobile-connection.state';
import { AddSelectorComponent } from './widgets/add-selector.component';
import { Selector } from '../../models/selector';
import axios from 'axios';
import { MediaService } from '../../services/media.service';
import { QrProfile } from './qr-profile.component';
import { useQr } from '../../hooks/qr.hooks';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { showOnboarding, showProfileOnboarding } from '../../redux/authentication.state';

import './overview.component.scss';

export const OverviewComponent = () => {
    const token = useSelector((state: RootState) => state.authentication.token);
    const user = useSelector((state: RootState) => state.user.selectedUser);
    const selectors = useSelector((state: RootState) => state.selector.selectors).slice().sort((s1, s2) => (s1.name ?? "") > (s2.name ?? "") ? 1 : -1);
    const defaultSelectors = useSelector((state: RootState) => state.selector.defaultSelectors);
    const { tags, selectedTagId } = useSelector((state: RootState) => state.tag);
    const selectedTag = tags.find((t) => t._id === selectedTagId);
    const { mobiles } = useSelector((state: RootState) => state.mobile);
    const selectedMobile = mobiles.find((m) => m._id === selectedTag?.mobile);
    const mobileSelectors = !selectedMobile ? [] : selectedMobile.selectors;
    const collectionsCount = useSelector((state: RootState) => state.mobilecollection.collectionsCount);
    const connections = useSelector((state: RootState) => state.mobileconnection.connections);
    const followers = useSelector((state: RootState) => state.following.followers);
    const [dragging, setDragging] = useState<MobileSelector[] | undefined>(undefined);
    const showQr = useQr();
    const dispatch = useDispatch();
    const selectorsEndRef = useRef(null);
    const [newSelector, setNewSelector] = useState<string | undefined>();

    useEffect(() => {
        if (selectedTag) {
            loadMobileCollectionsCount(selectedTag._id)(dispatch);
            loadMobileConnections(selectedTag._id)(dispatch);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTag]);

    useEffect(() => {
        dispatch(setSelectedMobile(selectedMobile?._id));
        if (selectedMobile?.pages[0]) {
            const onboarding = selectedMobile?.pages[0].onboarding;
            if (!onboarding) {
                dispatch(showOnboarding(true));
                dispatch(showProfileOnboarding(true));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMobile]);

    const scrollToBottom = async (selectorId: string) => {
        if (selectedMobile?.selectors) {
            const top = +(selectorsEndRef.current as any)?.offsetTop
            for (var y = 0; y <= top; y += 100) {
                window.scrollTo({ top: y, behavior: 'smooth' });
                await new Promise((res) => setTimeout(res, 5));
            }
            setNewSelector(selectorId);
        }
    };

    const addSelectorToMobile = (selector: Selector) => {
        if (selectedMobile) {
            const updatedSelectors = [...selectedMobile.selectors];
            updatedSelectors.push({ selector: selector._id, redirecturl: selector.redirecturl ?? "", value: "", privacy: false, listview: false });
            updateMobile({ ...selectedMobile, selectors: updatedSelectors }, () => scrollToBottom(selector._id))(dispatch);
        }
    };

    const onAddLink = (selectorId: string) => {
        const selector = defaultSelectors.find((s) => s._id === selectorId);
        if (selector && selectedMobile) {
            const caption = selector.name ?? "";
            const duplicate: Selector = { ...selector, caption: { en: caption, fr: caption } };
            delete duplicate._id;
            delete duplicate.defaultselector;
            axios.get(MediaService.getMediaUrl(duplicate.media), {
                responseType: 'arraybuffer'
            })
                .then((response) => {
                    const media = Buffer.from(response.data, 'binary').toString('base64');
                    addSelector({ ...duplicate, media, mediatype: response.headers['content-type'].toLowerCase() }, (newSelector) => addSelectorToMobile(newSelector))(dispatch);
                });
        }
    };

    const handleDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }
        if (result.destination.index === result.source.index) {
            return;
        }
        const res = Array.from(mobileSelectors);
        const [removed] = res.splice(result.source.index, 1);
        res.splice(result.destination.index, 0, removed);
        setDragging(res);
        if (selectedMobile) {
            const onDone = () => setDragging(undefined);
            updateMobile({ ...selectedMobile, selectors: res }, onDone, onDone)(dispatch);
        }
    };

    const handleLinkChange = (selectorId: string, value: string) => {
        const sIdx = mobileSelectors.findIndex((s) => s.selector === selectorId);
        const selector = sIdx > -1 ? mobileSelectors[sIdx] : undefined;
        if (selector && selectedMobile) {
            var enhancedValue = value;
            // eslint-disable-next-line no-template-curly-in-string
            var redirecturl = selector.redirecturl.replace('${value}', '');
            if (!redirecturl.length && !value.startsWith('http://') && !value.startsWith('https://')) {
                enhancedValue = 'http://' + enhancedValue;
            }
            const updatedSelectors = [...mobileSelectors];
            const updatedSelector = { ...selector, value: enhancedValue };
            updatedSelectors.splice(sIdx, 1, updatedSelector);
            updateMobile({ ...selectedMobile, selectors: updatedSelectors })(dispatch);
            setNewSelector(undefined);
        }
    };

    const handleSelectorNameChange = (selectorId: string, name: string) => {
        const selector = selectors.find((s) => s._id === selectorId);
        if (selector) {
            updateSelector({ ...selector, caption: { en: name, fr: name } })(dispatch);
            setNewSelector(undefined);
        }
    };

    const handleSelectorPrivacyChange = (selectorId: string, privacy: boolean) => {
        const sIdx = mobileSelectors.findIndex((s) => s.selector === selectorId);
        const selector = sIdx > -1 ? mobileSelectors[sIdx] : undefined;
        if (selector && selectedMobile) {
            const updatedSelectors = [...mobileSelectors];
            const updatedSelector = { ...selector, privacy };
            updatedSelectors.splice(sIdx, 1, updatedSelector);
            updateMobile({ ...selectedMobile, selectors: updatedSelectors })(dispatch);
        }
    };

    const handleSelectorHighlightChange = (selectorId: string, highlight: boolean) => {
        const sIdx = mobileSelectors.findIndex((s) => s.selector === selectorId);
        const selector = sIdx > -1 ? mobileSelectors[sIdx] : undefined;
        if (selector && selectedMobile) {
            const updatedSelectors = [...mobileSelectors.map((s) => { return { ...s, highlight: false } })];
            const updatedSelector = { ...selector, highlight };
            updatedSelectors.splice(sIdx, 1, updatedSelector);
            updateMobile({ ...selectedMobile, selectors: updatedSelectors })(dispatch);
        }
    };

    const handleSelectorListViewChange = (selectorId: string, listview: boolean) => {
        const sIdx = mobileSelectors.findIndex((s) => s.selector === selectorId);
        const selector = sIdx > -1 ? mobileSelectors[sIdx] : undefined;
        if (selector && selectedMobile) {
            const updatedSelectors = [...mobileSelectors];
            const updatedSelector = { ...selector, listview };
            updatedSelectors.splice(sIdx, 1, updatedSelector);
            updateMobile({ ...selectedMobile, selectors: updatedSelectors })(dispatch);
        }
    };

    const handleSelectorMediaChange = (selectorId: string, selectormedia: any, mediacontent: string) => {
        const selector = selectors.find((s) => s._id === selectorId);
        if (selector) {
            updateSelector({ ...selector, selectormedia, mediacontent })(dispatch);
        }
    };

    const handleSelectorFileChange = async (selectorId: string, file: any, type: string) => {
        const mediaRes = await new MediaService().uploadMedia(file, type);
        if (!mediaRes.success || !mediaRes.data) {
            return;
        }
        const sIdx = mobileSelectors.findIndex((s) => s.selector === selectorId);
        const selector = sIdx > -1 ? mobileSelectors[sIdx] : undefined;
        if (selector && selectedMobile) {
            const updatedSelectors = [...mobileSelectors];
            const updatedSelector = { ...selector, value: mediaRes.data };
            updatedSelectors.splice(sIdx, 1, updatedSelector);
            updateMobile({ ...selectedMobile, selectors: updatedSelectors })(dispatch);
        }
    };

    const handleDeleteSelector = (selectorId: string) => {
        const selector = selectedMobile?.selectors.find((s) => s.selector === selectorId);
        if (selector && selectedMobile) {
            const selectorToDelete = selectors.find((s) => s._id === selector.selector);
            if (selectorToDelete && !selectorToDelete.defaultselector) {
                deleteSelector(selectorToDelete._id)(dispatch);
            }
            const updatedSelectors = selectedMobile.selectors.filter((s) => s.selector !== selectorId);
            updateMobile({ ...selectedMobile, selectors: updatedSelectors })(dispatch);
        }
    };

    const renderSelector = (mobileSelector: MobileSelector, index: number): JSX.Element | null => {
        const selector = selectors.find((s) => s._id === mobileSelector.selector);
        return !selector ? null : (
            <SelectorComponent
                index={index}
                key={`selector-widget-${mobileSelector.selector}`}
                selector={selector}
                mobileSelector={mobileSelector}
                clicks={collectionsCount.find((c) => c.selector === selector._id)?.count ?? 0}
                isEditing={newSelector === mobileSelector.selector}
                onUpdateSelector={handleSelectorNameChange}
                onUpdatePrivacy={handleSelectorPrivacyChange}
                onUpdateHighlight={handleSelectorHighlightChange}
                onToggleLinkView={handleSelectorListViewChange}
                onUpdateMedia={handleSelectorMediaChange}
                onUpdateFile={handleSelectorFileChange}
                onLinkChange={handleLinkChange}
                onDelete={handleDeleteSelector}
            />
        );
    };

    const totalClicks = collectionsCount.reduce((total, c) => { return total + c.count; }, 0);

    const renderOverviewGrid = (): JSX.Element => {
        const droppableStyle = {
            width: '100%',
            maxWidth: '700px',
        };
        return (
            <div className="overview-grid">
                <FizLinkWidget link={`/${selectedTag?.votieurl ?? ""}`} mobile={selectedMobile} views={connections} clicks={totalClicks} followers={followers.length} />
                <AddSelectorComponent selectors={defaultSelectors} onAdd={onAddLink} />
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="list">
                        {provided => (
                            <div ref={provided.innerRef} {...provided.droppableProps} style={droppableStyle}>
                                {(dragging && dragging.length ? dragging : mobileSelectors).map(renderSelector)}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <div ref={selectorsEndRef} />
            </div>
        );
    };

    const renderPreview = (): JSX.Element => {
        return (
            <div className="overview-preview">
                {selectedMobile ? <LinkPreviewComponent mobile={selectedMobile} selectors={selectors} mobileSelectors={mobileSelectors} size={12} /> : null}
            </div>
        );
    };

    return (
        <div className="overview">
            {!showQr ? renderOverviewGrid() : null}
            {!showQr ? renderPreview() : null}
            {showQr && selectedMobile && selectedTag ? <QrProfile fullname={user?.contact?.fullname ?? token?.fullname} username={token?.username} votieurl={selectedTag.votieurl} picture={selectedMobile.pages[0].picture} title={selectedMobile.pages[0].title} /> : null}
        </div>
    );
};
