import { SecuredService } from "./secured.service";
import { ApiResult } from "./api-result";
import { UserSearch } from '../models/user-search';

export class SearchService extends SecuredService {

    private baseUrl = 'api/search';

    public search(query: string, page = 1, limit = 20): Promise<ApiResult<UserSearch[]>> {
        const encodedQuery = encodeURI(query);
        return this.get(`${this.baseUrl}?query=${encodedQuery}&page=${page}&limit=${limit}`);
    }

    public searchAll(query: string, page = 1, limit = 20): Promise<ApiResult<UserSearch[]>> {
        const encodedQuery = encodeURI(query);
        return this.get(`${this.baseUrl}/all?query=${encodedQuery}&page=${page}&limit=${limit}`);
    }
}