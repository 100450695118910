import React, { useState, useEffect, useRef } from 'react';
import { Button } from '@react-md/button';
import { onboardingPages, Onboarding, AddToHomescreenOnboarding, OnboardingReminder } from './onboarding';
import { createPortal } from 'react-dom';
import { useMobileDevice } from '../../hooks/device.hooks';

import './onboarding.component.scss';

interface OnboardingProps {
    visible: boolean;
    onDone?: () => void;
}

export const OnboardingComponent = (props: OnboardingProps) => {
    const [page, setPage] = useState(0);
    const mobileDevice = useMobileDevice();
    const onboardingRef = useRef<HTMLDivElement>(null);

    const updatedOnboardingPages = [...onboardingPages];
    if (!!mobileDevice) {
        updatedOnboardingPages.push(AddToHomescreenOnboarding);
    }

    updatedOnboardingPages.push(OnboardingReminder);

    useEffect(() => {
        return () => setPage(0);
    }, []);

    useEffect(() => {
        if (props.visible) {
            document.body.style.overflow = 'hidden';
            onboardingRef?.current?.focus();
        }
        else {
            document.body.style.overflow = 'unset';
            onboardingRef?.current?.blur();
        }
    }, [props.visible]);

    const handleNextPage = (): void => {
        if (page === updatedOnboardingPages.length - 1) {
            handleDone();
            return;
        }
        setPage(page + 1);
    };

    const handlePreviousPage = (): void => {
        if (page > 0) {
            setPage(page - 1);
        }
    };

    const handleDone = (): void => {
        if (props.onDone) {
            props.onDone();
        }
        setPage(0);
    };

    const handleSkip = (): void => {
        setPage(updatedOnboardingPages.length - 1);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
        event.preventDefault();
        event.stopPropagation();
        const key = event.keyCode;
        if (key === 39 || key === 9) {
            // arrow right || left tab
            handleNextPage();
        }
        else if (key === 37) {
            // arrow left
            handlePreviousPage();
        }
        else if (key === 27) {
            // esc key
            handleDone();
        }
    };

    const renderOnboardingTitleFirst = (onboarding: Onboarding): React.ReactNode => {
        return (
            <React.Fragment>
                <span className="onboarding-title">{onboarding.title}</span>
                <span className="onboarding-content">{onboarding.content}</span>
                <div className="onboarding-image">
                    {onboarding.image && <onboarding.image />}
                </div>
            </React.Fragment>
        );
    };

    const renderOnboardingImageFirst = (onboarding: Onboarding): React.ReactNode => {
        return (
            <React.Fragment>
                <div className="onboarding-image">
                    {onboarding.image && <onboarding.image />}
                </div>
                <span className="onboarding-title">{onboarding.title}</span>
                <span className="onboarding-content">{onboarding.content}</span>
            </React.Fragment>
        );
    };

    const renderOnboarding = (onboarding?: Onboarding): React.ReactNode => {
        return !onboarding ? null : (
            <div id={`onboarding-${page}`} className="onboarding-container">
                {onboarding.titleFirst ? renderOnboardingTitleFirst(onboarding) : renderOnboardingImageFirst(onboarding)}
            </div>
        );
    };

    const render = () => {
        return (
            <div ref={onboardingRef} className="onboarding" tabIndex={0} onKeyDown={handleKeyDown}>
                {renderOnboarding(updatedOnboardingPages[page])}
                <div className="onboarding-footer">
                    <div className="onboarding-stepper">
                        {updatedOnboardingPages.map((_, i) => <div className={"onboarding-step" + (i === page ? " selected" : "")} onClick={() => setPage(i)} />)}
                    </div>
                    <div className="split-buttons">
                        {page !== 0 ? <Button className="back-page" themeType="flat" style={{ color: '#fff' }} onClick={handlePreviousPage}>Back</Button> : null}
                        <Button className="next-page" themeType="outline" style={{ backgroundColor: '#F4AC3D', boxShadow: 'unset', color: '#fff' }} onClick={handleNextPage}>{page === updatedOnboardingPages.length - 1 ? 'Finish' : 'Next'}</Button>
                    </div>
                    <Button className="skip-page" themeType="flat" style={{ visibility: updatedOnboardingPages.length - 1 === page ? 'hidden' : 'visible' }} onClick={handleSkip}>Skip</Button>
                </div>
            </div>
        );
    };

    const rootContainer = document.getElementById('root');

    return !props.visible || !rootContainer ? null : createPortal(render(), rootContainer);
};
