import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { useSelectedMobile } from '../../hooks/mobile.hooks';
import { MediaService } from '../../services/media.service';
import { DEFAULT_PROFILE_PICTURE } from '../preview/base64.lib';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../locales/keys';

export const PageletProfileItem = () => {
    const { t } = useTranslation();
    const user = useSelector((state: RootState) => state.user.selectedUser);
    const [fullname] = useState(user?.contact.fullname);
    const selectedMobile = useSelectedMobile();
    const page = selectedMobile?.pages[0];
    const profilePicture = page?.picture ? MediaService.getMediaUrl(page.picture) : DEFAULT_PROFILE_PICTURE;

    return !fullname ? null : (
        <a className="pagelet-item profile" href="/profile/edit">
            {profilePicture ? <img className="pagelet-icon" src={profilePicture} alt={fullname} /> : null}
            <div className="pagelet-display">
                <span className="pagelet-title">{fullname}</span>
                <span className="pagelet-subheader">{t(LocalizationKeys.EditYourProfile)}</span>
            </div>
        </a>
    );
};
