import { Button } from '@react-md/button';
import { Dialog, DialogContent, DialogFooter } from '@react-md/dialog';
import React, { useCallback } from 'react';
import { TextField } from 'react-md';
import { useTextField } from '../../authentication/signup.component';

interface FieldDialogProps {
    visible: boolean;
    onSave: (fieldName: string) => void;
    onHide: () => void;
}

export const FieldDialog = ({ visible, onSave, onHide}: FieldDialogProps) => {
    const { bind: bindFieldName } = useTextField("");

    const handleSave = useCallback(() => {
        onSave(bindFieldName.value);
    }, [onSave, bindFieldName.value]);

    return (
        <Dialog
            id="field-dialog"
            visible={visible}
            onRequestClose={onHide}
            aria-labelledby="field-title"
            style={{ borderRadius: '16px' }}
        >
            <DialogContent>
                <h3>Create new field</h3>
                <TextField
                    {...bindFieldName}
                    placeholder="Field name..."
                />
            </DialogContent>
            <DialogFooter>
                <Button
                    id="field-cancel"
                    onClick={onHide}
                    type="button"
                >
                    Cancel
                </Button>
                <Button
                    id="field-save"
                    onClick={handleSave}
                    type="button"
                    style={{ color: '#03a9f4' }}
                >
                    Save
                </Button>
            </DialogFooter>
        </Dialog>
    );
};
