import React from 'react';
import { Dialog, DialogContent, DialogFooter } from '@react-md/dialog';
import { Button } from '@react-md/button';

interface UnfollowDialogProps {
    visible: boolean;
    fullname: string;
    username: string;
    onHide: () => void | undefined;
    onUnfollow: () => void;
}

export const UnfollowDialog = (props: UnfollowDialogProps) => {
    return (
        <Dialog
            id="unfollow-dialog"
            visible={props.visible}
            onRequestClose={props.onHide}
            aria-labelledby="unfollow-title"
            style={{ borderRadius: '16px' }}
        >
            <DialogContent>
                <h4>Are you sure you want to unfollow {props.fullname} (@{props.username})?</h4>
            </DialogContent>
            <DialogFooter>
                <Button
                    id="unfollow-cancel"
                    onClick={props.onHide}
                    type="button"
                >
                    Cancel
                </Button>
                <Button id="unfollow-unfollow" onClick={props.onUnfollow} type="reset" theme="warning">
                    Unfollow
                </Button>
            </DialogFooter>
        </Dialog>
    );
};
