import { useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from "../../../redux/reducers";
import { updateSettings } from '../../../redux/user-setting.state';

export const usePushNotifications = () => {
    const settings = useSelector((state: RootState) => state.usersetting.settings);
    const dispatch = useDispatch();

    const toggleOff = useCallback((_: number | string, event: Event) => {
        if (!settings) {
            return;
        }
        const id = `${(event.target as any).id}`.replace('-off', '');
        const pushnotifications = { ...settings.pushnotifications, [id]: false };
        const updated = { ...settings, pushnotifications: pushnotifications };
        updateSettings(updated)(dispatch);
    }, [dispatch, settings]);

    const toggleOn = useCallback((_: number | string, event: Event) => {
        if (!settings) {
            return;
        }
        const id = (event.target as any).id;
        const pushnotifications = { ...settings.pushnotifications, [id]: true };
        const updated = { ...settings, pushnotifications: pushnotifications };
        updateSettings(updated)(dispatch);
    }, [dispatch, settings]);

    return {
        pushnotifications: settings?.pushnotifications, toggleOff, toggleOn
    };
};