import React from 'react';
import { Table, TableBody, TableCell, TableHeader, TableRow } from '@react-md/table';
import { Member } from '../../../models/member';
import { ViewerItem } from '../viewer-item/viewer-item';

interface ViewerTableProps {
    viewers: Member[];
}

export const ViewerTable = ({ viewers }: ViewerTableProps) => {
    return (
        <Table fullWidth={true}>
            <TableHeader>
                <TableRow>
                    <TableCell>Status</TableCell>
                    <TableCell>User</TableCell>
                </TableRow>
            </TableHeader>
            <TableBody>
                {viewers.map((m) => {
                    return <ViewerItem id={m.user._id} fullname={m.user.contact.fullname} username={m.user.username} valid={m.valid} />
                })}
            </TableBody>
        </Table>
    );
};
