import React from 'react';
import { MediaService } from '../../services/media.service';
import { DEFAULT_PROFILE_PICTURE } from '../preview/base64.lib';
import { FontIcon } from 'react-md';
import { Verified } from '../svg/verified';

import './mobile-identity.component.scss';

interface MobileProfileProps {
    info: {
        fullname: string;
        username: string;
        title?: string;
        bio?: string;
        image?: string;
        verified?: boolean;
    }
    onImageClick?: () => void;
    children?: React.ReactChild;
}

export const MobileProfileComponent = (props: MobileProfileProps) => {
    const { fullname, username, title, bio, image, verified } = props.info;
    const source = image ? MediaService.getMediaUrl(image) : DEFAULT_PROFILE_PICTURE;
    return (
        <div className="mobile-identity">
            <div className="picture-container" style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center' }} onClick={props.onImageClick}>
                <img className="profilepicture" alt="Profile" src={source} />
                {!!props.onImageClick ? <FontIcon className="picture-edit">add_a_photo</FontIcon> : null}
            </div>
            <div className="mobile-profile">
                <div className="idname">
                    {fullname}
                </div>
                <div className="idname">
                    @{username}{verified && <Verified width={20} />}
                </div>
                {!!title ? <div className="idtitle">{title}</div> : null}
                {!!bio ? <div className="idbio">{bio}</div> : null}
                {props.children}
            </div>
        </div>
    );
};
