import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { TextField, FontIcon, Tabs, Tab } from 'react-md';
import { ContactComponent } from './contact.component';
import { ContactFollowerComponent } from './contact-follower.component';
import { Following } from '../../models/following';
import { Follower } from '../../models/follower';
import { FollowerRequestComponent } from './follower-request.component';
import { FollowerDialogComponent } from './follower.dialog.component';
import { loadAllFollowings, loadAllFollowers, unfollow } from '../../redux/following.state';
import { UserSearchComponent } from './user-search.component';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../locales/keys';

import './contacts.component.scss';

export const ContactsComponent = () => {
    const { t } = useTranslation();
    const [showFollowerDialog, toggleFollowerDialog] = useState(false);
    const [searchPagination, setSearchPagination] = useState(1);
    const [activeIndex, setActiveIndex] = useState(0);
    const dispatch = useDispatch();

    const followingSort = (a: Following, b: Following): number => {
        return a.followingid?.fullname > b.followingid?.fullname ? 1 : -1;
    };

    const followerSort = (a: Follower, b: Follower): number => {
        return a.userid?.fullname > b.userid?.fullname ? 1 : -1;
    };

    const following = [...useSelector((state: RootState) => state.following.followings)].sort(followingSort);
    const stateFollowers = useSelector((state: RootState) => state.following.followers);
    const followers = [...stateFollowers].filter((f) => f.status?.code === 'act').sort(followerSort);
    const pending = [...stateFollowers].filter((f) => f.status?.code === 'req').sort(followerSort);
    const [searchText, setSearchText] = useState("");

    const handleSearch = (value: React.Key): void => {
        const val = value.toString();
        setSearchText(val);
        setSearchPagination(1);
    };

    const handleOnHideFollowerDialog = (refreshFollowing: boolean, refreshFollowers: boolean): void => {
        toggleFollowerDialog(false);
        if (refreshFollowing) {
            loadAllFollowings()(dispatch);
        }
        if (refreshFollowers) {
            loadAllFollowers()(dispatch);
        }
    };

    const handleUnfollow = useCallback((id: string): void => {
        unfollow(id)(dispatch);
    }, [dispatch]);

    const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>): void => {
        const target = e.target as any;
        const bottom = target.scrollHeight - target.scrollTop === target.clientHeight;
        if (bottom) {
            setSearchPagination(searchPagination + 1);
        }
    };

    const searchComponent = useCallback((): JSX.Element => {
        return (
            <TextField
                value={searchText}
                leftIcon={<FontIcon>search</FontIcon>}
                placeholder={t(LocalizationKeys.SearchContacts)}
                onChange={handleSearch}
            />
        );
    }, [t, searchText]);

    const filter = useCallback(() => {
        const lowerCaseSearch = searchText.toLowerCase();
        return following.filter((f) => {
            return f.followingid?.fullname?.toLowerCase()?.includes(lowerCaseSearch) || f.followingid?.username?.toLowerCase()?.includes(lowerCaseSearch);
        });
    }, [searchText, following]);

    const filterFollowers = useCallback(() => {
        const lowerCaseSearch = searchText.toLowerCase();
        return followers.filter((f) => {
            return f.userid?.fullname?.toLowerCase()?.includes(lowerCaseSearch) || f.userid?.username?.toLowerCase()?.includes(lowerCaseSearch);
        });
    }, [searchText, followers]);

    const renderFollowing = useCallback(() => {
        return (
            searchText ? filter() : following).map((f) => <ContactComponent follow={f} onUnfollow={handleUnfollow} />
        );
    }, [searchText, following, filter, handleUnfollow]);

    const renderFollower = useCallback(() => {
        return (
            (searchText ? filterFollowers() : followers).map((f) => <ContactFollowerComponent follower={f} />)
        );
    }, [searchText, followers, filterFollowers]);

    return (
        <div className="contact-list" onScroll={handleScroll}>
            <Tabs tabId="following-followers" activeTabIndex={activeIndex} onTabChange={setActiveIndex} centered={true} inactiveTabClassName="md-text--secondary">
                <Tab label={t(LocalizationKeys.NumberFollowing, { count: following.length })} />
                <Tab label={followers.length !== 1 ? t(LocalizationKeys.NumberFollowers, { count: followers.length }) : t(LocalizationKeys.OneFollower)} />
            </Tabs>
            {searchComponent()}
            {!!pending.length ? <FollowerRequestComponent count={pending.length} onClick={() => toggleFollowerDialog(true)} /> : null}
            {activeIndex === 0 ? renderFollowing() : renderFollower()}
            <UserSearchComponent query={searchText} page={searchPagination} />
            {showFollowerDialog ? <FollowerDialogComponent following={following} pending={pending} visible={showFollowerDialog} onHide={handleOnHideFollowerDialog} /> : null}
        </div>
    );
};
