import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { RootState } from '../../redux/reducers';
import { TextField } from 'react-md';
import { HorizontalLoader } from '../horizontal-loader';
import { Button } from '../button';
import { useTextField } from './signup.component';
import { UserService } from '../../services/user.service';
import { SuccessErrorMessage } from './successerrormessage.component';
import { useTranslation } from 'react-i18next';

import './authentication.component.scss';
import { LocalizationKeys } from '../../locales/keys';

export const ForgotPassword = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [message, showMessage] = useState(false);
    const { value: username, bind: bindUsername } = useTextField('');
    const token = useSelector((state: RootState) => state.authentication.token);
    const history = useHistory();

    const handleOnForgotPassword = () => {
        setLoading(false);
        showMessage(true);
    };

    const isEmail = (value: string): boolean => {
        return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value);
    }

    const onForgotPassword = (event: React.FormEvent<HTMLFormElement>) => {
        if (username) {
            setLoading(true);
            const service = new UserService();
            const call = isEmail(username) ? service.forgotPasswordEmail(username) : service.forgotPasswordUsername(username);
            call.then(() => {
                    handleOnForgotPassword();
                })
                .catch(() => {
                    handleOnForgotPassword();
                });
        }
        event.preventDefault();
    }

    if (token) {
        return <Redirect to="/" />;
    }

    let tabIndex = 0;

    const renderForm = () => {
        return (
            <form className="form" onSubmit={onForgotPassword}>
                <div className="forgot-password">
                    <span className="header">{t(LocalizationKeys.ForgotYourPassword)}</span>
                    <span className="subheader">{t(LocalizationKeys.EnterEmailOrUsername)}</span>
                </div>
                <div tabIndex={tabIndex++} />
                <TextField fullWidth={true} id="username" type="text" label={t(LocalizationKeys.EmailOrUsername)} {...bindUsername} tabIndex={tabIndex++} />
                <Button type="submit" className="submit" disabled={!username || loading}>{loading ? <HorizontalLoader /> : t(LocalizationKeys.SendResetLink)}</Button>
            </form>
        );
    };

    return message ? <SuccessErrorMessage title={t(LocalizationKeys.PasswordResetTitle)} subtitle1={t(LocalizationKeys.PasswordResetSubtitle)} onClick={() => history.push('/login')} /> : renderForm();
};
