import React, { useState } from 'react';
import { NavigationItem } from './navigation-item';
import { Toolbar } from '../toolbar/toolbar.component';
import { FontIcon } from 'react-md';
import { useHistory } from 'react-router-dom';
import { useQr } from '../../hooks/qr.hooks';

import './navigation-drawer.scss';

interface NavigationDrawerProps {
    items: NavigationItem[];
    children?: any;
}

export const NavigationDrawer = (props: NavigationDrawerProps) => {
    const [open, setOpen] = useState(false);
    const [peak, setPeak] = useState(false);
    const showQr = useQr();
    const history = useHistory();

    const renderItem = (item: NavigationItem) => {
        const selected = window.location.pathname === item.path;
        const show = open || peak;
        const className = selected ? "nav-item selected" : "nav-item";
        return (
            <div className={className} onClick={() => history.push(item.path)} onMouseEnter={() => setPeak(true)} onMouseLeave={() => setPeak(false)}>
                <FontIcon>{item.icon}</FontIcon>
                <div style={{ visibility: !show ? 'hidden' : 'visible', opacity: !show ? '0' : '1' }}>{item.name}</div>
            </div>
        );
    };

    const renderSideNav = (): JSX.Element => {
        return (
            <div>
                <div className="sidenav" style={{ width: open || peak ? '280px' : '72px' }}>
                    {props.items.map((i) => renderItem(i))}
                </div>
                <div className="nav-content" style={{ marginLeft: open ? '280px' : '72px', marginTop: showQr ? '0px' : '64px' }}>
                    {props.children}
                </div>
            </div>
        );
    };

    const isSuperAdmin = false; // replace with check if logged in user is super admin

    return (
        <div className="dashboard">
            {!showQr ? <Toolbar hideMenu={!isSuperAdmin} onNavigationClick={() => setOpen(!open)} /> : null}
            {isSuperAdmin ? renderSideNav() : <div className="nav-content" style={{ marginTop: showQr ? '0px' : '64px' }}>{props.children}</div>}
        </div>
    );
};
