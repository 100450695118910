import React from 'react';

import './generic-phone.scss';

interface GenericPhoneProps {
    children?: any;
}

export const GenericPhone = (props: GenericPhoneProps) => {
    return (
        <div className="phone">
            <div className="phone-content">
                {props.children}
            </div>
        </div>
    );
};
