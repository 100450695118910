import { useCallback } from "react";
import { updateViewer } from '../../../redux/member.state';
import { useDispatch } from 'react-redux';

export interface ViewerItemProps {
    id: string;
    fullname: string;
    username: string;
    valid?: boolean;
}

export const useViewerItem = ({ id, fullname, username, valid }: ViewerItemProps) => {
    const dispatch = useDispatch();

    const toggleValidity = useCallback(() => {
        updateViewer(id, !valid)(dispatch);
    }, [dispatch, id, valid]);

    return {
        id, fullname, username, valid, toggleValidity
    }
};