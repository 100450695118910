export const LocalizationKeys = {
    Edit: "edit",
    Profile: "profile",
    Download: "download",
    Share: "share",
    Invite: "invite",
    EditProfile: "edit-profile",
    EditYourProfile: "edit-your-profile",
    WhatsNew: "whats-new",
    Logout: "logout",
    Lock: "lock",
    Unlock: "unlock",
    Delete: "delete",
    Add: "add",
    CheckOutMyFizlink: "check-out-my-fizlink",
    CheckOutThisFizlink: "check-out-this-fizlink",
    InviteOthers: "invite-others",
    Emails: "emails",
    SeparateEmailsByComma: "separate-emails-by-comma",
    Cancel: "cancel",
    ChangePhoto: "change-photo",
    UploadFile: "upload-file",
    Save: "save",
    Close: "close",
    FirstName: "first-name",
    LastName: "last-name",
    Email: "email",
    Title: "title",
    TitleHelperText: "title-helper-text",
    Bio: "bio",
    BioHelperText: "bio-helper-text",
    ChangePassword: "change-password",
    NewPassword: "new-password",
    ConfirmNewPassword: "confirm-new-password",
    PasswordsDoNotMatch: "passwords-do-not-match",
    Submit: "submit",
    SearchContacts: "search-contacts",
    Follow: "follow",
    Following: "following",
    Unfollow: "unfollow",
    Requested: "requested",
    CookieConsent: "cookie-consent",
    Ok: "ok",
    NoContent: "no-content",
    PrivateLinks: "private-links",
    FollowToSeeContact: "follow-to-see-contact",
    AlreadyFollowUser: "already-follow-user",
    Login: "login",
    Signup: "signup",
    ToSeeContactInfo: "to-see-contact-info",
    CreateYourOwn: "create-your-own",
    ItsEasy: "its-easy",
    HomeTitle: "home-title",
    HomeSubheader: "home-subheader",
    HomeInputPlaceholder: "home-input-placeholder",
    PageTwoH1: "page-two-h1",
    PageTwoH2: "page-two-h2",
    PageThreeH1: "page-three-h1",
    PageThreeH2: "page-three-h2",
    PageFourH1: "page-four-h1",
    PageFourH2: "page-four-h2",
    PageFiveH1: "page-five-h1",
    PageFiveH2: "page-five-h2",
    InvitationSent: "invitation-sent",
    InvitationsSent: "invitations-sent",
    Username: "username",
    UsernameEmail: "username-email",
    Password: "password",
    ConfirmPassword: "confirm-password",
    EmailErrorText: "email-error-text",
    UsernameErrorText: "username-error-text",
    PasswordErrorText: "password-error-text",
    IAgreeToThe: "i-agree-to-the",
    TermsAndConditions: "terms-and-conditions",
    CreateYourAccount: "create-your-account",
    SignupIts: "signup-its",
    Free: "free",
    GotIt: "got-it",
    ResetYourPassword: "reset-your-password",
    PasswordReset: "password-reset",
    LoginToContinue: "login-to-continue",
    ThankYouForSigningUp: "thank-you-for-signing-up",
    WeveSentAnEmail: "weve-sent-an-email",
    VerifyYourEmail: "verify-your-email",
    CheckYourSpam: "check-your-spam",
    ForgotPassword: "forgot-password",
    ForgotYourPassword: "forgot-your-password",
    EnterEmailOrUsername: "enter-email-or-username",
    EmailOrUsername: "email-or-username",
    SendResetLink: "send-reset-link",
    PasswordResetTitle: "password-reset-title",
    PasswordResetSubtitle: "password-reset-subtitle",
    Loyalty: "loyalty",
    ManageLoyaltyMembers: "manage-loyalty-members",
    GiveFeedback: "give-feedback",
    HelpImproveFizLink: "help-improve-fizlink",
    CheckOutNewFeatures: "check-out-new-features",
    SettingsPrivacy: "settings-privacy",
    HelpSupport: "help-support",
    ProfileOnboardingText1: "profile-onboarding-text1",
    ProfileOnboardingText2: "profile-onboarding-text2",
    Highlight: "highlight",
    Default: "default",
    Notifications: "notifications",
    PushNotifications: "push-notifications",
    Off: "off",
    FromEveryone: "from-everyone",
    FromPeopleIFollow: "from-people-i-follow",
    AcceptedFollowRequests: "accepted-follow-requests",
    AcceptedFollowRequestsInfo: "accepted-follow-requests-info",
    NewFollowers: "new-followers",
    NewFollowersInfo: "new-followers-info",
    Reminders: "reminders",
    RemindersInfo: "reminders-info",
    FollowerUpdates: "follower-updates",
    FollowerUpdatesInfo: "follower-updates-info",
    SubscribeTo: "subscribe-to",
    FeedbackEmails: "feedback-emails",
    FeedbackEmailsInfo: "feedback-emails-info",
    ReminderEmails: "reminder-emails",
    ReminderEmailsInfo: "reminder-emails-info",
    NewsEmails: "news-emails",
    NewsEmailsInfo: "news-emails-info",
    Views: "views",
    Clicks: "clicks",
    Followers: "followers",
    OneFollower: "one-follower",
    NumberFollowers: "number-followers",
    NumberFollowing: "number-following",
    Grid: "grid",
    List: "list",
};

export type LocalizationKey = typeof LocalizationKeys[keyof typeof LocalizationKeys]