import React from 'react';

interface VerifiedProps {
    width?: number;
}

export const Verified = ({ width }: VerifiedProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="verified" width={width ?? 24} viewBox="0 0 24 24">
            <path fill="#5cc7eb" d="M23,12l-2.44-2.79l0.34-3.69l-3.61-0.82L15.4,1.5L12,2.96L8.6,1.5L6.71,4.69L3.1,5.5L3.44,9.2L1,12l2.44,2.79l-0.34,3.7 l3.61,0.82L8.6,22.5l3.4-1.47l3.4,1.46l1.89-3.19l3.61-0.82l-0.34-3.69L23,12z M10.09,16.72l-3.8-3.81l1.48-1.48l2.32,2.33 l5.85-5.87l1.48,1.48L10.09,16.72z" />
        </svg>
    );
};
