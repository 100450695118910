import { TableCell, TableRow } from '@react-md/table';
import React from 'react';
import { FontIcon } from 'react-md';
import { Link } from 'react-router-dom';
import { MemberDialog } from '../member-dialog/member-dialog';
import { MemberItemProps, useMemberItem } from './member-item.hooks';

import './member-item.scss';

export const MemberItem = (props: MemberItemProps) => {
    const { id, settingFields, fullname, username, startdate, enddate, fields, valid, auditcount, toggleValidity, dialog, toggleDialog, handleSave } = useMemberItem(props);
    return (
        <>
            <TableRow key={id}>
                <TableCell>
                    <div className="valid-button" onClick={toggleDialog}>
                        <FontIcon>edit</FontIcon>
                    </div>
                </TableCell>
                <TableCell>
                    <div className="valid-button" onClick={toggleValidity}>
                        <FontIcon style={{ color: valid ? '#00d478' : '#EE3D4B' }}>{valid ? 'check_circle' : 'cancel'}</FontIcon>
                    </div>
                </TableCell>
                <TableCell>{fullname} (<Link to={`/${username}`} target="_blank">@{username}</Link>)</TableCell>
                <TableCell>{auditcount ?? 0}</TableCell>
                <TableCell>{startdate && new Date(startdate).toDateString()}</TableCell>
                <TableCell>{enddate && new Date(enddate).toDateString()}</TableCell>
                {
                    settingFields.map((f, i) => {
                        return <TableCell key={`${f.name}_${i}`}>{fields?.[f._id] ?? ""}</TableCell>
                    })
                }
                <TableCell></TableCell>
            </TableRow>
            {dialog ? <MemberDialog visible={!!dialog} settingFields={settingFields} startdate={startdate} enddate={enddate} name={fullname} fields={fields} onSave={handleSave} onHide={toggleDialog} /> : null}
        </>
    );
};
