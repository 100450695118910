import React, { useState, useEffect } from 'react';
import { useMobileDevice, MobileDeviceType } from '../../hooks/device.hooks';
import { Button } from 'react-md';
import { FizlinkIcon } from '../svg/fizlink-icon';

export const AddToHomescreenComponent = () => {
    const mobileDevice = useMobileDevice();
    const [popup, showPopup] = useState(false);
    const [phone, showPhone] = useState(false);
    const [popupCount, setPopupCount] = useState(0);

    const isIos = mobileDevice === MobileDeviceType.iOSPhone;

    useEffect(() => {
        const elementId = isIos ? "iosShare" : "androidShare";
        setTimeout(() => {
            const shareButton = document.getElementById(elementId);
            if (shareButton && shareButton.focus && shareButton.blur) {
                shareButton.focus();
                setTimeout(() => {
                    showPopup(true);
                    shareButton.blur();
                    setTimeout(() => {
                        showPopup(false);
                        showPhone(true);
                        setTimeout(() => {
                            showPhone(false);
                            setPopupCount(popupCount + 1);
                        }, 2000);
                    }, 4000);
                }, 1500);
            }
        }, 1500);

        return () => showPopup(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [popupCount]);

    if (!mobileDevice) {
        return null;
    }

    const renderPhoneWithLogo = () => {
        return !phone ? null : (
            <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', width: '90px', height: '180px', border: '4px solid #fff', borderRadius: '8px' }}>
                <div style={{ padding: '4px', display: 'flex', flexWrap: 'wrap' }}>
                    <div style={{ width: '33%', padding: '4px' }}><div style={{ height: '16px', width: '16px', border: '2px solid white', borderRadius: '4px' }}></div></div>
                    <div style={{ width: '33%', padding: '4px' }}><div style={{ height: '16px', width: '16px', border: '2px solid white', borderRadius: '4px' }}></div></div>
                    <div style={{ width: '33%', padding: '4px' }}><div style={{ height: '16px', width: '16px', border: '2px solid white', borderRadius: '4px' }}></div></div>
                    <div style={{ width: '33%', padding: '4px' }}><div style={{ height: '16px', width: '16px', border: '2px solid white', borderRadius: '4px' }}></div></div>
                    <div style={{ width: '33%', padding: '4px' }}><div style={{ height: '16px', width: '16px', border: '2px solid white', borderRadius: '4px' }}></div></div>
                    <div style={{ width: '33%', padding: '4px' }}><div style={{ height: '16px', width: '16px', border: '2px solid white', borderRadius: '4px' }}></div></div>
                    <div style={{ width: '33%', padding: '4px' }}><div className="onboarding-logo" style={{ height: '16px', width: '16px', border: '2px solid white', backgroundColor: 'white', borderRadius: '4px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><FizlinkIcon height={8} /></div></div>
                </div>
            </div>
        );
    };

    const renderPopup = () => {
        return (
            <div style={{ backgroundColor: '#fff', borderRadius: '8px', display: 'inline-block', visibility: popup ? 'visible' : 'hidden' }}>
                <div style={{ padding: '16px' }}>...</div>
                <div style={{ padding: '16px', borderTop: '1px solid #e0e0e0' }}>...</div>
                <div style={{ padding: '16px', border: '4px solid #F4AC3D', borderRadius: '8px' }}>Add to Home Screen</div>
            </div>
        )
    };

    const renderIos = () => {
        return (
            <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', touchAction: 'none' }}>
                {renderPopup()}
                {renderPhoneWithLogo()}
                {phone ? null : <Button id="iosShare" icon={true} svg={true}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="calc(100% - 4px)" width="calc(100% - 4px)" viewBox="0 0 50.181 80">
                        <path fill="#fff" d="M28.984,47.312 C30.154,47.312 31.150,46.341 31.150,45.170 L31.150,11.179 L31.001,6.447 L33.591,9.161 L38.845,14.640 C39.244,15.088 39.816,15.312 40.339,15.312 C41.460,15.312 42.332,14.491 42.332,13.370 C42.332,12.797 42.107,12.349 41.684,11.926 L30.553,1.093 C30.005,0.545 29.532,0.371 28.984,0.371 C28.411,0.371 27.938,0.545 27.390,1.093 L16.234,11.926 C15.835,12.349 15.586,12.797 15.586,13.370 C15.586,14.491 16.433,15.312 17.579,15.312 C18.102,15.312 18.699,15.088 19.098,14.640 L24.327,9.161 L26.967,6.447 L26.792,11.179 L26.792,45.170 C26.792,46.341 27.789,47.312 28.984,47.312 ZM10.481,72.214 L47.437,72.214 C53.886,72.214 57.124,68.952 57.124,62.602 L57.124,30.055 C57.124,23.704 53.886,20.467 47.437,20.467 L38.347,20.467 L38.347,24.875 L47.337,24.875 C50.749,24.875 52.716,26.718 52.716,30.279 L52.716,62.378 C52.716,65.939 50.749,67.782 47.337,67.782 L10.581,67.782 C7.095,67.782 5.202,65.939 5.202,62.378 L5.202,30.279 C5.202,26.718 7.095,24.875 10.581,24.875 L19.596,24.875 L19.596,20.467 L10.481,20.467 C4.057,20.467 0.794,23.680 0.794,30.055 L0.794,62.602 C0.794,68.977 4.057,72.214 10.481,72.214 Z" />
                    </svg>
                </Button>}
            </div >
        );
    };

    const renderAndroid = () => {
        return (
            <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', touchAction: 'none', pointerEvents: 'none' }}>
                {renderPopup()}
                {renderPhoneWithLogo()}
                {phone ? null : <Button id="androidShare" icon={true} style={{ color: '#fff' }}>
                    more_vert
                </Button>}
            </div >
        );
    };

    return isIos ? renderIos() : renderAndroid();
};
