import React from 'react';

interface WidgetHeaderProps {
    title: React.ReactNode;
    icon?: React.ReactNode;
    actions?: React.ReactNode;
    titleColor?: string;
    showActions?: boolean;
}

export const WidgetHeader = (props: WidgetHeaderProps) => {
    const { icon, title, titleColor, actions, showActions } = props;
    return (
        <div className="header">
            <div className="header-title">
                {icon ? <div className="icon">{icon}</div> : null}
                <div className="title" style={{ color: titleColor ?? '#101010' }}>{title}</div>
            </div>
            <div className="widget-actions" style={{ opacity: actions && showActions ? '1' : '0' }}>{actions}</div>
        </div>
    );
};
