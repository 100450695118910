import { SecuredService } from "./secured.service";
import { ApiResult } from "./api-result";
import { Member } from '../models/member';

export class ViewerService extends SecuredService {

    private baseUrl = 'api/viewers';

    public getAll(): Promise<ApiResult<Member[]>> {
        return this.get(this.baseUrl);
    }

    public save(userid: string): Promise<ApiResult<Member>> {
        return this.post(`${this.baseUrl}/${userid}`);
    }

    public update(userid: string, valid?: boolean): Promise<ApiResult<Member>> {
        return this.put(`${this.baseUrl}/${userid}`, { valid });
    }
}