import React, { useEffect, useState } from 'react';
import { MenuButton, ListItem, Button, FontIcon } from 'react-md';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { useSelectedMobile } from '../../hooks/mobile.hooks';
import { MediaService } from '../../services/media.service';
import { DEFAULT_PROFILE_PICTURE } from '../preview/base64.lib';
import { useHistory } from 'react-router-dom';
import { FizlinkIcon } from '../svg/fizlink-icon';
import { useTranslation } from 'react-i18next';
import { locale } from '../../locales/locale';
import { useMobileDevice } from '../../hooks/device.hooks';
import { PageletButton } from '../pagelet/pagelet-button';
import { defaultSettingsPagelet, PageletProp, PageletItemProps } from '../pagelet/pagelet.types';
import { FizLink } from '../svg/fizlink-logo';
import { markAsRead } from '../../redux/push-notification.state';
import { LocalizationKeys } from '../../locales/keys';

import './toolbar.component.scss';

interface ToolbarProps {
    hideMenu?: boolean;
    onNavigationClick?: () => void;
}

export const Toolbar = (props: ToolbarProps) => {
    const { i18n } = useTranslation();
    const token = useSelector((state: RootState) => state.authentication.token);
    const user = useSelector((state: RootState) => state.user.selectedUser);
    const stateFollowers = useSelector((state: RootState) => state.following.followers);
    const pushnotifications = useSelector((state: RootState) => state.pushnotification.pushnotifications);
    const pending = [...stateFollowers].filter((f) => f.status?.code === 'req');
    const selectedMobile = useSelectedMobile();
    const history = useHistory();
    const [languages] = useState(Object.keys(i18n.services.resourceStore.data).sort());
    const isMobileDevice = useMobileDevice();
    const [toolbarClassName] = useState(!isMobileDevice ? "toolbar" : "toolbar-mobile");
    const [notificationPageletItems, setNotificationPageletItems] = useState<PageletProp[]>([]);
    const [settingsPagelet, setSettingsPagelet] = useState<PageletProp[]>([]);

    const dispatch = useDispatch();

    const onLanguageSelected = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        const id = event.currentTarget.id;
        i18n.changeLanguage(id);
    };

    const page = selectedMobile?.pages[0];
    const profilePicture = page?.picture ? MediaService.getMediaUrl(page.picture) : DEFAULT_PROFILE_PICTURE;
    const isContactsActive = window.location.pathname === '/setup/contacts';
    const isSetupActive = window.location.pathname === '/';

    useEffect(() => {
        const settingsPagelet: PageletProp[] = [];
        settingsPagelet.push('profile');
        if (user?.enterprise) {
            settingsPagelet.push({ title: LocalizationKeys.Loyalty, href: '/setup/loyalty', subheader: LocalizationKeys.ManageLoyaltyMembers, icon: 'loyalty' });
        }
        settingsPagelet.push('divider');
        settingsPagelet.push(...defaultSettingsPagelet);

        setSettingsPagelet(settingsPagelet);
    }, [user]);

    useEffect(() => {
        const props: PageletItemProps[] = pushnotifications
            .slice()
            .sort((a, b) => new Date(b.creationdate).getTime() - new Date(a.creationdate).getTime())
            .map((n) => {
                return {
                    title: n.title,
                    subheader: n.content,
                    href: n.href,
                    src: n.picture,
                    dispatchAction: markAsRead(n._id)
                }
            });
        setNotificationPageletItems(props);
    }, [dispatch, pushnotifications]);

    const handleTabClick = (path: string): void => {
        if (path !== window.location.pathname) {
            history.push(path);
        }
    };

    return (
        <header className={toolbarClassName}>
            <div className="menu-logo">
                {props.hideMenu ? null : <Button icon={true} onClick={props.onNavigationClick} style={{ marginRight: '8px' }}>menu</Button>}
                <a href="/home">
                    <FizLink height={!isMobileDevice ? 32 : 24} />
                </a>
            </div>
            <div className="toolbar-tabs">
                <div className={`toolbar-tab-wrapper ${isSetupActive ? 'active' : ''}`} onClick={() => handleTabClick('/')}>
                    <div className="toolbar-tab">
                        <FizlinkIcon height={20} color={isSetupActive ? 'rgb(3, 169, 244)' : 'rgba(0, 0, 0, 0.54)'} />
                    </div>
                </div>
                <div className={`toolbar-tab-wrapper ${isContactsActive ? 'active' : ''}`} onClick={() => handleTabClick('/setup/contacts')}>
                    <div className="toolbar-tab">
                        <FontIcon style={{ color: isContactsActive ? 'rgb(3, 169, 244)' : undefined }}>group</FontIcon>
                        {!!pending.length ? <div className="toolbar-notification" /> : null}
                    </div>
                </div>
            </div>
            <div className="toolbar-actions">
                <div className="toolbar-action">
                    <MenuButton
                        id="profile-language"
                        position="br"
                        style={{ borderRadius: '16px' }}
                        listStyle={{ borderRadius: '16px' }}
                        menuItems={languages.map((item) => <ListItem id={item} key={`profile-${item}`} primaryText={locale[item] ?? item} onClick={onLanguageSelected} />)}
                    >
                        <div className="profile-action">
                            <FontIcon>language</FontIcon>
                            <label id="language-label">{locale[i18n.language] ?? i18n.language}</label>
                        </div>
                    </MenuButton>
                </div>
                <PageletButton items={notificationPageletItems} icon="notifications">
                    <div style={{ position: 'relative' }}>
                        {!notificationPageletItems.length ? null :
                            <div className="notifications-badge">
                                {notificationPageletItems.length > 9 ? "9+" : notificationPageletItems.length}
                            </div>
                        }
                    </div>
                </PageletButton>
                <PageletButton items={settingsPagelet}>
                    <div className="profile-action">
                        <img height={24} width={24} alt="Profile" className="profile-picture" src={profilePicture} />
                        <label id="fullname-label">{user?.contact.firstname ?? token?.firstname}</label>
                    </div>
                </PageletButton>
            </div>
        </header>
    );
}
