import { useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from "../../../redux/reducers";
import { updateSettings } from '../../../redux/user-setting.state';

export const useNotifications = () => {
    const settings = useSelector((state: RootState) => state.usersetting.settings);
    const dispatch = useDispatch();

    const update = useCallback((checked: boolean, event: Event) => {
        if (!settings) {
            return;
        }
        const id = (event.target as any).id;
        const notifications = { ...settings.notifications, [id]: checked };
        const updated = { ...settings, notifications };
        updateSettings(updated)(dispatch);
    }, [dispatch, settings]);

    return {
        notifications: settings?.notifications, update
    };
};