import React, { useCallback } from 'react';
import { FontIcon } from 'react-md';
import { PageletItemProps } from './pagelet.types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import './pagelet-item.scss';

export const PageletItem = ({ title, href, target, subheader, icon, src, dispatchAction }: PageletItemProps) => {
    const { t } = useTranslation()
    const dispatch = useDispatch();

    const renderChildren = useCallback(() => {
        return (
            <React.Fragment>
                {icon ? <FontIcon className="pagelet-icon">{icon}</FontIcon> : null}
                {src ? <img className="pagelet-img" src={src} alt={title} /> : null}
                <div className="pagelet-display">
                    <span className="pagelet-title">{t(title)}</span>
                    {subheader ? <span className="pagelet-subheader">{t(subheader)}</span> : null}
                </div>
            </React.Fragment>
        );
    }, [t, icon, src, title, subheader]);

    const handleDispatchAction = useCallback(() => {
        if (dispatchAction) {
            dispatch(dispatchAction);
        }
    }, [dispatch, dispatchAction]);

    if (dispatchAction && href) {
        return (
            <a className="pagelet-item" href={href} target={target} onClick={handleDispatchAction}>
                {renderChildren()}
            </a>
        );
    }
    else if (dispatchAction) {
        return (
            <div className="pagelet-item" onClick={handleDispatchAction}>
                {renderChildren()}
            </div>
        );
    }
    else if (href) {
        return (
            <a className="pagelet-item" href={href} target={target}>
                {renderChildren()}
            </a>
        );
    }
    else {
        return null;
    }
};
